import React, {Component} from 'react';
import {UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle} from "reactstrap"
import stringsoflanguages from '../../Stringsoflanguage'
import { IntlContext } from "../../utility/context/Internationalization"
import axios from "axios";
import { APIURL, COMMON } from '../../config';

class Logo extends Component {

    constructor() {
        super();
        this.state = {
            enableLogin: 'false',
            ipurl:APIURL,
            languageCode:2,
            languageData: [],
         }
    }

    componentDidMount() {
        
        // stringsoflanguages.setLanguage('ta')
                // localStorage.setItem('languagecode', 2)
                // localStorage.setItem('languagecode_short', 'en')
                let langcode = (localStorage.getItem('languagecode') == null || localStorage.getItem('languagecode') == undefined || localStorage.getItem('languagecode') == 0 || localStorage.getItem('languagecode') == '') ? 2 : localStorage.getItem('languagecode')
                let shortcode = (localStorage.getItem('languagecode_short') == null || localStorage.getItem('languagecode_short') == undefined || localStorage.getItem('languagecode_short') == 0 || localStorage.getItem('languagecode_short') == '') ? 'en' : localStorage.getItem('languagecode_short')
                // alert(shortcode);
                stringsoflanguages.setLanguage(shortcode)
                localStorage.setItem('languagecode', langcode)
                localStorage.setItem('languagecode_short', shortcode)
                this.setState({ languagecode_color: this.state.languagecode_color = 2 })
               
        window.addEventListener('scroll', this.isSticky);
        this.setState({enableLogin:localStorage.getItem('enableLogin')})
        if(localStorage.getItem('language_json_result') == null || localStorage.getItem('language_json_result') == 'null' || localStorage.getItem('language_json_result') == undefined || localStorage.getItem('language_json_result') == 'undefined')
          this.fetchLanguageData();
        else {
          let language_list = localStorage.getItem('language_json_result').toString() != 'null' ? JSON.parse(localStorage.getItem('language_json_result')) : {}
          this.bindLanguage(language_list)
        }
    }
    fetchLanguageData() {
        try {
            axios({
                method: 'Post',
                url: this.state.ipurl + 'languagebind/?',
                params: {
                    ipaddress: '103.65.1.203'
                },
            })
          .then((response) => {
            if(response.data) {
              localStorage.setItem("language_json_result", JSON.stringify(response.data))
              this.bindLanguage(response.data);
          }
          })
        
          .catch((error) => {
            console.error('Error fetching language data:', error);
          });
        } catch (error) { }
    }

    bindLanguage(response) {
      if (response.hasOwnProperty('language_json_result')) {
        let languageList = response.language_json_result.languagelist;
      //   console.log(languageList, "45555555555555555555555")
        this.setState({ languageData: this.state.languageData = languageList });
      }
    }

    render() {
        return (
<div>
            <a className="home-link" href={'/'} title="Jobanya" rel="home">
                <img id="logo-img" height="80" width="250" className="img-fluid auto_size" src={process.env.PUBLIC_URL + "/images/jobanya-logo.png"} alt="logo-img"/>
             </a>
{/* <div>
{window.innerWidth <= 768 &&
    (<div className="dropdown d-inline-block">
               
               <UncontrolledDropdown>
                 <DropdownToggle color="flat-light" caret>
                   <span style={{ color: "grey" }}>   {this.state.languageData?.length > 0 ? this.state.languageData?.filter(t => t.shorttype == localStorage.getItem('languagecode_short')).map(e => { return e.languagename }) : ""}</span>
                
                 </DropdownToggle>
                 <DropdownMenu>
                   {this.state.languageData ? this.state.languageData.map((i, index) => {
                     return (
                       <DropdownItem
                         tag="a"
                         onClick={e => {
                           stringsoflanguages.setLanguage('ta')
                           localStorage.setItem('languagecode', i.languagecode)
                           localStorage.setItem('languagecode_short', i.shorttype)
                           window.location.reload();
                         }}
                       >
                         <span>{i.languagename}</span>
                       </DropdownItem>
                     )
                   }) : ""}
                 </DropdownMenu>
               </UncontrolledDropdown>
             </div>)
             }
</div> */}
</div>
        );
    }
}

export default Logo;