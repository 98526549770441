import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import axios from "axios";
import { APIURL, COMMON } from '../config'
import * as Moment from 'moment'
import { history } from "../history"
import HTMLRenderer from 'react-html-renderer'
let error_log = require('../error_log');


export class GNJob_details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rowData: {},
            gnjobcode: 0,
            ipurl: APIURL,
            photoIndex: 0,
            isOpen: false,
            jobrolename: '',
            daysleft: '',
            locationname: '',
            companyname: '',
            jobdescription: '',
            jobtypes: [],
            minsalary: '',
            maxsalary: '',
            isfresher: '',
            experiencefrom: '',
            experienceto: '',
            industryname: '',
            noofopenings: '',
            address: '',
            emailid: '',
            skills: [],
            varcontact_address1: "",
            varcontact_address2: "",
            varcontact_address3: "",
            varcontact_address4: "",
            varcontact_address5: "",
            varcontact_address6: "",
            approveddate: '',
            qualification: [],
            schoolinglistval: [],
            educationval: [],
            afterschoolingisany: "",
            specializationany: '',
            isanydegree: false,
        };
    }

    componentDidMount() {
        try {
            let empcode = localStorage.getItem('employeecode');
            this.setState({employeecode : this.state.employeecode = empcode});
            // alert("44444444")
            localStorage.getItem('ipaddress')
            this.setState({ipaddress : this.state. ipaddress = localStorage.getItem('ipaddress')});
                stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
                this.setState({languagecode : this.state.languagecode = localStorage.getItem('languagecode')})
            let query = new URLSearchParams(history.location.search)
            let gnorganisationcode = (query.get('gnorganisationcode'))
            let gnjobcode = (query.get('gnjobcode'))
            if (gnorganisationcode != '') {
                this.setState({ gnorganisationcode: this.state.gnorganisationcode = gnorganisationcode })
            }
            if (localStorage.getItem('gnjobcode') != '') {
                this.setState({ gnjobcode: this.state.gnjobcode = gnjobcode })
            }
            this.job_list()
        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "GNJob_details", "componentDidMount", 0, error.toString());
        }
    }
    job_list() {
        window.fbq('track', 'Website', { fb_ad_function: "job_list", fb_ad_type: "Gn Job Details", fb_ad_loginuser: COMMON.employee_name });
        try {
            axios({
                method: 'Post',
                // url: this.state.ipurl + 'gnorganisation/govtjobslist',
                url: 'https://api.jobanya.com/api/controller/' + 'gnorganisation/govtjobslist',
                crossdomain: true,

                params: {
                    // employeecode: this.state.employeecode,
                    employeecode: this.state.employeecode,
                    ipaddress: this.state.ipaddress,
                    languagecode: this.state.languagecode,
                    gnorganisationcode: this.state.gnorganisationcode,
                    gnjobcode: this.state.gnjobcode,
                    skip: this.state.skip,
                    limit: this.state.limit
                },
                data: {
                    skillcode: [],
                    locationcode: [], jobfunctioncode: [], jobrolecode: [], jobtypecode: [], schoolqualcode: [], afterschoolcatecode: [], anydegree: "true", anyqualification: "true", anyspec: "true", afterschoolqualcode: [], afterschoolspeccode: [], experiencecode: 0, industrycode: [], employertypecode: [], companytypecode: [], maritalcode: [], gendercode: [], differentlyabled: -1, salaryfrom: 0, salaryto: 0, agefrom: 0, ageto: 0, anyage: "true"
                }
            })
                .then(response => {
                    if (response.data.gnOrganisation_list_json_result.varstatuscode === 4) {
                        let rowData = response.data.gnOrganisation_list_json_result.govtjobs[0];
                        JSON.stringify(rowData);
                        this.setState({ rowData: this.state.rowData = rowData });
                        // alert(JSON.stringify(this.state.rowData))
                    }

                })
                .catch(error => {
                    error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "Gn_Job_list", "job_list", 0, error.toString());
                });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "Gn_Job_list", "job_list", 0, error.toString());
        }


    }
    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{

                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };

        return (

            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="Job details"
                    breadcrumb="Job Details"
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            {/* <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                <div className="job_list-widget">
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="ti ti-files"></i>Job Informations</h3>
                                        <ul>
                                            <li className="d-flex"><b className="mr-5">Job Type:</b>
                                                {this.state.jobtypes.map((jobtypes, index) => {
                                                    return (
                                                        <span>{jobtypes.jobtypename} {index == this.state.jobtypes.length - 1 ? '' : ', '}</span>
                                                    )
                                                })
                                                }</li>
                                            <li className="d-flex"><b className="mr-5">Location:</b>{this.state.locationname}</li>
                                            <li className="d-flex"><b className="mr-5">Offered Salary:</b>{this.state.minsalary} - {this.state.maxsalary}</li>
                                            <li className="d-flex"><b className="mr-5">Posted:</b>{this.state.approveddate}</li>
                                            <li className="d-flex"><b className="mr-5">Experience:</b>{this.state.isfresher == true || this.state.isfresher == 'true' ?
                                                'Fresher ' : ''
                                            }
                                                {this.state.experiencefrom >= 0 && this.state.experienceto > 0 ?
                                                    this.state.experiencefrom + '-' + this.state.experienceto + ' Years' : ''}


                                            </li>
                                            <li className="d-flex"><b className="mr-5">Industry:</b>{this.state.industryname}</li>
                                            <li className="d-flex"><b className="mr-5">Qualification:</b></li>
                                            <li className="d-flex"><b className="mr-5">position:</b>{this.state.noofopenings}</li>
                                        </ul>
                                    </aside>
                                    <aside className="widget form-widget">
                                        <h3 className="widget-title"><i className="ti ti-email"></i>Send Us Message</h3>
                                        <form className="wrap-form">
                                            <label>
                                                <input name="name" type="text" placeholder="Your Name" required="required" />
                                            </label>
                                            <label>
                                                <input name="email" type="text" placeholder="Your Email" required="required" />
                                            </label>
                                            <label>
                                                <textarea name="message" rows="3" placeholder="Message" required="required"></textarea>
                                            </label>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" type="submit">send a message!</button>
                                        </form>
                                    </aside>
                                    <aside className="widget location-widget p-0">
                                        <iframe width="100%" height="350" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26361414.263400003!2d-113.75849480805297!3d36.24080384347216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1578680778274!5m2!1sen!2sin">
                                        </iframe>
                                        <div className="p-20">
                                            {this.state.varcontact_address1 ?
                                                <div>
                                                    <span className="text-theme-SkinColor">
                                                        <i className="fa fa-map-marker-alt mr-10"></i></span><span>{this.state.varcontact_address2}</span><br />
                                                    <span>{this.state.varcontact_address3}</span><br />
                                                    <span>{this.state.varcontact_address4}</span><br />
                                                    <span>{this.state.varcontact_address5}</span><br />
                                                    <span>{this.state.varcontact_address6}</span>
                                                </div>
                                                : ''
                                            }
                                            <div>
                                                <a href="mailto:info@example.com"><i className="fa fa-envelope mr-10"></i>{this.state.emailid}</a>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div> */}
                            <div className="col-lg-12 content-area">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        {/* featured-imagebox */}
                                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor m-0">
                                            <div className="featured-thumbnail">
                                                <img src={this.state.rowData.gnorganisationurl != '' ? this.state.rowData.gnorganisationurl : "images/joblist_logo.jpg"} />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3>{this.state.rowData.title}</h3>
                                                </div>
                                                {/* <div className="featured-desc">
                                                    <p>Published {this.state.daysleft}Days Ago.</p>
                                                </div> */}
                                                <div className="featured-bottom">
                                                    <div className="job-meta">
                                                        {this.state.rowData.statecode == 1 ?
                                                            <span><i className="fa fa-map-marker-alt"></i>{this.state.rowData.statename}</span>
                                                            : null}
                                                        <span><i className="fa fa-building"></i>{this.state.rowData.gnorganisationname}</span>
                                                    </div>
                                                    {this.state.rowData.hasOwnProperty('expirationdate') ?
                                                        <div className="job-time">
                                                            <span className="green">Last date - {Moment(new Date(this.state.rowData.expirationdate)).format('DD-MM-YYYY')}</span>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>{/* featured-imagebox end */}
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>Job Description :</h5>
                                            </div>
                                            <div className="desc">
                                                {/* <p>{this.state.rowData.description}</p> */}
                                                <HTMLRenderer
                                                    html={this.state.rowData.description}
                                                // components={{
                                                //     h1: props => <Heading color="red" {...props} />,
                                                //     h2: Subheading,
                                                //     a: Link,
                                                // }}
                                                />
                                            </div>

                                        </div>
                                        {this.state.rowData.hasOwnProperty('link') ?
                                            this.state.rowData.link != '' ?
                                                <div className="overview-box">
                                                    <div className="title">
                                                        <h5>Link</h5>
                                                    </div>
                                                    <div className="desc">
                                                        <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey">
                                                            <li>
                                                                <i className="ti ti-check-box"></i>
                                                                <div className="ttm-list-li-content"> <a href={this.state.rowData.link}>{this.state.rowData.link}</a></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                : '' : ''}
                                        {this.state.rowData.hasOwnProperty('uploads') ?
                                            this.state.rowData.uploads.length > 0 ?
                                                <div className="overview-box">
                                                    <div className="title">
                                                        <h5>Downloads</h5>
                                                    </div>
                                                    {this.state.rowData.uploads.map((e, index) => {
                                                        return (
                                                            <div className="desc">
                                                                <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey">
                                                                    <li>
                                                                        <i className="ti ti-check-box"></i>
                                                                        <div className="ttm-list-li-content"> <a href={this.state.rowData.uploads[index].filepath}>{this.state.rowData.uploads[index].displayname}</a></div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })
                                                    }

                                                </div>
                                                : '' : ''}
                                        {/* <div className="overview-box mb-60">
                                            <div className="title">
                                                <div className="d-sm-flex justify-content-between mb_10">
                                                    <h5 className="mb-10">Share Job :</h5>
                                                    <div className="social-icons circle mb-10">
                                                        <ul className="list-inline">
                                                            <li><a href="#facebook" rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                                            <li><a href="#twitter" rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                                            <li><a href="#instagram" rel="noopener" aria-label="instagram"><i className="ti ti-instagram"></i></a></li>
                                                            <li><a href="#pinterest" rel="noopener" aria-label="pinterest"><i className="ti ti-pinterest"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default GNJob_details;