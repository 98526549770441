import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import Checkbox from '../components/@vuexy/checkbox/CheckboxesVuexy';
import { Check } from "react-feather"
import axios from "axios";
import Pagination from "react-js-pagination";
import ReactPaginate from 'react-paginate';
import stringsoflanguages from '../Stringsoflanguage';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Job_role_filter } from '../pages/job_role_filter';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { Slider } from "@material-ui/core";
import { Redirect } from 'react-router-dom';
import {
    Card,
    CardBody,
    Media,
    Col,
    Row,
    Label,
    FormGroup,
    Button,
    TabContent,
    TabPane,
    Form,
    Input,
    Collapse,
    CardHeader,
    CardTitle,
    CustomInput,
    Badge,
    UncontrolledCollapse
} from "reactstrap"
import Select from "react-select"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import LoadingOverlay from 'react-loading-overlay'
import { APIURL, COMMON } from '../config'
import { history } from "../history"
import { getAllByPlaceholderText } from '@testing-library/react';
import { generateRefreshToken } from '../serviceworker';
let error_log = require('../error_log');

export class Job_list extends Component {

    constructor() {
        super();
        this.state = {
            istabclick: 0,
            is_auth: true,
            leadtype: 0,
            employermobileno: '',
            fromSalary: '',
            toSalary: '',
            jobcode: 0,
            showModal: false,
            modalMessage: '',
            showCallHRModal: false,
            name: "React",
            ipurl: APIURL,
            rowData: [],
            dpjobfunction: [],
            jobrole_full_list: [],
            currentItems: [],
            pageCount: 1,
            endPage: 10,
            itemOffset: 0,
            itemsPerPage: 10,
            startPage: 1,
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
            menuItems: [],
            applyFilters: [],
            isFilterApplied: false,
            activePage: 15,
            skip: 0,
            limit: 10,
            currentPageIndex: 0,
            jobfunctioncodeval: [],
            salaryRange: [20, 37],
            selectedSalaryRange: "",
            locationcodeval: [],
            jobrolecodeval: [],
            typecode: '',
            jobnav: '',
            languagecode: 2,
            search_val: '',
            common_array_duplicate: [],
            common_array: '',
            recent_array_duplicate: [],
            closeicon_hidden: true,
            show_hidden: true,
            search_array_hidden: true,
            profile_location: [],
            profile_taluk: [],
            profile_jobfunction: [],
            profile_jobrole: [],
            skillcodeval: [],
            isSearchclick: false,
            filterprofile: [],
            jobalertdays: '',
            location_val: '',
            jobrole_val: '',
            jobfunction_val: '',
            searchflag: '',
            recommededJobrole: 'false',
            jobfunctionresult: false,
            jobroleresult: false,
            enableSearch: false,
            locationresult: false,
            showjobfunction: false,
            showskills: false,
            skill_full_list: [],
            dpgender: [],
            dpExperience: [],
            profile_gender: [],
            job_experience_array: [],
            max_experience: 40,
            jobfunctionarray: [],
            skillarray: [],
            jobfunctioncode_api: [],
            joblocationcode_api: [],
            dpdistrict: [],
            dpbindstate: [],
            experienceval: 0,
            jobtype: 0,
            ipaddress: '',
            isActive: false,
            isFilterActive: false,
            jobtypecodeval: [],
            employeecode: 1,
            districtarray: [],
            selectedItemIndex: 0,
            jobfunction_val: '',
            newSelectedJobRoleIndex: 0,
            enableJobSearch: 'false',
            FilterTitleitemOffset: 0,
            FilterTitleitemsPerPage: 20,
            FilterCurrentOffset: 0,
            items: [], // Populate with your items
            // loadingimg: require("images/svg/loading.gif"),
            selectedJobRoleIndex: 0,
            setJobRoleIndex: 0,
            jobrole_val: '',
            jobRoleArray: [],
            allJobRoleArray: [],
            selectedLocationIndex: 0,
            location_val: '',
            activeTab: 1,
            getroleValue: [],
            currentJobRoleValue: '',
            jobtypedata: [{ jobtypecode: 1, jobtypename: "Private Jobs" }, { jobtypecode: 2, jobtypename: "Freshers Jobs" }, { jobtypecode: 3, jobtypename: "Flash Jobs" }],
            openFilters: false,
            selectedFilterTitle: "",
            selectedFilterCode: 0,
            totalCount: 0,
            jobtypedata: [],
            getSalaryMarks: [
                { value: 0, label: '₹0' },
                { value: 50000, label: '₹50,000' },
                { value: 100000, label: '₹1,00,000' }
            ],
            salaryRangeVal: [0, 100000]
        };
        this.setState({ languagecode: this.state.languagecode = localStorage.getItem('languagecode') });
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onChangeJobfunction = this.onChangeJobfunction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.inputRef = React.createRef();
        this.inputRoleRef = React.createRef();
        this.inputLocationRef = React.createRef();
    }
    // componentDidMount() {
    //     // Add a global keydown event listener to capture key presses anywhere in the document

    //   }

    componentWillUnmount() {
        // Remove the keydown event listener when the component unmounts
        // document.removeEventListener('keydown', this.handleGlobalKeyDown);
    }
    handleGlobalKeyDown = (e) => {
        // Check if the focused element is the input field
        if (document.activeElement === this.inputRef.current) {
            if (e.key === 'Tab') {
                e.preventDefault();
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                // alert("voi")
                // Handle Down Arrow key
                if (this.state.selectedItemIndex < this.state.common_array_duplicate.length - 1) {
                    const newSelectedItemIndex = this.state.selectedItemIndex + 1;
                    const commonArrayDuplicate = [...this.state.common_array_duplicate];
                    commonArrayDuplicate[this.state.selectedItemIndex].hover = false;
                    commonArrayDuplicate[newSelectedItemIndex].hover = true;
                    this.setState({
                        common_array_duplicate: commonArrayDuplicate,
                        selectedItemIndex: newSelectedItemIndex,
                    });
                }
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                // Handle Up Arrow key
                if (this.state.selectedItemIndex > 0) {
                    const newSelectedItemIndex = this.state.selectedItemIndex - 1;
                    const commonArrayDuplicate = [...this.state.common_array_duplicate];
                    commonArrayDuplicate[this.state.selectedItemIndex].hover = false;
                    commonArrayDuplicate[newSelectedItemIndex].hover = true;
                    this.setState({
                        common_array_duplicate: commonArrayDuplicate,
                        selectedItemIndex: newSelectedItemIndex,
                    });
                }
            } else if (e.key === 'Enter') {
                if (this.state.selectedItemIndex >= 0) {
                    const selectedItem = this.state.common_array_duplicate[this.state.selectedItemIndex];
                    alert(selectedItem.name)
                    this.setState({
                        jobfunction_val: selectedItem.name,
                        selectedItemIndex: 0, // Reset selection
                    }, () => {
                        this.inputRef.current.value = selectedItem.name;
                    });
                    this.recentSearchClick(selectedItem)
                }
            }
        }
    }
    gotoRecommded() {
        this.setState({ getroleValue: '0' })
        localStorage.setItem('getcurrentRole', 0)
        localStorage.setItem('RecommendMoreJob', 'false')
    }

    updatecallcount = async(jobcode) =>
    {
        // alert("updatecallcount")
        try{
           
            var accessToken =  localStorage.getItem('accessToken');
            // this.setState({ animating: true });
 
                            axios({
                                method: 'Post',
                                url: this.state.ipurl + 'jobs/updateCallCount/',
                                headers: {
                                    'Authorization': 'Bearer ' + accessToken,
                                    'Content-Type': 'application/json'
                                  },
                                params: {                                   
                                    jobcode: jobcode,
                                    employeecode: this.state.employeecode,
                                    ipaddress: this.state.ipaddress,
                                    type:1,
                                    isleadtype: 0
                                }
                            })
                                .then(async response => {
                                    this.setState({ animating: this.state.animating = false });
                                    if (response.data.status == 401) {
                                        await generateRefreshToken().then((result) => {
                                          if (result == 1) {
                                            this.updatecallcount(jobcode);
                                          } else {
                                            // alert('Unauthorized')
                                          }
                                        });
                                      }
                                      else{
                                    if (response.data.hasOwnProperty('job_json_result')) {
                                        this.state.varstatuscode = response.data.job_json_result.hasOwnProperty('varstatuscode') ? response.data.job_json_result.varstatuscode : 0
                                        this.state.responsestring = response.data.job_json_result.hasOwnProperty('responsestring') ? response.data.job_json_result.responsestring : ''
                                        if (this.state.varstatuscode == 2) {
                                            // Linking.openURL(`tel:${this.state.employermobileno}`)
                                        }
                                        else if (this.state.varstatuscode == 7) {
                                            
                                            toast(stringsoflanguages.user_not_found, {
                                                position: toast.POSITION.TOP_RIGHT,
                                                className: 'foo-bar'
                                            });
                                            // setTimeout(() => {
                                            //     var user_not = require('../routes/er_tokendisable');
                                            //     user_not(this.state.employercode, this.state.fcmtokem);
                                            //     this.logoutsession();
                                            // }, 1000)
                                        }
                                        else {
                                            toast(this.state.responsestring, {
                                                position: toast.POSITION.TOP_RIGHT,
                                                className: 'foo-bar'
                                            });
                                           
                                        }
                                    }
                                }
    
                                })
                                .catch((error) => {
                                    error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_details", "updatecallcount", 0, error.toString());
                                });
        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_details", "updatecallcount", 0, error.toString());
        }
    }

    //   handleGlobalKeyDown = (e) => {
    //     // Check if the focused element is the input field
    //     if (document.activeElement === this.inputRef.current) {
    //       if (e.key === 'Tab') {
    //         e.preventDefault();
    //         console.log('Tab');
    //       } else if (e.key === 'ArrowDown') {
    //         e.preventDefault();
    //         // Handle Down Arrow key
    //         if (this.state.selectedItemIndex < this.state.common_array_duplicate.length - 1) {
    //           this.setState((prevState) => {
    //             const newSelectedItemIndex = prevState.selectedItemIndex + 1;
    //             prevState.common_array_duplicate[prevState.selectedItemIndex].hover = false;
    //             prevState.common_array_duplicate[newSelectedItemIndex].hover = true;
    //             return { selectedItemIndex: newSelectedItemIndex };
    //           });
    //         }
    //       } else if (e.key === 'ArrowUp') {
    //         e.preventDefault();
    //         // Handle Up Arrow key
    //         if (this.state.selectedItemIndex > 0) {
    //           this.setState((prevState) => {
    //             const newSelectedItemIndex = prevState.selectedItemIndex - 1;
    //             prevState.common_array_duplicate[prevState.selectedItemIndex].hover = false;
    //             prevState.common_array_duplicate[newSelectedItemIndex].hover = true;
    //             return { selectedItemIndex: newSelectedItemIndex };
    //           });
    //         }
    //       }  else if (e.key === 'Enter') {

    //         if (this.state.selectedItemIndex >= 0) {
    //           const selectedItem = this.state.common_array_duplicate[this.state.selectedItemIndex];
    //           this.setState({
    //             jobfunction_val: selectedItem.name,
    //             selectedItemIndex: 0, // Reset selection
    //         }, () => {
    //             this.inputRef.current.value = selectedItem.name; 

    //           });
    //         }
    //       }
    //     }
    //   };
    handleJobRoleKeyDown = (e) => {
        // Check if the focused element is the input field
        if (document.activeElement === this.inputRoleRef.current) {
            if (e.key === 'Tab') {
                e.preventDefault();
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                // Handle Down Arrow key
                if (this.state.selectedJobRoleIndex < this.state.common_array_duplicate.length - 1) {
                    this.state.newSelectedJobRoleIndex = this.state.selectedJobRoleIndex;
                    if (this.state.setJobRoleIndex == 0) {
                        this.state.newSelectedJobRoleIndex = 0
                        this.setState({
                            setJobRoleIndex: 1
                        });
                    } else {
                        this.state.newSelectedJobRoleIndex = this.state.selectedJobRoleIndex + 1;

                    }

                    const commonArrayDuplicate = [...this.state.common_array_duplicate];
                    commonArrayDuplicate[this.state.selectedJobRoleIndex].hover = false;
                    commonArrayDuplicate[this.state.newSelectedJobRoleIndex].hover = true;
                    this.setState({
                        common_array_duplicate: commonArrayDuplicate,
                        selectedJobRoleIndex: this.state.newSelectedJobRoleIndex,
                    });
                }
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                // Handle Up Arrow key
                if (this.state.selectedJobRoleIndex > 0) {
                    const newSelectedJobRoleIndex = this.state.selectedJobRoleIndex - 1;
                    const commonArrayDuplicate = [...this.state.common_array_duplicate];
                    commonArrayDuplicate[this.state.selectedJobRoleIndex].hover = false;
                    commonArrayDuplicate[newSelectedJobRoleIndex].hover = true;
                    this.setState({
                        common_array_duplicate: commonArrayDuplicate,
                        selectedJobRoleIndex: newSelectedJobRoleIndex,
                    });

                }
            } else if (e.key === 'Enter') {
                if (this.state.selectedJobRoleIndex >= 0) {
                    const selectedJobRole = this.state.common_array_duplicate[this.state.selectedJobRoleIndex];
                    this.setState({
                        jobrole_val: selectedJobRole.name,
                        selectedJobRoleIndex: 0, // Reset selection
                    }, () => {
                        this.inputRoleRef.current.value = selectedJobRole.name;
                        this.recentSearchClick(selectedJobRole)
                    });
                }
            }
        }
    };
    handleLocationKeyDown = (e) => {
        // Check if the focused element is the input field
        if (document.activeElement === this.inputLocationRef.current) {
            if (e.key === 'Tab') {
                e.preventDefault();
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                // Handle Down Arrow key
                if (this.state.selectedLocationIndex < this.state.common_array_duplicate.length - 1) {
                    const newSelectedLocationIndex = this.state.selectedLocationIndex + 1;
                    const commonArrayDuplicate = [...this.state.common_array_duplicate];
                    commonArrayDuplicate[this.state.selectedLocationIndex].hover = false;
                    commonArrayDuplicate[newSelectedLocationIndex].hover = true;

                    this.setState({
                        common_array_duplicate: commonArrayDuplicate,
                        selectedLocationIndex: newSelectedLocationIndex,
                    });

                }
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                // Handle Up Arrow key
                if (this.state.selectedLocationIndex > 0) {
                    const newSelectedLocationIndex = this.state.selectedLocationIndex - 1;
                    const commonArrayDuplicate = [...this.state.common_array_duplicate];
                    commonArrayDuplicate[this.state.selectedLocationIndex].hover = false;
                    commonArrayDuplicate[newSelectedLocationIndex].hover = true;
                    this.setState({
                        common_array_duplicate: commonArrayDuplicate,
                        selectedLocationIndex: newSelectedLocationIndex,
                    });
                }
            } else if (e.key === 'Enter') {
                if (this.state.selectedLocationIndex >= 0) {
                    const selectedLocation = this.state.common_array_duplicate[this.state.selectedLocationIndex];
                    //alert(selectedLocation.name)
                    this.setState({
                        location_val: selectedLocation.name,
                        selectedLocationIndex: 0, // Reset selection
                    }, () => {
                        this.inputLocationRef.current.value = selectedLocation.name;
                        this.recentSearchClick(selectedLocation)
                    });
                }
            }
        }
    };

    searchJobRole(searchValue) {
        if (searchValue != "" && searchValue.length >= 3) {
            var filteredJobRole = this.state.allJobRoleArray
                .filter(e => e.jobrolename.toLowerCase().includes(searchValue.toLowerCase()));
            this.setState({ jobRoleArray: this.state.jobRoleArray = filteredJobRole })
        }
        else if (searchValue.length == 2) {
            this.setState({
                FilterTitleitemOffset: (this.state.FilterTitleitemOffset = 0),
                jobRoleArray: (this.state.jobRoleArray = [])
            })
            this.pageFilterBind(this.state.FilterTitleitemOffset, this.state.FilterTitleitemsPerPage)
            //   if(this.state.jobRoleArray.length != this.state.allJobRoleArray.length)
            //     this.setState({jobRoleArray :this.state.jobRoleArray =this.state.allJobRoleArray })
        }
    }


    componentDidMount() {
        try {
            if (!localStorage.getItem("mobileNumber") || localStorage.getItem("mobileNumber") == null) {
                this.setState({ is_auth: this.state.is_auth = false });
            }
            // localStorage.setItem("istabclick", 0)
            //     stringsoflanguages.setLanguage('ta')
            // localStorage.setItem('languagecode', 1)
            // localStorage.setItem('languagecode_short', 'ta')
            // alert(stringsoflanguages.getLanguage())
            // this.setState({ languagecode_color: this.state.languagecode_color = 1 })

            let empcode = localStorage.getItem('employeecode');
            if (empcode == null || empcode == undefined || empcode == 0 || empcode == -1) {
                empcode = -1;
            }
            else {
                empcode = empcode;
            }
            let leadtype = localStorage.getItem('leadtype');
            this.setState({ leadtype: this.state.leadtype = leadtype });
            this.setState({ employeecode: this.state.employeecode = empcode });
            if (leadtype == 0)
            {
                this.setState({ recommededJobrole: this.state.recommededJobrole = localStorage.getItem('RecommendMoreJob') });
                stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
                this.setState({ currentJobRoleValue: this.state.currentJobRoleValue = localStorage.getItem('getCurrentJobSearch') })
                this.setState({ enableJobSearch: localStorage.getItem('setJobSearch') })
    
                this.setState({ getroleValue: this.state.getroleValue = localStorage.getItem('getcurrentRole') });
                this.setState({ jobrolecodeval: this.state.jobrolecodeval = Number(this.state.getroleValue) });
    
                let menuList = [];
                if (this.state.getroleValue == '0' || this.state.getroleValue == null || !this.state.getroleValue)
                    menuList = [{ menuCodeId: 1, menuName: stringsoflanguages.pre_jobrole }];
    
                this.setState({
                    menuItems: this.state.menuItems = [...menuList, ...[{ menuCodeId: 2, menuName: stringsoflanguages.filter_job_location }, { menuCodeId: 3, menuName: stringsoflanguages.personal_gender }, { menuCodeId: 4, menuName: stringsoflanguages.pre_header_popup_sal }, { menuCodeId: 5, menuName: stringsoflanguages.rl_experience }]],
                    languagecode: this.state.languagecode = localStorage.getItem('languagecode'),
                    jobtypedata: [{ jobtypecode: 1, jobtypename: stringsoflanguages.jobsummary_privatejobs }, { jobtypecode: 2, jobtypename: stringsoflanguages.jobsummary_fresherjobs }, { jobtypecode: 3, jobtypename: stringsoflanguages.home_flashjobs }]
                });
    
                //this.call_gender()
                //  this.call_job_location()
    
    
                this.setState({ ipaddress: this.state.ipaddress = localStorage.getItem('ipaddress') });
                //localStorage.setItem('languagecode', 1)
                // localStorage.setItem('languagecode', 1)
                // localStorage.setItem('languagecode_short', 'ta')
                // alert(stringsoflanguages.getLanguage())
                // this.setState({ languagecode_color: this.state.languagecode_color = 1 })
                // document.addEventListener('keydown', this.handleGlobalKeyDown);
                let query = new URLSearchParams(history.location.search)
                let jobfunctioncode = (query.get('jobfunctioncodeval'))
                let jobrolecode = (query.get('jobrolecodeval'))
                let locationcodeval = (query.get('locationcodeval'))
                let jobfunctionname = (query.get('jobfunctionname'))
                let jobrolename = (query.get('jobrolename'))
                let locationname = (query.get('locationname'))
                let jobnav = (query.get('jobnav'))
                let searchflag = (query.get('searchflag'))
                let jobtype = (query.get('jobtype'))
                let jfunarr = []
                let jlocarr = []
                let jrolearr = []
    
                if (localStorage.getItem("applied_filters") != null && localStorage.getItem("applied_filters").toString() != 'null') {
                    let appliedFilters = JSON.parse(localStorage.getItem("applied_filters"));
    
                    this.setState({
                        profile_jobrole: (this.state.profile_jobrole = appliedFilters.jobTitle),
                        profile_location: (this.state.profile_location = appliedFilters.jobLocation),
                        profile_gender: (this.state.profile_gender = appliedFilters.gender),
                        fromSalary: (this.state.fromSalary = appliedFilters.salary_from),
                        toSalary: (this.state.toSalary = appliedFilters.salary_to),
                        job_experience_array: (this.state.job_experience_array = appliedFilters.experience),
                        applyFilters: (this.state.applyFilters = appliedFilters.applyFilters),
                        isFilterApplied: (this.state.applyFilters = true),
                    })
                }
    
                this.setState({
                    searchflag: this.state.searchflag = searchflag
                })
                if (jobfunctioncode != '' && jobfunctioncode != null) {
                    jfunarr.push(parseInt(jobfunctioncode))
                    if (this.state.searchflag == 2) {
                        this.setState({ jobfunction_val: this.state.jobfunction_val = jobfunctionname })
                    }
                }
                if (jobrolecode != '' && jobrolecode != null) {
                    jrolearr.push(parseInt(jobrolecode))
                    if (this.state.searchflag == 2) {
                        this.setState({ jobrole_val: this.state.jobrole_val = jobrolename })
                    }
                }
                if (locationcodeval != '' && locationcodeval != null) {
                    jlocarr.push(parseInt(locationcodeval))
                    if (this.state.searchflag == 2) {
                        this.setState({ location_val: this.state.location_val = locationname })
                    }
                }
                if (jobnav != '' && jobnav != null) {
                    this.setState({ jobnav: this.state.jobnav = jobnav })
                }
                if (jobtype == 2) {
                    this.setState({ experienceval: this.state.experienceval = [0] })
                }
                this.setState({
                    jobfunctioncodeval: this.state.jobfunctioncodeval = jfunarr,
                    jobfunctioncode_api: this.state.jobfunctioncode_api = [],
                    jobfunctioncode_api: this.state.jobfunctioncode_api = Array.from(new Set(this.state.jobfunctioncodeval.concat(this.state.profile_jobfunction))),
                    // jobrolecodeval: this.state.jobrolecodeval = jrolearr,
                    locationcodeval: this.state.locationcodeval = jlocarr,
                    joblocationcode_api: this.state.joblocationcode_api = [],
                    joblocationcode_api: this.state.joblocationcode_api = Array.from(new Set(this.state.locationcodeval.concat(this.state.profile_location))),
                    jobtype: this.state.jobtype = jobtype
                })
    
                // if( this.state.getroleValue != '0' && this.state.getroleValue != null){
                //     this.get_job_list()
                // }
                // else{
                this.job_list()
                // }
                this.searchprofile_load();
                if (localStorage.getItem('languageFilter') == null || localStorage.getItem('languageFilter') == 'null' || localStorage.getItem('languageFilter') == undefined || localStorage.getItem('languageFilter') == 'undefined') {
                    this.checkFilterBind()
                } else this.call_job_function()
            }
            
            // this.searchprofile_load()
            // this.call_job_function()
            // this.call_job_role()
            // this.accordion()

        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_list", "componentDidMount", 0, error.toString());
        }
    }
    checkFilterBind() {
        if (localStorage.getItem('languageFilter') != null && localStorage.getItem('languageFilter') != 'null' && localStorage.getItem('languageFilter') != undefined && localStorage.getItem('languageFilter') != 'undefined') {
            this.call_job_function()
        } else {
            setTimeout(() => {
                this.checkFilterBind()
            }, 500)
        }
    }
    indian_standard_amount(amt) {
        try {

            var x = parseInt(amt);
            x = x.toString();
            var lastThree = x.substring(x.length - 3);
            var otherNumbers = x.substring(0, x.length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

            return res;
        }
        catch (error) {
        }
    }

    handlePageChange(pageNumber) {
        // console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
    }

    findclick() {
        this.setState({ searchflag: this.state.searchflag = '' })
        //alert(this.state.jobfunction_val)
        if (this.state.jobfunction_val == '') {
            this.setState({ jobfunctioncodeval: this.state.jobfunctioncodeval = [] })
        }
        // alert(this.state.jobrole_val)
        if (this.state.jobrole_val == '') {
            this.setState({ jobrolecodeval: this.state.jobrolecodeval = [] })
        }
        if (this.state.location_val == '') {
            this.setState({ locationcodeval: this.state.locationcodeval = [] })
        }
        let listArr = localStorage.getItem('all_jobs')
        if (listArr == "true") {
            this.job_list('all_jobs')
        }
        else {
            this.job_list()
        }

    }

    simulateClick(e) {
        e.click()
    }
    openCallHRModal = (employermobileno) => {
        // console.log('eeee', emplo)
        if (window.innerWidth <= 768) {
            // this.setState({employermobileno: this.state.employermobileno = employermobileno});
            // this.refs.empmobileno.click();
        }
        else {
            this.setState({ employermobileno: this.state.employermobileno = employermobileno });
            this.setState({
                showCallHRModal: true,
            });
        }

    };
    openCallHRModal = (employermobileno) => {
        console.log('em', employermobileno)
        this.setState({ employermobileno: this.state.employermobileno = employermobileno });
        this.setState({
            showCallHRModal: true,
        });
    };
    openModal = (message) => {
        this.setState({
            showModal: true,
            modalMessage: message,
        });
        setTimeout(() => {
            this.setState({showModal: this.state.showModal= false})
            this.updatecallcount(this.state.jobcode)
            let listArr = localStorage.getItem('all_jobs')
            if (listArr == "true") {
                this.job_list('all_jobs')
            }
            else {
                this.job_list()
            }
            document.location.href = "tel:"+ this.state.employermobileno;
        }, 3000);
        
    }

    closeModal = () => {
        //  window.location.reload();
        this.setState({ showModal: this.state.showModal = false });

        let listArr = localStorage.getItem('all_jobs')
        if (listArr == "true") {
            this.job_list('all_jobs')
        }
        else {
            this.job_list()
        }
    }

    closeHRModal = () => {
        //   window.location.reload();
        this.setState({ showCallHRModal: this.state.showCallHRModal = false });
        // this.job_list()
    }

    viewjob = (jobcode) => {
        // alert("hi")
        // localStorage.setItem('jobcode', jobcode);
        history.push('/Job_details?jobcode=' + jobcode);
    }

    applynow(jobcode, employercode, employermobileno) {
        var accessToken = localStorage.getItem('accessToken');

        try {
            //alert("Hi")
            this.setState({ employermobileno: this.state.employermobileno = employermobileno });
            this.setState({ jobcode: this.state.jobcode = jobcode });
            // this.openModal("Applied Successfully!");
            axios({
                method: 'Post',
                url: this.state.ipurl + 'jobs/jobapply/?',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    employeecode: this.state.employeecode,
                    languagecode: this.state.languagecode,
                    ipaddress: this.state.ipaddress,
                    jobcode: jobcode,
                    employercode: employercode,
                    //    employermobileno:employermobileno,
                },
            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.applynow();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })

                    }
                    else {
                        if (response.data.hasOwnProperty('job_json_result')) {
                            this.state.varstatuscode = response.data.job_json_result.hasOwnProperty('varstatuscode') ? response.data.job_json_result.varstatuscode : 0;
                            let modalMessage = '';
                            //   alert(jobcode)
                            //  alert(this.state.varstatuscode)

                            if (this.state.varstatuscode === 27) {
                                // toast("Custom Style Notification with css class!", {
                                //     position: toast.POSITION.TOP_RIGHT,
                                //     className: 'foo-bar'
                                //   });
                                const message = ` Job applied successfully!`;
                                this.openModal(message);
                                
                            } else if (this.state.varstatuscode === 31) {
                                toast("not_eligible", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 77) {
                                toast("apply_limit", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 83) {
                                toast("alrdy_invited", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 62) {
                                toast("invite_then_reject", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 59) {
                                toast("apply_then_reject", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 7) {
                                toast("user_not_found", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else {
                                toast(this.state.responsestring, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            }

                            if (modalMessage) {
                                this.openModal(modalMessage);
                            }
                        }
                    }
                })
                .catch((error) => {

                });
        } catch (error) {

        }
    }


    job_list(value) {
        var accessToken = localStorage.getItem('accessToken');

        window.fbq('track', 'Website', { fb_ad_function: "job_list", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
        this.setState({ isActive: this.state.isActive = true })
        try {
            // if (this.state.jobnav == 1) {
            //     this.setState({
            //         typecode: this.state.typecode = 2,
            //         limit: this.state.limit = 10
            //     })
            // }
            // else {
            //     this.setState({ typecode: this.state.typecode = 6 })
            // }
            var jobrolecode_api = [];
            if (this.state.currentJobRoleValue != null && this.state.currentJobRoleValue != '' && this.state.currentJobRoleValue != 'null')
                jobrolecode_api.push(this.state.jobrolecodeval);

            var experienceVal = [];
            if (this.state.job_experience_array && this.state.job_experience_array.length > 0) {
                if (this.state.job_experience_array.includes(0)) {
                    experienceVal = [0, 0, this.state.max_experience]
                } else {
                    var maxExp = Math.min(...this.state.job_experience_array) || 0
                    experienceVal = [maxExp, this.state.max_experience]
                }
            }
            this.setState({ typecode: this.state.typecode = 6 })
            localStorage.removeItem("jobnavication")
            var varurl = '';
            //alert(this.state.employeecode)
            if (this.state.employeecode > 0) {
                varurl = this.state.ipurl + 'jobs/recommended'
            }
            else {
                // alert("jobslist")
                varurl = this.state.ipurl + 'jobs/jobslist'
            }
            var data_body = {};
            if (value != '' && value != undefined) {
                data_body = {
                    skillcode: [],
                    locationcode: [],
                    jobfunctioncode: [],
                    jobrolecode: [],
                    jobtypecode: [],
                    schoolqualcode: [],
                    afterschoolcatecode: [],
                    anydegree: "true",
                    anyqualification: "true",
                    anyspec: "true",
                    afterschoolqualcode: [],
                    afterschoolspeccode: [],
                    experiencecode: [],
                    industrycode: [],
                    employertypecode: [],
                    companytypecode: [],
                    maritalcode: [],
                    gendercode: [],
                    differentlyabled: -1,
                    salaryfrom: '',
                    salaryto: '',
                    agefrom: 0,
                    ageto: 0,
                    anyage: "true"
                }
            }
            if (((this.state.currentJobRoleValue != null && this.state.currentJobRoleValue != '' && this.state.currentJobRoleValue != 'null') || (this.state.profile_jobrole && this.state.profile_jobrole.length > 0) || (this.state.profile_location && this.state.profile_location.length > 0) || (this.state.profile_gender && this.state.profile_gender.length > 0) || (this.state.job_experience_array && this.state.job_experience_array.length > 0) || (this.state.salaryRangeVal[0] != 0 && this.state.salaryRangeVal[1] != 100000))) {
                data_body = {
                    skillcode: this.state.skillcodeval,
                    locationcode: this.state.joblocationcode_api,
                    jobfunctioncode: this.state.jobfunctioncode_api,
                    jobrolecode: this.state.currentJobRoleValue != null && this.state.currentJobRoleValue != '' && this.state.currentJobRoleValue != 'null' ? jobrolecode_api : this.state.profile_jobrole
                    ,
                    jobtypecode: this.state.jobtypecodeval.length > 0 ? this.state.jobtypecodeval : [],
                    schoolqualcode: [],
                    afterschoolcatecode: [],
                    anydegree: "true",
                    anyqualification: "true",
                    anyspec: "true",
                    afterschoolqualcode: [],
                    afterschoolspeccode: [],
                    experiencecode: experienceVal,
                    industrycode: [],
                    employertypecode: [],
                    companytypecode: [],
                    maritalcode: [],
                    gendercode: this.state.profile_gender,
                    differentlyabled: -1,
                    salaryfrom: this.state.fromSalary,
                    salaryto: this.state.toSalary,
                    agefrom: 0,
                    ageto: 0,
                    anyage: "true"
                }
            }
            axios({
                method: 'Post',
                url: varurl,
                crossdomain: true,
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    employeecode: this.state.employeecode,
                    ipaddress: this.state.ipaddress,
                    languagecode: this.state.languagecode,
                    type: this.state.typecode,
                    skip: this.state.skip,
                    limit: this.state.limit
                },
                data: data_body

            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.job_list();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })

                    }
                    else {
                        this.setState({ isActive: this.state.isActive = false })
                        // alert(response.data.job_list_json_result.varstatuscode)
                        if (this.state.typecode == 6) {
                            // console.log('jobtype'+this.state.jobtype)
                            if (response.data.job_list_json_result.varstatuscode === 4) {
                                this.setState({ jobalertdays: this.state.jobalertdays = response.data.job_list_json_result.jobalertdays })
                                this.setState({ totalCount: this.state.totalCount = response.data.job_list_json_result.job_list_total })
                                // this.setpaginator()
                                // let flash_job_list = []
                                let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                                let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list') ? response.data.job_list_json_result.job_list : []
                                this.setState({ flash_job_list: this.state.flash_job_list = flash_job_list });
                                for (let i = 0; i < recommended_list.length; i++) {
                                    recommended_list[i].flash = 0;
                                    if (recommended_list[i].hasOwnProperty('locationname'))
                                        recommended_list[i].locationname.sort();
                                }
                                for (let i = 0; i < flash_job_list.length; i++) {
                                    flash_job_list[i].flash = 1;
                                    if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                        flash_job_list[i].joblocationname.sort();
                                }
                                let arr = [];
                                let flashlist = flash_job_list;
                                if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                                    for (let i = 0; i < recommended_list.length; i++) {
                                        if (i != 0 && i % 4 == 0) {
                                            if (flashlist && flashlist.length > 0) {
                                                arr.push(flashlist[0]);
                                                flashlist.shift();
                                            }
                                            arr.push(recommended_list[i]);
                                        } else {
                                            arr.push(recommended_list[i]);
                                        }
                                    }
                                    if (flashlist && flashlist.length > 0) {
                                        flashlist.forEach(element => {
                                            arr.push(element);
                                        });
                                    }
                                } else {
                                    if ((recommended_list && recommended_list.length > 0)) {
                                        arr = recommended_list;
                                    }
                                    if ((flash_job_list && flash_job_list.length > 0)) {
                                        arr = flash_job_list;
                                    }
                                }
                                this.setState({ recommended_list: this.state.recommended_list = arr });
                                let rowData = []
                                if (this.state.jobtype == 1) {
                                    rowData = response.data.job_list_json_result.job_list
                                }
                                else if (this.state.jobtype == 3) {
                                    rowData = response.data.job_list_json_result.flash_job_list
                                }
                                else {
                                    rowData = arr;
                                }
                                JSON.stringify(rowData);
                                this.setState({ rowData: this.state.rowData = rowData });
                                this.setState({ currentItems: rowData })
                                this.setPaginator()
                                this.joblist(this.state.currentItems)
                                

                            }
                            else if (response.data.job_list_json_result.varstatuscode === 6) {
                                this.setState({
                                    rowData: this.state.rowData = [],
                                    currentItems: this.currentItems = []
                                });
                                if (this.state.currentItems.length == 0 && localStorage.getItem("istabclick") == 0)
                                {
                                    this.job_loading("3")
                                }
                                else
                                {
                                    this.job_loading((localStorage.getItem("istabclick") != null && localStorage.getItem("istabclick") != undefined && localStorage.getItem("istabclick") != "undefined") ? localStorage.getItem("istabclick").toString() : "3")
                                }
                                this.setPaginator()
                                this.setState({ totalCount: this.state.totalCount = 0 })
                                this.joblist(this.state.currentItems)
                            }
                            // console.log(this.state.rowData)
                        }
                        else {
                            if (response.data.job_list_json_result.varstatuscode === 4) {
                                // let flash_job_list = []
                                let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                                //let flash_job_list = []
                                let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list_count') ? response.data.job_list_json_result.job_list_count : []
                                this.setState({ flash_job_list: this.state.flash_job_list = flash_job_list });
                                for (let i = 0; i < recommended_list.length; i++) {
                                    recommended_list[i].flash = 0;
                                    if (recommended_list[i].hasOwnProperty('locationname'))
                                        recommended_list[i].locationname.sort();
                                }
                                for (let i = 0; i < flash_job_list.length; i++) {
                                    flash_job_list[i].flash = 1;
                                    if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                        flash_job_list[i].joblocationname.sort();
                                }
                                let arr = [];
                                let flashlist = flash_job_list;
                                if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                                    for (let i = 0; i < recommended_list.length; i++) {
                                        if (i != 0 && i % 4 == 0) {
                                            if (flashlist && flashlist.length > 0) {
                                                arr.push(flashlist[0]);
                                                flashlist.shift();
                                            }
                                            arr.push(recommended_list[i]);
                                        } else {
                                            arr.push(recommended_list[i]);
                                        }
                                    }
                                    if (flashlist && flashlist.length > 0) {
                                        flashlist.forEach(element => {
                                            arr.push(element);
                                        });
                                    }
                                } else {
                                    if ((recommended_list && recommended_list.length > 0)) {
                                        arr = recommended_list;
                                    }
                                    if ((flash_job_list && flash_job_list.length > 0)) {
                                        arr = flash_job_list;
                                    }
                                }
                                this.setState({ recommended_list: this.state.recommended_list = arr });
                                let rowData = arr

                                JSON.stringify(rowData);
                                this.setState({ rowData: this.state.rowData = rowData });
                                this.setState({ currentItems: rowData })
                                this.setPaginator()
                                this.joblist(this.state.currentItems)
                            }
                            else if (response.data.job_list_json_result.varstatuscode === 6) {
                                this.setState({
                                    rowData: this.state.rowData = [],
                                    currentItems: this.currentItems = []
                                });
                                this.setPaginator()
                                this.setState({ totalCount: this.state.totalCount = 0 })
                                this.joblist(this.state.currentItems)
                            }
                        }
                    }
                    this.scrollToTop()
                })
        }
        catch (error) {
            this.setState({ isActive: this.state.isActive = false })
            this.setState({ totalCount: this.state.totalCount = 0 })
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "onclicksearch_btn", 0, error.toString());
        }

    }
    setPaginator() {
        var moduleVal = this.state.totalCount % this.state.itemsPerPage
        var fullvalue = Math.floor(this.state.totalCount / this.state.itemsPerPage)
        //  alert(fullvalue)
        if (moduleVal > 0) {
            var wholeVal = moduleVal
            wholeVal = fullvalue + 1;
            this.setState({ pageCount: this.state.pageCount = wholeVal });
        }
        else {
            this.setState({ pageCount: this.state.pageCount = fullvalue });
        }
        // if(this.state.startPage == this.state.totalCount){
        //     this.setState({endPage:this.state.endPage =this.state.totalCount})
        // }
        // if(this.state.endPage > this.state.totalCount ){
        //     this.setState({endPage:this.state.endPage =this.state.totalCount})
        // }
        var sPageCount = this.state.skip + 1;
        var endpageCount = this.state.currentItems.length < this.state.limit ? this.state.totalCount : (sPageCount - 1) + this.state.limit;
        this.setState({ startPage: sPageCount, endPage: this.state.endPage = endpageCount })
    }
    applied_job_list() {
        var accessToken = localStorage.getItem('accessToken');
        window.fbq('track', 'Website', { fb_ad_function: "job_list", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
        this.setState({
            isActive: this.state.isActive = true,
            totalCount: (this.state.totalCount = 0)
        })
        try {
            // if (this.state.jobnav == 1) {
            //     this.setState({
            //         typecode: this.state.typecode = 2,
            //         limit: this.state.limit = 10
            //     })
            // }
            // else {
            //     this.setState({ typecode: this.state.typecode = 6 })
            // }

            this.setState({ typecode: this.state.typecode = 6 })
            localStorage.removeItem("jobnavication")

            axios({
                method: 'Post',
                url: this.state.ipurl + '/jobs/applied',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    deviceip: this.state.ipaddress,
                    employeecode: this.state.employeecode,
                    sortbycode: 0,
                    languagecode: this.state.languagecode,
                },

                data: this.state.bind_filter_array
            }).then(async response => {
                if (response.data.status == 401) {
                    await generateRefreshToken().then((result) => {
                        if (result == 1) {
                            this.applied_job_list();
                        }
                        else {
                            // alert('Unauthorized')
                        }
                    })

                }
                else {
                    this.setState({ isActive: this.state.isActive = false })
                    // alert(response.data.job_list_json_result.varstatuscode)
                    if (this.state.typecode == 6) {
                        // console.log('jobtype'+this.state.jobtype)
                        if (response.data.job_list_json_result.varstatuscode === 4) {
                            this.setState({ jobalertdays: this.state.jobalertdays = response.data.job_list_json_result.jobalertdays })
                            // let flash_job_list = []
                            let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                            let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list') ? response.data.job_list_json_result.job_list : []
                            this.setState({ flash_job_list: this.state.flash_job_list = flash_job_list });
                            for (let i = 0; i < recommended_list.length; i++) {
                                recommended_list[i].flash = 0;
                                if (recommended_list[i].hasOwnProperty('locationname'))
                                    recommended_list[i].locationname.sort();
                            }
                            for (let i = 0; i < flash_job_list.length; i++) {
                                flash_job_list[i].flash = 1;
                                if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                    flash_job_list[i].joblocationname.sort();
                            }
                            let arr = [];
                            let flashlist = flash_job_list;
                            if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                                for (let i = 0; i < recommended_list.length; i++) {
                                    if (i != 0 && i % 4 == 0) {
                                        if (flashlist && flashlist.length > 0) {
                                            arr.push(flashlist[0]);
                                            flashlist.shift();
                                        }
                                        arr.push(recommended_list[i]);
                                    } else {
                                        arr.push(recommended_list[i]);
                                    }
                                }
                                if (flashlist && flashlist.length > 0) {
                                    flashlist.forEach(element => {
                                        arr.push(element);
                                    });
                                }
                            } else {
                                if ((recommended_list && recommended_list.length > 0)) {
                                    arr = recommended_list;
                                }
                                if ((flash_job_list && flash_job_list.length > 0)) {
                                    arr = flash_job_list;
                                }
                            }
                            this.setState({ recommended_list: this.state.recommended_list = arr });
                            let rowData = []
                            if (this.state.jobtype == 1) {
                                rowData = response.data.job_list_json_result.job_list
                            }
                            else if (this.state.jobtype == 3) {
                                rowData = response.data.job_list_json_result.flash_job_list
                            }
                            else {
                                rowData = arr;
                            }
                            JSON.stringify(rowData);
                            this.setState({ rowData: this.state.rowData = rowData });
                            this.setState({ currentItems: rowData })
                            this.setPaginator()
                            this.joblist(this.state.currentItems)
                        }
                        else if (response.data.job_list_json_result.varstatuscode === 6) {
                            this.setState({
                                rowData: this.state.rowData = [],
                                currentItems: this.currentItems = []
                            });
                            this.setPaginator()
                            this.setState({ totalCount: this.state.totalCount = 0 })
                            this.joblist(this.state.currentItems)
                        }
                    }
                    else {
                        if (response.data.job_list_json_result.varstatuscode === 4) {
                            // let flash_job_list = []
                            let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                            //let flash_job_list = []
                            let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list_count') ? response.data.job_list_json_result.job_list_count : []
                            this.setState({ flash_job_list: this.state.flash_job_list = flash_job_list });
                            for (let i = 0; i < recommended_list.length; i++) {
                                recommended_list[i].flash = 0;
                                if (recommended_list[i].hasOwnProperty('locationname'))
                                    recommended_list[i].locationname.sort();
                            }
                            for (let i = 0; i < flash_job_list.length; i++) {
                                flash_job_list[i].flash = 1;
                                if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                    flash_job_list[i].joblocationname.sort();
                            }
                            let arr = [];
                            let flashlist = flash_job_list;
                            if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                                for (let i = 0; i < recommended_list.length; i++) {
                                    if (i != 0 && i % 4 == 0) {
                                        if (flashlist && flashlist.length > 0) {
                                            arr.push(flashlist[0]);
                                            flashlist.shift();
                                        }
                                        arr.push(recommended_list[i]);
                                    } else {
                                        arr.push(recommended_list[i]);
                                    }
                                }
                                if (flashlist && flashlist.length > 0) {
                                    flashlist.forEach(element => {
                                        arr.push(element);
                                    });
                                }
                            } else {
                                if ((recommended_list && recommended_list.length > 0)) {
                                    arr = recommended_list;
                                }
                                if ((flash_job_list && flash_job_list.length > 0)) {
                                    arr = flash_job_list;
                                }
                            }
                            this.setState({ recommended_list: this.state.recommended_list = arr });
                            let rowData = arr

                            JSON.stringify(rowData);
                            this.setState({ rowData: this.state.rowData = rowData });
                            this.setState({ currentItems: rowData })
                            this.setPaginator()
                            this.joblist(this.state.currentItems)
                        }
                        else if (response.data.job_list_json_result.varstatuscode === 6) {
                            this.setState({
                                rowData: this.state.rowData = [],
                                currentItems: this.currentItems = []
                            });
                            this.setPaginator()
                            this.setState({ totalCount: this.state.totalCount = 0 })
                            this.joblist(this.state.currentItems)
                        }
                    }
                }

            })
        }
        catch (error) {
            this.setState({ isActive: this.state.isActive = false })
            this.setState({ totalCount: this.state.totalCount = 0 })
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "onclicksearch_btn", 0, error.toString());
        }

    }
    //Bind job function 
    // call_job_function() {
    //     window.fbq('track', 'Website', { fb_ad_function: "call_job_function", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
    //     if (this.state.dpjobfunction.length == 0) {
    //         this.setState({ isSmallLoaderJobFunctionActive: this.state.isSmallLoaderJobFunctionActive = true })
    //         axios({
    //             method: 'Post',
    //             url: this.state.ipurl + 'employee/filterbind/',
    //             crossdomain: true,
    //             params: {
    //                 deviceip: this.state.ipaddress,
    //                 type: 0,
    //                 languagecode: this.state.languagecode,
    //                 // employeecode: 13641,
    //                 employeecode: this.state.employeecode
    //             }
    //         })
    //         // alert(this.state.employeecode)
    //             .then(response => {
    //                 this.setState({ isSmallLoaderJobFunctionActive: this.state.isSmallLoaderJobFunctionActive = false })

    //                 if (response.data.jobfunction_filter_json_result.varstatuscode === 4) {
    //                     //jobfunction
    //                     var mjfun_rowdata = response.data.jobfunction_filter_json_result.jobfunctionlist.filter(e => e.languagecode == this.state.languagecode);
    //                     mjfun_rowdata.sort(this.GetSortOrder('jobfunctionname'))
    //                     mjfun_rowdata.sort();
    //                     this.state.dpjobfunction = response.data.jobfunction_filter_json_result.jobfunctionlist.filter(e => e.languagecode == this.state.languagecode);
    //                     JSON.stringify(this.state.dpjobfunction);
    //                     this.setState({ dpjobfunction: this.state.dpjobfunction });
    //                     this.bind_jobfunction(mjfun_rowdata, 1)


    //                 }


    //                 else if (response.data.jobfunction_filter_json_result.varstatuscode === 7) {
    //                     localStorage.clear()
    //                 }
    //                 else {
    //                     this.setState({ isSmallLoaderJobFunctionActive: this.state.isSmallLoaderJobFunctionActive = false })
    //                 }
    //             })
    //             .catch(error => {
    //                 //   this.setState({ isSmallLoaderJobFunctionActive: this.state.isSmallLoaderJobFunctionActive = false })
    //                 //   error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
    //             });
    //     }
    // }


    call_job_function() {
        // alert("hlo")
        // window.fbq('track', 'Website', { fb_ad_function: "call_job_function", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
        // if (this.state.dpjobfunction.length === 0) {
        //     this.setState({ isSmallLoaderJobFunctionActive: true });
        //     this.setState({ isFilterActive: (this.state.isFilterActive = true)})
        //     axios({
        //         method: 'Post',
        //         url: this.state.ipurl + 'employee/filterbind/',
        //         crossdomain: true,
        //         params: {
        //             deviceip: this.state.ipaddress,
        //             type: 0,
        //             languagecode: this.state.languagecode,
        //             employeecode: this.state.employeecode,
        //         }
        //     })
        //     .then(response => {
        // this.setState({ isFilterActive: (this.state.isFilterActive = false)})

        // this.setState({ isSmallLoaderJobFunctionActive: false });
        var skill_list = localStorage.getItem("filter_skill_list") != null ? JSON.parse(localStorage.getItem("filter_skill_list")) : [];
        var mjfun_rowdata = skill_list.filter(e => e.languagecode == this.state.languagecode);
        mjfun_rowdata.sort(this.GetSortOrder('jobfunctionname'));
        mjfun_rowdata.sort();

        var jobfunctionlist = localStorage.getItem("filter_job_function_list") != null ? JSON.parse(localStorage.getItem("filter_job_function_list")) : [];
        this.state.dpjobfunction = jobfunctionlist.filter(e => e.languagecode == this.state.languagecode);

        JSON.stringify(this.state.dpjobfunction);
        this.setState({ dpjobfunction: this.state.dpjobfunction });
        this.bind_jobfunction(mjfun_rowdata, 1);

        // Job Skill
        var mjskill_rowdata, flag_arr = [];
        var skilllist = skill_list.filter(e => e.languagecode == this.state.languagecode);
        this.setState({ skill_full_list: skilllist });

        // Gender
        var genderlist = localStorage.getItem("filter_gender_list") != null ? JSON.parse(localStorage.getItem("filter_gender_list")) : [];
        var mjgender_rowdata = genderlist.filter(e => e.languagecode == this.state.languagecode);
        this.setState({ dpgender: mjgender_rowdata });
        this.setState({ jobgenderarr: mjgender_rowdata });
        this.bind_genderstatus(mjgender_rowdata, 8);
        // alert( response.data.jobgroup_filter_json_result.genderlist);

        // Location
        var state_list = localStorage.getItem("filter_state_list") != null ? JSON.parse(localStorage.getItem("filter_state_list")) : [];
        var sortfilter_ary = state_list.filter(e => e.languagecode == this.state.languagecode);
        sortfilter_ary.sort(this.GetSortOrder("statename"));
        this.state.dpbindstate = sortfilter_ary.map(sortfilter_ary => {
            return { value: sortfilter_ary.statecode, label: sortfilter_ary.statename }
        });
        JSON.stringify(this.state.dpbindstate);
        this.setState({ dpbindstate: this.state.dpbindstate });

        // Additional Logic for Job Location
        var district_list = localStorage.getItem("filter_district_list") != null ? JSON.parse(localStorage.getItem("filter_district_list")) : [];
        var mlocat_rowdata = district_list.filter(e => e.languagecode == this.state.languagecode);
        mlocat_rowdata.sort(this.GetSortOrder('districtname'));
        this.setState({ dpdistrict: mlocat_rowdata });
        this.setState({ districtarray: mlocat_rowdata });
        this.bind_location(mlocat_rowdata, 0);

        // Job Role
        var jobrolelist = localStorage.getItem("filter_jobrole_list") != null ? JSON.parse(localStorage.getItem("filter_jobrole_list")) : [];
        var mjrole_rowdata = jobrolelist.filter(e => e.languagecode == Number(localStorage.getItem('languagecode')));
        mjrole_rowdata.sort(this.GetSortOrder('jobrolename'));
        mjrole_rowdata.sort();
        // mjrole_rowdata.forEach(row => {
        //     if (this.state.profile_jobrole.includes(row.jobrolecode)) {
        //         row["status"] = true;
        //     } else {
        //         row["status"] = false;
        //     }
        // });



        for (var i = 0; i < this.state.profile_jobrole.length; i++) {
            let index = mjrole_rowdata.findIndex(el => el.jobrolecode == this.state.profile_jobrole[i]);

            if (index != -1) {
                mjrole_rowdata[index]['status'] = true
            }
        }

        this.setState({
            allJobRoleArray: this.state.allJobRoleArray = jobrolelist
        });
        // console.log("alljobrolearraybykavitha", JSON.parse(localStorage.getItem("filter_jobrole_list")));
        this.pageFilterBind(this.state.FilterTitleitemOffset, this.state.FilterTitleitemsPerPage)
        this.bind_job_role(this.state.jobRoleArray, 0)

        // Job Experience
        var experiencelist = localStorage.getItem("filter_experience_list") != null ? JSON.parse(localStorage.getItem("filter_experience_list")) : [];
        var mjExperienceList = experiencelist;
        this.setState({ dpExperience: mjExperienceList });
        this.bind_Experience(mjExperienceList);
        // alert( response.data.jobgroup_filter_json_result.genderlist);

        // if (this.state.profile_jobfunction.length > 0) {
        //     mjrole_rowdata = mjrole_rowdata.filter(e => this.state.profile_jobfunction.includes(e.jobfunctioncode));
        // }
        // this.setState({ jobrole_full_list: mjrole_rowdata });

        // var bind_collapseItems = [
        //     {
        //         id: 2,
        //         title: "Job Role",
        //         icon: <i className='fa fa-chevron-down collapse-icon'></i>,
        //         content: this.bind_jobrole(mjrole_rowdata, 2)
        //     },
        // ];
        // this.setState({ collapseItems: bind_collapseItems });
        // this.setState({ collapseItems: this.state.collapseItems });

        // })
        // .catch(error => {
        //     this.setState({ isFilterActive: (this.state.isFilterActive = false)})
        // });
        // }
    }
    // call_gender() {

    //     window.fbq('track', 'Website', { fb_ad_function: "call_gender", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
    //     if (this.state.dpgender.length == 0) {

    //         this.setState({ isSmallLoaderGenderActive: this.state.isSmallLoaderGenderActive = true });
    //         axios({
    //             method: 'Post',
    //             url: this.state.ipurl + 'employee/filterbind/',
    //             crossdomain: true,
    //             params: {
    //                 deviceip:this.state.ipaddress,
    //                 type: 10,
    //                 languagecode: this.state.languagecode,
    //                 employeecode: this.state.employeecode,
    //             }
    //         })
    //             .then(response => {
    //                 if (response.data.jobgroup_filter_json_result.varstatuscode === 4) {
    //                     //gender status
    //                     var mjgender_rowdata = response.data.jobgroup_filter_json_result.genderlist.filter(e => e.languagecode == this.state.languagecode)
    //                     this.setState({ dpgender: this.state.dpgender = mjgender_rowdata });
    //                     // if (mjgender_rowdata) {
    //                     //     mjgender_rowdata.forEach(row => {
    //                     //         if (this.state.profile_gender.includes(row.gendercode)) {
    //                     //             row["status"] = true;
    //                     //         }
    //                     //         else {
    //                     //             row["status"] = false;
    //                     //         }
    //                     //     });
    //                     // }
    //                     this.setState({ jobgenderarr: this.state.jobgenderarr = mjgender_rowdata })
    //                     this.bind_genderstatus(mjgender_rowdata, 8)

    //                 }
    //                 else if (response.data.jobgroup_filter_json_result.varstatuscode === 7) {
    //                     // toast.warn(this.state.blocked_msg, {
    //                     //   autoClose: this.state.timeset
    //                     // })
    //                     localStorage.clear()
    //                     // setTimeout(() => {
    //                     //     history.push('/');
    //                     // }, 2000);
    //                 } else {
    //                     this.setState({ isSmallLoaderGenderActive: this.state.isSmallLoaderGenderActive = false })
    //                 }

    //             })
    //             .catch(error => {
    //                 this.setState({ isSmallLoaderGenderActive: this.state.isSmallLoaderGenderActive = false })
    //                 error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
    //             });
    //     }
    // }

    //Bind job skill 
    // call_job_skill() {
    //     window.fbq('track', 'Website', { fb_ad_function: "call_job_skill", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
    //     if (this.state.skill_full_list.length == 0) {
    //         axios({
    //             method: 'Post',
    //             url: this.state.ipurl + 'employee/filterbind/',
    //             crossdomain: true,
    //             params: {
    //                 deviceip: this.state.ipaddress,
    //                 type: 1,
    //                 languagecode: this.state.languagecode,
    //                 employeecode: this.state.employeecode,
    //             }
    //         })
    //             .then(response => {
    //                 if (response.data.skill_filter_json_result.varstatuscode === 4) {

    //                     //job skill
    //                     var mjskill_rowdata, flag_arr = []
    //                     var skilllist = response.data.skill_filter_json_result.skill_list.filter(e => e.languagecode == this.state.languagecode);
    //                     // if (this.state.profile_jobrole.length > 0) {
    //                     //     skilllist = skilllist.filter(e => this.state.profile_jobrole.includes(e.jobrolecode));
    //                     // }

    //                     this.setState({ skill_full_list: this.state.skill_full_list = skilllist })
    //                     //remove duplicate
    //                     skilllist.map(e => {
    //                         if (flag_arr.length > 0) {
    //                             let filter = flag_arr.some(q => q.skillcode === e.skillcode);
    //                             if (!filter)
    //                                 flag_arr.push(e)
    //                         } else {
    //                             flag_arr.push(e)
    //                         }
    //                     })
    //                     mjskill_rowdata = flag_arr
    //                     this.bind_jobskill(mjskill_rowdata, 3)
    //                     mjskill_rowdata.sort(this.GetSortOrder('skillname'))
    //                     if (mjskill_rowdata) {
    //                         mjskill_rowdata.forEach(row => {
    //                             if (this.state.skillcodeval.includes(row.skillcode)) {
    //                                 row["status"] = true;
    //                             }
    //                             else {
    //                                 row["status"] = false;
    //                             }
    //                         });
    //                     }

    //                 }
    //                 else if (response.data.skill_filter_json_result.varstatuscode === 7) {
    //                     // toast.warn(this.state.blocked_msg, {
    //                     //   autoClose: this.state.timeset
    //                     // })
    //                     localStorage.clear()
    //                     // setTimeout(() => {
    //                     //     history.push('/');
    //                     // }, 2000);
    //                 }
    //                 else {
    //                     this.setState({ isSmallLoaderJobSkillActive: this.state.isSmallLoaderJobSkillActive = false })
    //                 }
    //             })
    //             .catch(error => {
    //                 this.setState({ isSmallLoaderJobSkillActive: this.state.isSmallLoaderJobSkillActive = false })
    //                 error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
    //             });
    //     } else {
    //         if (this.state.profile_jobrole.length > 0) {
    //             this.state.skill_full_list = this.state.skill_full_list.filter(e => this.state.profile_jobrole.includes(e.jobrolecode));
    //         }
    //     }
    // }
    //jobfunction
    bind_job_role(mjfun_rowdata, idfun = 1) {
        try {
            // this.state.jobfunctionarray =[...mjfun_rowdata];
            this.state.jobRoleArray = mjfun_rowdata;
            //alert(mjfun_rowdata.length)
            if (this.state.jobRoleArray && this.state.jobRoleArray.length > 0) {
                //alert("iam")
                return this.state.jobRoleArray.map((jfun, index) => {
                    const { jobrolecode, status, jobrolename } = jfun
                    return (
                        <div className="cookies-checkbox mt-15" >
                            <div className="d-flex flex-row justify-content-start">
                                {/* <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value={jobrolecode}
                                    onClick={(e) => this.jfunctionclick(e, idfun)}
                                /> */}
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    // style={{backgroundColor: (status)? 'transparent': 'red'}}
                                    id="jobfunction"
                                    icon=""
                                    label={jobrolename}
                                    checked={status}
                                    onChange={(e) => {
                                        this.job_role_click(e, jobrolecode)
                                    }}
                                />
                                {/* <span>{jobrolename}</span> */}
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            // error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_jobfunction", 0, error.toString());
        }

    }

    pageFilterBind(offset, itemsPerPage) {
        this.setState({ FilterTitleitemOffset: this.state.FilterTitleitemOffset = offset })
        var endOffset = this.state.FilterTitleitemOffset + itemsPerPage;
        var count = this.state.allJobRoleArray.slice(this.state.FilterTitleitemOffset, endOffset);
        this.state.jobRoleArray = [...this.state.jobRoleArray, ...count];
        this.setState({ jobRoleArray: this.state.jobRoleArray = this.state.jobRoleArray });
        // this.setState({ pageCount: this.state.pageCount = Math.ceil(this.state.allJobRoleArray.length / itemsPerPage) });
    }

    handleScroll = (e) => {
        // const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        const bottom = Math.round(e.target.scrollTop) === (e.target.scrollHeight - e.target.offsetHeight);

        // obj.scrollTop === (obj.scrollHeight - obj.offsetHeight)
        if (bottom && !this.state.jobrole_val) {
            this.setState({
                FilterCurrentOffset: (this.state.FilterCurrentOffset = this.state.FilterCurrentOffset + 1)
            });
            var newOffset = (this.state.FilterCurrentOffset * this.state.FilterTitleitemsPerPage) % this.state.allJobRoleArray.length;
            this.setState({ FilterTitleitemOffset: this.state.FilterTitleitemOffset = newOffset })
            this.pageFilterBind(newOffset, this.state.FilterTitleitemsPerPage)
        }
    }

    bind_jobfunction(mjfun_rowdata, idfun = 1) {
        try {
            // this.state.jobfunctionarray =[...mjfun_rowdata];
            this.state.jobfunctionarray = mjfun_rowdata;
            //alert(mjfun_rowdata.length)
            if (this.state.jobfunctionarray && this.state.jobfunctionarray.length > 0) {
                //alert("iam")
                return this.state.jobfunctionarray.map((jfun, index) => {
                    const { jobfunctioncode, status, jobfunctionname } = jfun
                    return (
                        <div className="cookies-checkbox mt-15">
                            <div className="d-flex flex-row justify-content-start">
                                {/* <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value={jobfunctioncode}
                                    onClick={(e) => this.jfunctionclick(e, idfun)}
                                /> */}
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    id="jobfunction"
                                    icon=""
                                    label={jobfunctionname}
                                    checked={jfun.checked}
                                    onChange={(e) => {
                                        this.jfunctionclick(e, jobfunctioncode)
                                    }}
                                />
                                {/* <span>{jobfunctionname}</span> */}
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            // error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_jobfunction", 0, error.toString());
        }

    }

    job_role_click = (event, jobrolecode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            let index = this.state.jobRoleArray.findIndex(el => el.jobrolecode == jobrolecode);

            if (index != -1) {
                this.state.jobRoleArray[index]['status'] = check
            }

            let indexInArray = this.state.allJobRoleArray.findIndex(el => el.jobrolecode == jobrolecode);

            if (indexInArray != -1) {
                this.state.allJobRoleArray[indexInArray]['status'] = check
            }

            //     if (mjfunctions.jobrolecode === Number(jobrolecode)) {
            //             if (mjfunctions.jobrolecode === Number(jobrolecode)) {
            //                 mjfunctions.status = !mjfunctions.status;
            //             }
            //         }
            //         });
            if (check === true) {
                this.state.profile_jobrole.push(jobrolecode);
                this.setState({ profile_jobrole: this.state.profile_jobrole });
            }
            else {
                let i = this.state.profile_jobrole.indexOf(jobrolecode);
                if (i > -1) {
                    this.state.profile_jobrole.splice(i, 1)
                }
                this.setState({ profile_jobrole: this.state.profile_jobrole });
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, this.state.version, "Search_Profiles", "jfunctionone", 0, error.toString());
        }

    }

    jfunctionclick = (event, jobfunctioncode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            this.state.jobfunctionarray.forEach(mjfunctions => {
                if (mjfunctions.jobfunctioncode === Number(jobfunctioncode)) {
                    mjfunctions.status = !mjfunctions.status;
                    if (mjfunctions.status === true) {
                        this.state.profile_jobfunction.push(mjfunctions.jobfunctioncode)
                        this.setState({ profile_jobfunction: this.state.profile_jobfunction });
                    } else {
                        //this.setState({profile_jobfunction:[]})
                        let i = this.state.profile_jobfunction.indexOf(mjfunctions.jobfunctioncode);
                        if (i > -1) {
                            this.state.profile_jobfunction.splice(i, 1)
                        }
                        this.setState({ profile_jobfunction: this.state.profile_jobfunction });
                        // let profile_jobfunction = this.state.profile_jobfunction.splice(this.state.profile_jobfunction.indexOf(mjfunctions.jobfunctioncode), 1);
                        // this.setState({ profile_jobfunction: profile_jobfunction });
                    }
                }
            });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, this.state.version, "Search_Profiles", "jfunctionone", 0, error.toString());
        }

    }

    //job skills
    bind_jobskill(mjskill_rowdata, idskill) {
        try {
            // this.state.skillarray =[...mjskill_rowdata];
            this.state.skillarray = mjskill_rowdata;
            if (this.state.skillarray && this.state.skillarray.length > 0) {
                return this.state.skillarray.map((idskill, index) => {
                    const { skillcode, jobrolecode, status, jobfunctioncode, skillname } = idskill
                    return (
                        <div className="cookies-checkbox mt-15">
                            <div className="d-flex flex-row justify-content-start">
                                {/* <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value={skillcode}
                                    onClick={(e) => this.jfunctionclick(e, idskill)}
                                /> */}
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    id="jobskill"
                                    icon=""
                                    label={skillname}
                                    checked={idskill.checked}
                                    onChange={(e) => {
                                        this.jskillone(e, skillcode)
                                    }}
                                />
                            </div>
                        </div>

                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_jobskill", 0, error.toString());
        }

    }

    //onchange skills
    jskillone = (event, skillcode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            this.state.skillarray.forEach(mskills => {
                if (mskills.skillcode === Number(skillcode)) {
                    mskills.status = !mskills.status;
                    if (mskills.status === true) {
                        this.state.skillcodeval.push(mskills.skillcode)
                        this.setState({ skillcodeval: this.state.skillcodeval });
                    } else {
                        //this.setState({profile_skill:[]})
                        let i = this.state.skillcodeval.indexOf(mskills.skillcode);
                        if (i > -1) {
                            this.state.skillcodeval.splice(i, 1)
                        }
                        this.setState({ skillcodeval: this.state.skillcodeval });

                        // let profile_skill = this.state.profile_skill.splice(this.state.profile_skill.indexOf(mskills.skillcode), 1);
                        // this.setState({ profile_skill: profile_skill });
                    }
                }
            });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "jskillone", 0, error.toString());
        }

    }

    //Bind Gender


    //gender status
    //gender
    bind_genderstatus(mjgender_rowdata, idgender) {
        try {
            this.state.jobgenderarr = mjgender_rowdata;

            if (this.state.jobgenderarr && this.state.jobgenderarr.length > 0) {
                return this.state.jobgenderarr.map((idgender, index) => {
                    const { gendercode, gendername, status } = idgender
                    // console.log(gendername)
                    return (
                        <div className="cookies-checkbox mt-15">
                            <div className="d-flex flex-row justify-content-start">
                                {/* <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value={gendercode}
                                    onClick={(e) => this.jobgenderone(e, idgender)}
                                /> */}
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    id="jobfunction"
                                    icon=""
                                    label={gendername}
                                    checked={this.state.profile_gender.includes(gendercode)}
                                    onChange={(e) => {
                                        this.jobgenderone(e, gendercode)
                                    }}
                                />
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Job_list", "bind_genderstatus", 0, error.toString());
        }
    }

    bind_Experience(mjexperience_rowdata) {
        try {
            this.state.dpExperience = mjexperience_rowdata;

            if (this.state.dpExperience && this.state.dpExperience.length > 0) {
                return this.state.dpExperience.map((idexp, index) => {
                    const { experiencecode, experiencename, value } = idexp
                    return (
                        <div className="cookies-checkbox mt-15">
                            <div className="d-flex flex-row justify-content-start">
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    id="jobfunction"
                                    icon=""
                                    label={experiencename}
                                    checked={this.state.job_experience_array.includes(value)}
                                    onChange={(e) => {
                                        this.jobexperienceclick(e, value)
                                    }}
                                />
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Job_list", "bind_genderstatus", 0, error.toString());
        }
    }


    //onchanges Experience
    jobexperienceclick = (event, experiencecode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            this.state.dpExperience.forEach(mjgender => {
                if (mjgender.experiencecode === Number(experiencecode)) {
                    mjgender.status = !mjgender.status;
                }
            });
            if (check === true) {
                this.state.job_experience_array.push(experiencecode);
                this.setState({ job_experience_array: this.state.job_experience_array });
            }
            else {
                let i = this.state.job_experience_array.indexOf(experiencecode);
                if (i > -1) {
                    this.state.job_experience_array.splice(i, 1)
                }
                this.setState({ job_experience_array: this.state.job_experience_array });
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "job_list", "jobgenderone", 0, error.toString());
        }
    }

    //onchanges gender
    jobgenderone = (event, gendercode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            this.state.jobgenderarr.forEach(mjgender => {
                if (mjgender.gendercode === Number(gendercode)) {
                    mjgender.status = !mjgender.status;
                }
            });
            if (check === true) {
                this.state.profile_gender.push(gendercode);
                this.setState({ profile_gender: this.state.profile_gender });
            }
            else {
                let i = this.state.profile_gender.indexOf(gendercode);
                if (i > -1) {
                    this.state.profile_gender.splice(i, 1)
                }
                this.setState({ profile_gender: this.state.profile_gender });
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "job_list", "jobgenderone", 0, error.toString());
        }
    }

    //Bind job location 
    // call_job_location() {
    //     window.fbq('track', 'Website', { fb_ad_function: "call_job_location", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
    //     if (this.state.dpdistrict.length == 0 || this.state.dpbindstate.length == 0) {
    //         axios({
    //             method: 'Post',
    //             url: this.state.ipurl + 'employee/filterbind/',
    //             crossdomain: true,
    //             params: {
    //                 deviceip: this.state.ipaddress,
    //                 type: 2,
    //                 languagecode: this.state.languagecode,
    //                 employeecode: this.state.employeecode
    //             }
    //         })
    //             .then(response => {
    //                 this.setState({ isSmallLoaderActive: this.state.isSmallLoaderActive = false })
    //                 if (response.data.location_filter_json_result.varstatuscode === 4) {
    //                     //Bind state name in select dropdown
    //                     var sortfilter_ary = response.data.location_filter_json_result.state_list.filter(e => e.languagecode == this.state.languagecode)
    //                     sortfilter_ary.sort(this.GetSortOrder("statename"))
    //                     this.state.dpbindstate = sortfilter_ary.map(sortfilter_ary => {
    //                         return { value: sortfilter_ary.statecode, label: sortfilter_ary.statename }
    //                     });
    //                     JSON.stringify(this.state.dpbindstate);
    //                     this.setState({ dpbindstate: this.state.dpbindstate });

    //                     //  Bind job location
    //                     var mlocat_rowdata = response.data.location_filter_json_result.district_list.filter(e => e.languagecode == this.state.languagecode);
    //                     mlocat_rowdata.sort(this.GetSortOrder('districtname'))
    //                     this.state.dpdistrict = response.data.location_filter_json_result.district_list.filter(e => e.languagecode == this.state.languagecode);
    //                     JSON.stringify(this.state.dpdistrict);
    //                     this.setState({ dpdistrict: this.state.dpdistrict });
    //                     // if (mlocat_rowdata) {
    //                     //     mlocat_rowdata.forEach(row => {
    //                     //         if (this.state.profile_location.includes(row.districtcode)) {
    //                     //             row["status"] = true;
    //                     //         }
    //                     //         else {
    //                     //             row["status"] = false;
    //                     //         }
    //                     //     });
    //                     // }
    //                     // alert(JSON.stringify(mlocat_rowdata))
    //                     this.setState({ districtarray: this.state.districtarray = mlocat_rowdata });
    //                     this.bind_location(mlocat_rowdata, 0)


    //                     // this.state.dptaluk = response.data.location_filter_json_result.taluk_list
    //                     // JSON.stringify(this.state.dptaluk);
    //                     // this.setState({ dptaluk: this.state.dptaluk });

    //                 }
    //                 else if (response.data.location_filter_json_result.varstatuscode === 7) {
    //                     // toast.warn(this.state.blocked_msg, {
    //                     //     autoClose: this.state.timeset
    //                     // })
    //                     localStorage.clear()
    //                     // setTimeout(() => {
    //                     //     history.push('/');
    //                     // }, 2000);
    //                 }
    //                 else {
    //                     this.setState({ isSmallLoaderActive: this.state.isSmallLoaderActive = false })
    //                 }
    //             })
    //             .catch(error => {
    //                 this.setState({ isSmallLoaderActive: this.state.isSmallLoaderActive = false })
    //                 error_log.ERRORLOG(this.state.employercode, this.state.version, "job_list", "bind_filter_array", 0, error.toString());
    //             });
    //     }
    // }

    //job location
    bind_location(mlocat_rowdata, jloc) {
        try {
            // alert('bind_location')
            // alert(JSON.stringify(mlocat_rowdata))
            // this.state.districtarray =[...mlocat_rowdata];
            // if (this.state.mlocat_rowdata === null || this.state.mlocat_rowdata === undefined) {1
            if (mlocat_rowdata && mlocat_rowdata.length > 0) {
                this.state.districtarray = mlocat_rowdata;
                if (this.state.districtarray && this.state.districtarray.length > 0) {
                    return this.state.districtarray.map((jloc, index) => {
                        const { districtcode, statecode, status, districtname } = jloc;
                        return (
                            <div className="cookies-checkbox mt-15">
                                <div className="d-flex flex-row justify-content-start">
                                    {/* <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value={districtcode}
                                        onClick={(e) => this.locationone(e, idval)}
                                    /> */}
                                    {/* <span>{districtname}</span> */}
                                    <Checkbox className="d-flex flex-row justify-content-start"
                                        id="jobfunction"
                                        icon=""
                                        label={districtname}
                                        checked={this.state.profile_location.includes(districtcode)}
                                        onChange={(e) => {
                                            this.locationone(e, districtcode)
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_location", 0, error.toString());
        }
    }

    //onchange location
    locationone = (event, districtcode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            this.state.districtarray.forEach(mlocation => {
                if (mlocation.districtcode === Number(districtcode)) {
                    mlocation.status = !mlocation.status;
                }
            });
            if (check) {
                this.state.profile_location.push(districtcode);
                this.setState({ profile_location: this.state.profile_location });
            }
            else {
                let i = this.state.profile_location.indexOf(districtcode);
                if (i > -1) {
                    this.state.profile_location.splice(i, 1)
                }
                this.setState({ profile_location: this.state.profile_location });
            }
            // this.state.collapseItems.forEach(colaps => {
            //     if (colaps.id === Number(idval)) {
            //         colaps.content = this.bind_location(this.state.districtarray, 0);
            //     }
            // });
            // this.setState({ collapseItems: this.state.collapseItems });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "locationone", 0, error.toString());
        }

    }

    // jobtype

    bind_jobtype() {
        try {
            if (this.state.jobtypedata && this.state.jobtypedata.length > 0) {
                return this.state.jobtypedata.map((jtype, index) => {
                    const { jobtypename, jobtypecode } = jtype;
                    return (
                        <div className="cookies-checkbox mt-15">
                            <div className="d-flex flex-row justify-content-start">
                                {/* <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value={jtype.checked}
                                    onClick={(e) => this.jobtypeone(e, jobtypecode)}
                                /> */}
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    id="jobtype"
                                    icon=""
                                    label={jobtypename}
                                    checked={this.state.jobtypecodeval.includes(jobtypecode)}
                                    onChange={(e) => {
                                        this.jobtypeone(e, jobtypecode)
                                    }}
                                />
                                {/* <span>{jobtypename}</span> */}
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_location", 0, error.toString());
        }
    }

    //onchange jobtype
    jobtypeone = (event, jobtypecode) => {
        try {
            // var gendiv = document.getElementById("genid");
            let check = event.target.checked;
            let value = event.target.value;
            this.state.jobtypedata.forEach(jobtype => {
                if (jobtype.jobtypecode === Number(jobtypecode)) {
                    jobtype.checked = check
                } else if (check === false) {
                    jobtype.checked = false
                }
            });
            if (check === true) {
                this.state.jobtypecodeval.push(Number(jobtypecode));
                this.setState({ jobtypecodeval: this.state.jobtypecodeval });
            }
            else if (check === false) {
                let i = this.state.jobtypecodeval.indexOf(Number(jobtypecode));
                if (i > -1) {
                    this.state.jobtypecodeval.splice(i, 1)
                }
                this.setState({ jobtypecodeval: this.state.jobtypecodeval });
            }
            this.setState({
                jobtypedata: this.state.jobtypedata = this.state.jobtypedata,
            })
            // if (jobtypecode == 1) {
            //     gendiv.style.display = "block";
            //     this.setState({
            //         jobtype: this.state.jobtype = jobtypecode
            //     })
            // }
            // else if (jobtypecode == 3) {
            //     gendiv.style.display = "none";
            //     this.setState({
            //         profile_gender: this.state.profile_gender = [],
            //         jobtype: this.state.jobtype = jobtypecode
            //     })

            // }
            // else {
            //     gendiv.style.display = "block";
            //     this.setState({
            //         jobtype: this.state.jobtype = jobtypecode,
            //         experienceval: this.state.experienceval = [0]
            //     })
            // }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, this.state.version, "job-list", "jobtypeone", 0, error.toString());
        }

    }
    clickHere() {
        this.clearFilter();
        localStorage.setItem('getcurrentRole', 1)
        // this.setState({getroleValue:1})
        localStorage.setItem('RecommendMoreJob', 'true')
        window.location.reload()
        this.job_list()
    }
    applyfilter(isWebView = false) {

        var flag = 0;
        var removeFlag = 0;
        if (this.state.selectedFilterCode == 1 || isWebView) {
            if ((this.state.profile_jobrole && this.state.profile_jobrole.length > 0) || this.state.applyFilters.includes(this.state.selectedFilterCode)) {
                flag++;
                if (!isWebView) this.job_list();
                if (this.state.profile_jobrole.length == 0) removeFlag++;
            }
        }
        if (this.state.selectedFilterCode == 2 || isWebView) {
            if ((this.state.profile_location && this.state.profile_location.length > 0) || this.state.applyFilters.includes(this.state.selectedFilterCode)) {
                flag++;
                this.setState({
                    joblocationcode_api: this.state.joblocationcode_api = [],
                    joblocationcode_api: this.state.joblocationcode_api = Array.from(new Set(this.state.locationcodeval.concat(this.state.profile_location)))
                });
                if (!isWebView) this.job_list();
                if (this.state.profile_location.length == 0) removeFlag++;
            } else if (isWebView) {
                this.setState({
                    joblocationcode_api: this.state.joblocationcode_api = [],
                    locationcodeval: this.state.locationcodeval = []
                });
            }
        }
        if (this.state.selectedFilterCode == 3 || isWebView) {
            if ((this.state.profile_gender && this.state.profile_gender.length > 0) || this.state.applyFilters.includes(this.state.selectedFilterCode)) {
                flag++;
                if (!isWebView) this.job_list();
                if (this.state.profile_gender.length == 0) removeFlag++;
            }
        }
        if (this.state.selectedFilterCode == 4 || isWebView) {
            if ((this.state.salaryRangeVal[0] || this.state.salaryRangeVal[1]) || this.state.applyFilters.includes(this.state.selectedFilterCode)) {
                flag++;
                this.setState({
                    fromSalary: this.state.fromSalary = this.state.salaryRangeVal[0],
                    toSalary: this.state.toSalary = this.state.salaryRangeVal[1],
                })
                if (!isWebView) this.job_list()
                if (this.state.salaryRangeVal[0] == 0 && this.state.salaryRangeVal[1] == 10000 && this.state.applyFilters.includes(this.state.selectedFilterCode)) removeFlag++;
            } else if (isWebView) {
                this.setState({
                    fromSalary: this.state.fromSalary = 0,
                    toSalary: this.state.toSalary = 0,
                });
            }
        }
        if (this.state.selectedFilterCode == 5 || isWebView) {
            if ((this.state.job_experience_array && this.state.job_experience_array.length > 0) || this.state.applyFilters.includes(this.state.selectedFilterCode)) {
                flag++;
                if (!isWebView) this.job_list()
                if (this.state.job_experience_array.length == 0) removeFlag++;
            }
        }
        if (isWebView && flag > 0) this.job_list()
        if (!isWebView) {
            this.setState({
                openFilters: (this.state.openFilters = false),
                // jobfunctioncode_api: this.state.jobfunctioncode_api = [],
                // jobfunctioncode_api: this.state.jobfunctioncode_api = Array.from(new Set(this.state.jobfunctioncodeval.concat(this.state.profile_jobfunction)))
            });
            if (!this.state.applyFilters.includes(this.state.selectedFilterCode) && flag > 0)
                this.setState({
                    applyFilters: this.state.applyFilters = [...this.state.applyFilters, ...[this.state.selectedFilterCode]]
                })

            if (removeFlag > 0) {
                var index = this.state.applyFilters.findIndex(el => el == this.state.selectedFilterCode)
                this.state.applyFilters.splice(index, 1);
                this.setState({
                    applyFilters: this.state.applyFilters
                })
            }
        }

        if (flag > 0)
            this.setState({
                isFilterApplied: this.state.isFilterApplied = true
            })

        if (flag > 0 || removeFlag > 0) {
            let filterDetails = {
                jobTitle: this.state.profile_jobrole || [],
                jobLocation: this.state.profile_location || [],
                gender: this.state.profile_gender || [],
                salary_from: this.state.fromSalary || 0,
                salary_to: this.state.toSalary || 100000,
                experience: this.state.job_experience_array || [],
                applyFilters: this.state.applyFilters || [],
                ///d
            }
            localStorage.setItem("applied_filters", JSON.stringify(filterDetails))
        }
    }

    clearFilter() {
        if (this.state.profile_jobrole && this.state.profile_jobrole.length > 0) {
            for (var i = 0; i < this.state.profile_jobrole.length; i++) {
                let index = this.state.jobRoleArray.findIndex(el => el.jobrolecode == this.state.profile_jobrole[i]);

                if (index != -1) {
                    this.state.jobRoleArray[index]['status'] = false
                }
            }
            this.setState({
                jobRoleArray: this.state.jobRoleArray
            });
        }
        this.searchclear(2)
        if ((this.state.profile_jobrole && this.state.profile_jobrole.length > 0) || (this.state.profile_location && this.state.profile_location.length > 0) || (this.state.profile_gender && this.state.profile_gender.length > 0) || (this.state.job_experience_array && this.state.job_experience_array.length > 0) || (this.state.salaryRangeVal[0] != 0 && this.state.salaryRangeVal[1] != 100000))
            this.setState({
                fromSalary: this.state.fromSalary = "",
                toSalary: this.state.toSalary = "",
                applyFilters: this.state.applyFilters = [],
                openFilters: this.state.openFilters = false,
                profile_gender: this.state.profile_gender = [],
                profile_jobrole: this.state.profile_jobrole = [],
                joblocationcode_api: this.state.joblocationcode_api = [],
                profile_location: this.state.profile_location = [],
                job_experience_array: this.state.job_experience_array = [],
                locationcodeval: this.state.locationcodeval = [],
                selectedFilterCode: this.state.selectedFilterCode = 0,
                salaryRangeVal: this.state.salaryRangeVal = [0, 100000]
            })

        if (this.state.isFilterApplied) {
            localStorage.removeItem("applied_filters")
            this.setState({
                isFilterApplied: this.state.isFilterApplied = false,
                applyFilters: this.state.applyFilters = [],
            });
            let listArr = localStorage.getItem('all_jobs')
            if (listArr == "true") {
                this.setState({ skip: this.state.skip = 0 })
                this.job_list('all_jobs')
            }
            else {
                this.job_list()
            }
        }
    }

    loadMore() {
        this.setState({
            // itemOffset: this.state.itemOffset = this.state.itemsPerPage,
            limit: this.state.limit = this.state.limit + 10
        })

        if (this.state.activeTab === 1) {
            this.job_list()
        }
        if (this.state.activeTab === 2) {
            this.applied_job_list()
        }
        if (this.state.activeTab === 3) {
            this.job_list()
        }
        // this.page(this.state.itemOffset, this.state.itemsPerPage)
    }
    //Bind job role 
    // call_job_role() {
    //     window.fbq('track', 'Website', { fb_ad_function: "call_job_role", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
    //     if (this.state.jobrole_full_list.length == 0) {
    //         this.setState({ isSmallLoaderJobRoleActive: this.state.isSmallLoaderJobRoleActive = true })
    //         axios({
    //             method: 'Post',
    //             url: this.state.ipurl + 'employee/filterbind/',
    //             crossdomain: true,
    //             params: {
    //                 deviceip: this.state.ipaddress,
    //                 type: 11,
    //                 languagecode: this.state.languagecode,
    //                 employeecode: this.state.employeecode,
    //             }
    //         })
    //             .then(response => {
    //                 this.setState({ isSmallLoaderJobRoleActive: this.state.isSmallLoaderJobRoleActive = false })
    //                 if (response.data.jobrole_filter_json_result.varstatuscode === 4) {

    //                     //jobrole
    //                     var mjrole_rowdata = response.data.jobrole_filter_json_result.jobrolelist.filter(e => e.languagecode == Number(localStorage.getItem('languagecode')));
    //                     mjrole_rowdata.sort(this.GetSortOrder('jobrolename'))
    //                     mjrole_rowdata.sort();
    //                     if (mjrole_rowdata) {
    //                         mjrole_rowdata.forEach(row => {
    //                             if (this.state.profile_jobrole.includes(row.jobrolecode)) {
    //                                 row["status"] = true;
    //                             }
    //                             else {
    //                                 row["status"] = false;
    //                             }
    //                         });
    //                     }

    //                     if (this.state.profile_jobfunction.length > 0) {
    //                         mjrole_rowdata = mjrole_rowdata.filter(e => this.state.profile_jobfunction.includes(e.jobfunctioncode));
    //                     }
    //                     this.setState({ jobrole_full_list: this.state.jobrole_full_list = mjrole_rowdata })

    //                     //Bind job role in checkbox
    //                     var bind_collapseItems = [
    //                         {
    //                             id: 2,
    //                             title: "Job Role",
    //                             icon: <i className='fa fa-chevron-down collapse-icon'></i>,
    //                             content: this.bind_jobrole(mjrole_rowdata, 2)
    //                         },
    //                     ];
    //                     this.setState({ collapseItems: this.state.collapseItems = bind_collapseItems })
    //                     this.setState({ collapseItems: this.state.collapseItems })
    //                 }
    //                 else if (response.data.jobrole_filter_json_result.varstatuscode === 7) {
    //                     // toast.warn(this.state.blocked_msg, {
    //                     //     autoClose: this.state.timeset
    //                     // })
    //                     localStorage.clear()
    //                     // setTimeout(() => {
    //                     //     history.push('/');
    //                     // }, 2000);
    //                 }
    //                 else {
    //                     this.setState({ isSmallLoaderJobRoleActive: this.state.isSmallLoaderJobRoleActive = false })
    //                 }
    //             })
    //             .catch(error => {
    //                 // this.setState({ isSmallLoaderJobRoleActive: this.state.isSmallLoaderJobRoleActive = false })
    //                 // error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
    //             });
    //     } else {
    //         if (this.state.profile_jobfunction.length > 0) {
    //             this.state.jobrole_full_list = this.state.profile_jobfunction.filter(e => this.state.jobrole_full_list.includes(e.jobfunctioncode));
    //         }

    //     }
    // }
    GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    onChangeValue(event) {
        console.log(event.target.value);
    }
    handleChange(event, newValue) {
        this.setState({ salaryRangeVal: (this.state.salaryRangeVal = newValue) })
    }

    getText(value) {
        return `${value}`
    };

    onChangeJobfunction(event) {
        this.setState({ jobfunctioncodeval: this.state.jobfunctioncodeval = [] })
        this.state.jobfunctioncodeval.push(Number(event.target.value))
        this.job_list()
    }

    jobpost_list_view() {
        try {
            return this.state.rowData.map((jobpost, index) => {
                const { employername, flash, companyname, statuscode, registeredname, contactdetai1ls, jobcode, jobfunctionname, jobid, viewedcount, dialedcount, pushedcount, experience, salaryrange, isbestsalary, joblocationname, jobrolename, imageurl, createddate, updateddate, profileurl, daysleft, employercode, employermobileno } = jobpost
                return (

                    <div className="col-lg-12 col-md-12" key={index}>

                        {/* featured-imagebox */}
                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                            <div className="featured-thumbnail">
                                <img src="images/joblist_logo.jpg" />
                                {/* <img src={profileurl}/> */}

                                {flash == 1 ?
                                    <div className="required-tag">Flash</div>
                                    :
                                    ''
                                }
                            </div>
                            <div className="featured-content">
                                <div className="job-title" >
                                    <div className="job-heading" >
                                        <h3><a>{jobrolename}</a></h3>
                                    </div>
                                    <div className="job-time">
                                        <span style={{ backgroundColor: 'transparent', color: 'red', fontSize: '11px' }}>{daysleft} days left</span>
                                    </div>
                                </div>
                                {/* <div className="featured-desc">
                                    <p>Published {daysleft} Days Ago.</p>
                                </div> */}
                                <div className="featured-details">
                                    <div className="job-meta">
                                        {flash == 1 ?
                                            <div>
                                                <span><i className="fa fa-building"></i>{companyname}</span><br />
                                            </div>
                                            :
                                            <div>
                                                <span><i className="fa fa-building"></i>{employername}</span><br />
                                            </div>
                                        }

                                        <span><i className="fa fa-map-marker-alt"></i>{joblocationname}</span><br />
                                        <span><span><i className="fa fa-briefcase"></i>{experience[0].isfresher == true || experience[0].isfresher == 'true' ?
                                            <span>Fresher</span>
                                            : ''}
                                            {experience[0].from >= 0 && experience[0].to > 0 ?
                                                <span>{experience[0].from} to {experience[0].to} Years</span>
                                                : ''
                                            }
                                            {experience[0].isfresher == "false" && experience[0].from == 0 && experience[0].to == 0 ?
                                                <span>-</span>
                                                : ''
                                            }</span></span><br />
                                    </div>
                                </div>
                            </div>
                        </div>{/* featured-imagebox end */}
                    </div >
                )

            })
        }
        catch (error) {
            // error_log.ERRORLOG(this.state.usercode, this.state.version, "FlashJobsList", "jobpost_list_view", 0, error.toString());
        }
    }

    bind_pageslist() {
        try {
            // this.state.jobfunctionarray =[...mjfun_rowdata];
            let pages = [1, 2, 3, 4, 5, 6, 7]
            if (pages && pages.length > 0) {
                return pages.map((page, index) => {
                    return (
                        index > 2 ?
                            <a className="page-nav-link">...</a>

                            :
                            <a className="page-nav-link ">{page}</a>


                    )
                })
            }
        }
        catch (error) {
            // error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_jobfunction", 0, error.toString());
        }

    }
    page(offset, itemsPerPage) {
        this.setState({ skip: this.state.itemOffset = offset })
        this.setState({ pageCount: this.state.pageCount = Math.ceil(this.state.rowData.length / itemsPerPage) })
    }

    handlePageClick = (event) => {

        var data = ((event.selected + 1) * this.state.limit) - this.state.limit;

        this.setState({ skip: this.state.skip = data })
        let listArr = localStorage.getItem('all_jobs')
        if (listArr == "true") {
            this.job_list('all_jobs')
        }
        else {
            this.job_list()
        }

    }

    joblist = (currentItems) => {
        // console.log(currentItems)
        // console.log(JSON.stringify(currentItems))
        // return (
        //     <>
        //         {currentItems &&
        //             currentItems.map((item) => (
        //                 <div>
        //                     <h6>Item #{item.employername}</h6>
        //                 </div>
        //             ))}
        //     </>
        // );
        try {
            return this.state.currentItems.map((jobpost, index) => {
                const { employername, flash, companyname, statuscode, registeredname, contactdetai1ls, jobcode, jobfunctionname, jobid, viewedcount, dialedcount, pushedcount, experience, salaryrange, isbestsalary, joblocationname, jobrolename, imageurl, createddate, updateddate, profileurl, daysleft, employercode, employermobileno, appliedstatus, applieddate } = jobpost
                return (
                    // <a href={'/Job_details?jobcode=' + jobcode}>
                    <div className="col-lg-12 col-md-12" key={index}>
                        {/* featured-imagebox */}

                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                            <div className="featured-thumbnail" style={{ textAlign: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', verticalAlign: 'top' }}>
                                {profileurl ?
                                    <img src={profileurl} />
                                    :
                                    <img src="images/joblist_logo.jpg" />
                                }
                                {/* <img src="images/joblist_logo.png" /> */}
                                {/* <img src={profileurl}/> */}
                                {flash == 1 ?
                                    <div className="required-tag">Flash Job</div>
                                    :
                                    ''
                                }
                                <div className="button-container">
                                    {/* {daysleft <= this.state.jobalertdays && appliedstatus == 0 ?
                                            //  <span  style={{ backgroundColor:'transparent',color:'red' }}>{daysleft} days left</span>
                                            null
                                            : daysleft == 0 ?
                                                <span className="danger-color">Expiring Today</span>
                                                :
                                                null
                                                } */}
                                    {this.state.employeecode > 0 && appliedstatus == 0 && daysleft >= 0 ? (
                                        <button className="apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded applyBtn ttm-btn-style-fill ttm-btn-color-skincolor jobDetail" onClick={() => this.applynow(jobcode, employercode, employermobileno)}>
                                            Call & Apply</button>
                                    ) : appliedstatus == 11 && window.innerWidth >= 768 ?
                                        <button className="apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded applyBtn ttm-btn-style-fill ttm-btn-color-skincolor jobDetail" style={{ backgroundColor: 'green', borderColor: 'transparent' }} onClick={() => {this.openCallHRModal(employermobileno); this.updatecallcount(jobcode)}}>
                                            Call HR</button>
                                        : appliedstatus == 11 && window.innerWidth < 768 ?
                                            <a href={'tel:' + employermobileno} >
                                                <button onClick={() => this.updatecallcount(jobcode)} className="apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded applyBtn ttm-btn-style-fill ttm-btn-color-skincolor jobDetail" style={{ backgroundColor: 'green', borderColor: 'transparent' }}>
                                                    Call HR</button></a>
                                            : null}
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                        {daysleft > this.state.jobalertdays && (
                                            <span style={{ backgroundColor: 'transparent', color: 'red', fontSize: '12px' }}>{daysleft} days left</span>
                                        )}
                                        {daysleft <= this.state.jobalertdays && daysleft > 0 ?
                                            <span style={{ backgroundColor: 'transparent', color: 'red', fontSize: '12px' }}>{daysleft} days left</span>
                                            : daysleft == 0 ?
                                                <span style={{ backgroundColor: 'transparent', color: 'red', fontSize: '12px' }} >Expiring Today</span>
                                                : daysleft < 0 ?
                                                    <span style={{ backgroundColor: 'transparent', color: 'red', fontSize: '12px' }} >Expired</span>
                                                    :
                                                    null
                                        }
                                    </div>

                                    <Modal isOpen={this.state.showModal} className='JobList_Popup'>
                                        <ModalHeader style={{ borderColor: 'white', justifyContent: 'flex-end', textAlign: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end', alignItems: 'flex-end' }} toggle={this.closeModal} >

                                        </ModalHeader>
                                        <ModalBody>
                                            <div className="content-tab">
                                                <div className="text-center" style={{ marginTop: "-5px", textAlign: 'center', alignContent: 'center', alignSelf: 'center', alignItems: 'center' }}>
                                                    <center>
                                                        <img src="images/footer-logo.png" alt="Your Logo" />
                                                        <p style={{ fontSize: 12, color: 'black' }}>User Friendly & Trusted HR Manager</p></center>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'green',
                                                fontSize: '48px'
                                            }}>
                                                <span><img src="images/svg/accept.png" className="dz-img rounded-circle cls-load" alt="" /></span>
                                            </div>
                                            <p style={{
                                                textAlign: 'center',
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                            }}>{this.state.modalMessage}</p>
                                            <p style={{ textAlign: 'center' }}>Now you can call this employer @ <b><a onClick={() => this.updatecallcount(this.state.jobcode)} href={'tel:' + this.state.employermobileno} >
                                                {this.state.employermobileno}</a></b></p>
                                            {window.innerWidth >= 768 ?
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>

                                                    <Button color="green" onClick={() => {this.closeModal; this.updatecallcount(this.state.jobcode)}}>Call HR</Button>


                                                </div>
                                                :
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <a onClick={() => this.updatecallcount(this.state.jobcode)} href={'tel:' + this.state.employermobileno} >
                                                        <Button color="green">Call HR</Button>
                                                    </a>

                                                </div>
                                            }

                                        </ModalBody>
                                    </Modal>
                                </div>
                            </div>
                            <Modal isOpen={this.state.showCallHRModal} className='JobList_Popup'>
                                <ModalHeader style={{ borderColor: 'white', justifyContent: 'flex-end', textAlign: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end', alignItems: 'flex-end' }} toggle={this.closeHRModal} >

                                </ModalHeader>
                                <ModalBody>
                                    <div className="content-tab">
                                        <div className="text-center" style={{ marginTop: "-5px", textAlign: 'center', alignContent: 'center', alignSelf: 'center', alignItems: 'center' }}>
                                            <center>
                                                <img src="images/footer-logo.png" alt="Your Logo" />
                                                <p style={{ fontSize: 12, color: 'black' }}>User Friendly & Trusted HR Manager</p></center>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'green',
                                        fontSize: '48px'
                                    }}>
                                        <span><img src="images/svg/phone-call.png" className="dz-img rounded-circle cls-load" alt="" /></span>
                                    </div>
                                    <p style={{
                                        textAlign: 'center',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                    }}>{this.state.modalMessage}</p>
                                    <p style={{ textAlign: 'center' }}>Now you can call this employer @ <b><a ref={'empmobileno'} href={'tel:' + this.state.employermobileno} >
                                        {this.state.employermobileno}</a></b></p>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button color="green" onClick={this.closeHRModal}>OK</Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                            <div className="featured-content" onClick={() => this.viewjob(jobcode)}>
                                <div className="job-title" style={{ marginBottom: "-4px" }} >
                                    {/* margin-top: 8px; */}
                                    <div className="job-heading" style={{ marginBottom: "-8px" }}>
                                        {flash == 1 ?
                                            <h5><a href={'/Flashjobs_details?jobcode=' + jobcode}>{jobrolename}</a></h5>
                                            :
                                            <h5><a href={'/Job_details?jobcode=' + jobcode}>{jobrolename}</a></h5>
                                        }
                                    </div>

                                </div>
                                {/* <div className="featured-desc">
                                    <p>Published {daysleft} Days Ago.</p>
                                </div> */}
                                <div className="featured-details">
                                    <div className="job-meta">
                                        {flash == 1 ?
                                            <div>
                                                <span><i className="fa fa-building"></i>{companyname}</span><br />
                                            </div>
                                            :
                                            <div>
                                                <span><i className="fa fa-building"></i>{employername}</span><br />
                                            </div>
                                        }

                                        <span><i className="fa fa-map-marker-alt"></i>{joblocationname}</span><br />
                                        <span><span><i className="fa fa-briefcase"></i>{experience[0].isfresher == true || experience[0].isfresher == 'true' ?
                                            <span>Fresher</span>
                                            : ''}
                                            {experience[0].from >= 0 && experience[0].to > 0 ?
                                                <span>{experience[0].from} to {experience[0].to} Years</span>
                                                : ''
                                            }
                                            {experience[0].isfresher == "false" && experience[0].from == 0 && experience[0].to == 0 ?
                                                <span>-</span>
                                                : ''
                                            }</span></span><br />
                                        {isbestsalary == 1 ?
                                            <div>
                                                <span><i className="fas fa-rupee-sign"></i>Best Salary in the Industry</span>
                                            </div>
                                            :
                                            salaryrange.min != '' & salaryrange.max != '' ?
                                                <div>
                                                    <span><i className="fas fa-rupee-sign"></i>{this.indian_standard_amount(salaryrange.min)} to {this.indian_standard_amount(salaryrange.max)}</span>
                                                </div>
                                                :
                                                <div>
                                                    <span><i className="fas fa-rupee-sign"></i>-</span>
                                                </div>
                                        }

                                    </div>
                                    <div className="job-time">

                                    </div>
                                </div>
                            </div>
                        </div>{/* featured-imagebox end */}
                    </div >
                    // </a>
                )

            })
        }
        catch (error) {
            // error_log.ERRORLOG(this.state.usercode, this.state.version, "FlashJobsList", "jobpost_list_view", 0, error.toString());
        }
    }
    navigation(jobcode, flash) {
        localStorage.setItem('jobcode', jobcode);
        localStorage.setItem('flash', flash);
    }

    filterlistchanges(flag) {
        try {
            //ajith
            if (flag == 'jobfunction') {
                this.setState({ showjobfunction: this.state.showjobfunction = !this.state.showjobfunction });
                this.setState({ showskills: this.state.showskills = false });
                // this.call_job_function(3);
            }
            if (flag == 'skills') {
                this.setState({ showskills: this.state.showskills = !this.state.showskills });
                this.setState({ showjobfunction: this.state.showjobfunction = false });
                // this.call_job_function(1);
            }
        } catch (error) {

        }
    }

    searchprofile_load() {
        var accessToken = localStorage.getItem('accessToken');

        window.fbq('track', 'Website', { fb_ad_function: "searchprofile_load", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
        this.setState({ isActive: this.state.isActive = true })
        //alert(this.state.employeecode)
        axios({
            method: 'Post',
            url: this.state.ipurl + 'search/load/',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            },
            params: {
                languagecode: this.state.languagecode,
                employeecode: this.state.employeecode,
                ipaddress: this.state.ipaddress,
            }
        })
            .then(async response => {
                if (response.data.status == 401) {
                    await generateRefreshToken().then((result) => {
                        if (result == 1) {
                            this.searchprofile_load();
                        }
                        else {
                            // alert('Unauthorized')
                        }
                    })
                }
                else {
                    this.setState({ isActive: this.state.isActive = false })
                    if (response.data.search_json_result.varstatuscode === 4) {

                        //load location
                        var load_location_response = response.data.search_json_result.locationlist;
                        var dpbind_loadlocation = load_location_response.map(load_location_response => {
                            return { value: load_location_response.districtcode, label: load_location_response.districtname }
                        })
                        JSON.stringify(dpbind_loadlocation)
                        this.setState({ dpbind_loadlocation });
                        //load jobfunction
                        var load_jobfunction_response = response.data.search_json_result.jobfunctionlist;
                        var dpbind_loadjobfunction = load_jobfunction_response.map(load_jobfunction_response => {
                            return { value: load_jobfunction_response.jobfunctioncode, label: load_jobfunction_response.jobfunctionname }
                        })
                        JSON.stringify(dpbind_loadjobfunction)
                        this.setState({ dpbind_loadjobfunction });
                        // alert(JSON.stringify(dpbind_loadjobfunction))
                        // var sortfilter_ary = dpbind_loadjobfunction.filter(e => e.value == 1).map(sortfilter_ary => {
                        //     return  sortfilter_ary.label 
                        //   })

                        // alert(JSON.stringify(sortfilter_ary))
                        // alert(JSON.stringify(jfunname))

                        //load jobfunction
                        var load_jobrole_response = response.data.search_json_result.jobrolelist;
                        var dpbind_loadjobrole = load_jobrole_response.map(load_jobrole_response => {
                            return { value: load_jobrole_response.jobrolecode, label: load_jobrole_response.jobrolename }
                        })
                        JSON.stringify(dpbind_loadjobrole)
                        this.setState({ dpbind_loadjobrole });
                        this.setState({ locationlist: this.state.locationlist = response.data.search_json_result.hasOwnProperty('locationlist') ? response.data.search_json_result.locationlist : [] })
                        this.setState({ jobrolelist: this.state.jobrolelist = response.data.search_json_result.hasOwnProperty('jobrolelist') ? response.data.search_json_result.jobrolelist : [] })
                        this.setState({ jobfunctionlist: this.state.jobfunctionlist = response.data.search_json_result.hasOwnProperty('jobfunctionlist') ? response.data.search_json_result.jobfunctionlist : [] })
                        this.setState({ recentsearch: this.state.recentsearch = response.data.search_json_result.hasOwnProperty('recentsearch') ? response.data.search_json_result.recentsearch : [] })
                        let common_list = [], final_list = [];
                        // for (let i = 0; i < this.state.recentsearch.recentlocationlist.length; i++) {
                        //     let filter = common_list.some(e => e.label === this.state.recentsearch.recentlocationlist[i].locationname && e.value === this.state.recentsearch.recentlocationlist[i].locationcode);
                        //     if (!filter) {
                        //         final_list.push({ 'name': this.state.recentsearch.recentlocationlist[i].locationname, "languagecode": this.state.recentsearch.recentlocationlist[i].languagecode, 'id': this.state.recentsearch.recentlocationlist[i].locationcode, 'status': 1, 'status_name': 'Location', 'searchtype': 1, hover: false });
                        //     }
                        // }
                        // for (let i = 0; i < this.state.recentsearch.recentjobfunctionlist.length; i++) {
                        //     let filter = common_list.some(e => e.label === this.state.recentsearch.recentjobfunctionlist[i].jobfunctionname && e.value === this.state.recentsearch.recentjobfunctionlist[i].jobfunctioncode);
                        //     if (!filter) {
                        //         final_list.push({ 'name': this.state.recentsearch.recentjobfunctionlist[i].jobfunctionname, 'languagecode': this.state.recentsearch.recentjobfunctionlist[i].languagecode, 'id': this.state.recentsearch.recentjobfunctionlist[i].jobfunctioncode, 'status': 2, 'status_name': 'Job Function', 'searchtype': 3, hover: false });
                        //     }
                        // }
                        // for (let i = 0; i < this.state.recentsearch.recentjobrolelist.length; i++) {
                        //     let filter = common_list.some(e => e.label === this.state.recentsearch.recentjobrolelist[i].jobrolename && e.value === this.state.recentsearch.recentjobrolelist[i].jobrolecode);
                        //     if (!filter) {
                        //         final_list.push({ 'name': this.state.recentsearch.recentjobrolelist[i].jobrolename,'jobrolename': this.state.recentsearch.recentjobrolelist[i].jobrolename,  'languagecode': this.state.recentsearch.recentjobrolelist[i].languagecode, 'id': this.state.recentsearch.recentjobrolelist[i].jobrolecode, 'jobrolecode': this.state.recentsearch.recentjobrolelist[i].jobrolecode, 'status': 3, 'status_name': 'Job Role', 'searchtype': 2, hover: false });
                        //     }
                        // }
                        // for (let i = 0; i < this.state.locationlist.length; i++) {
                        //     let filter = common_list.some(e => e.label === this.state.locationlist[i].districtname && e.value === this.state.locationlist[i].districtcode);
                        //     if (!filter) {
                        //         common_list.push({ 'name': this.state.locationlist[i].districtname, 'languagecode': this.state.locationlist[i].languagecode, 'id': this.state.locationlist[i].districtcode, 'status': 2, 'status_name': 'Location', 'searchtype': 1, hover: false });
                        //     }
                        // }
                        for (let i = 0; i < this.state.jobrolelist.length; i++) {
                            let filter = common_list.some(e => e.label === this.state.jobrolelist[i].jobrolename && e.value === this.state.jobrolelist[i].jobrolecode);
                            if (!filter) {
                                common_list.push({ 'name': this.state.jobrolelist[i].jobrolename, 'jobrolename': this.state.jobrolelist[i].jobrolename, 'languagecode': this.state.jobrolelist[i].languagecode, 'id': this.state.jobrolelist[i].jobrolecode, 'jobrolecode': this.state.jobrolelist[i].jobrolecode, 'status': false, 'status_name': 'Job Role', 'searchtype': 2, hover: false });
                            }
                        }

                        // for (let i = 0; i < this.state.jobfunctionlist.length; i++) {
                        //     let filter = common_list.some(e => e.label === this.state.jobfunctionlist[i].jobfunctionname && e.value === this.state.jobfunctionlist[i].jobfunctioncode);
                        //     if (!filter) {
                        //         common_list.push({ 'name': this.state.jobfunctionlist[i].jobfunctionname, 'languagecode': this.state.jobfunctionlist[i].languagecode, 'id': this.state.jobfunctionlist[i].jobfunctioncode, 'status': 2, 'status_name': 'Job Function', 'searchtype': 3, hover: false });
                        //     }
                        // }
                        this.setState({ common_array: this.state.common_array = common_list });
                        this.setState({ common_array_duplicate: this.state.common_array_duplicate = common_list });
                        this.setState({ recent_array: this.state.recent_array = final_list });
                        this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = final_list });
                    }
                    else if (response.data.search_json_result.varstatuscode === 7) {
                        //   toast.warn(this.state.blocked_msg, {
                        //     autoClose: this.state.timeset
                        //   })
                        localStorage.clear()
                        // setTimeout(() => {
                        //     history.push('/');
                        // }, 2000);
                    }
                    else {
                        this.setState({ isActive: this.state.isActive = false })
                    }
                }
            })
            .catch(error => {
                this.setState({ isActive: this.state.isActive = false })
                error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "searchprofile_load", 0, error.toString());
            });
    }

    recentSearchClick(item) {
        try {
            this.setState({ show_hidden: this.state.show_hidden = true })
            this.setState({ search_array_hidden: this.state.search_array_hidden = true })
            // this.setState({ search_val: this.state.search_val = item.name });
            // this.onclicksearch_btn();
            this.setState({ jobnav: this.state.jobnav = 1 })
            //alert(item.searchtype)
            if (Object.keys(item).length > 0) {
                if (item.searchtype === 1) {
                    // alert(1)
                    this.setState({ locationcodeval: this.state.locationcodeval = [] })
                    this.state.locationcodeval.push(item.id)
                    this.setState({

                        joblocationcode_api: this.state.joblocationcode_api = [],
                        joblocationcode_api: this.state.joblocationcode_api = Array.from(new Set(this.state.locationcodeval.concat(this.state.profile_location)))
                    })
                    this.setState({
                        searchtype: this.state.searchtype = 1,
                        location_val: this.state.location_val = item.name

                    })
                }
                else if (item.searchtype === 2) {
                    // alert(2)
                    this.setState({ jobrolecodeval: this.state.jobrolecodeval = [] })
                    this.state.jobrolecodeval.push(item.id)
                    this.setState({
                        searchtype: this.state.searchtype = 2,
                        jobrole_val: this.state.jobrole_val = item.name
                    })
                }
                else {
                    //alert(3)
                    this.setState({ jobfunctioncodeval: this.state.jobfunctioncodeval = [] })
                    this.state.jobfunctioncodeval.push(item.id)
                    this.setState({
                        jobfunctioncode_api: this.state.jobfunctioncode_api = [],
                        jobfunctioncode_api: this.state.jobfunctioncode_api = Array.from(new Set(this.state.jobfunctioncodeval.concat(this.state.profile_jobfunction)))
                    })
                    this.setState({
                        searchtype: this.state.searchtype = 3,
                        jobfunction_val: this.state.jobfunction_val = item.name
                    })
                }
            }
            this.setState({ common_array_duplicate: this.state.common_array_duplicate = this.state.common_array })
            // this.job_list()
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "recentSearchClick", 0, error.toString());
        }
    }
    getSearchValues(val, flag) {
        try {
            console.log("this.state.jobRoleArrayKavitha", this.state.jobRoleArray)
            // if (flag == 1) {
            //     this.setState({
            //         location_val: this.state.location_val = val,
            //         locationresult: this.state.locationresult = true,
            //         jobroleresult: this.state.jobroleresult = false,
            //         jobfunctionresult: this.state.jobfunctionresult = false

            //     })
            // }
            // else 
            // if (flag == 2) {
            //     // alert("A")
            //     if(val == ""){
            //         this.setState({
            //             enableSearch :this.state.enableSearch = false
            //     })
            //     localStorage.setItem('getCurrentJobSearch','')
            //     }
            //     this.setState({
            //         jobrole_val: this.state.jobrole_val = val,
            //         jobroleresult: this.state.jobroleresult = true,
            //         locationresult: this.state.locationresult = false,
            //         jobfunctionresult: this.state.jobfunctionresult = false,
            //         enableSearch :this.state.enableSearch = true,
            //         jobrolecodeval :this.state.jobrolecodeval && this.state.jobrolecodeval.length > 0 ? this.state.jobrolecodeval[0].jobrolecode : []

            //     })
            //     // console.log(this.state.jobrolecodeva)


            // }
            // else {
            //     this.setState({
            //         jobfunction_val: this.state.jobfunction_val = val,
            //         jobfunctionresult: this.state.jobfunctionresult = true,
            //         jobroleresult: this.state.jobroleresult = false,
            //         locationresult: this.state.locationresult = false
            //     })
            // }
            if (this.state.languagecode == 2) {

                // this.setState({ search_val: this.state.search_val = val })
                // if (flag == 1) {
                //     this.setState({ location_val: this.state.location_val = val })
                // }
                // else 
                // if (flag == 2) {
                //     this.setState({ jobrole_val: this.state.jobrole_val = val })
                // }
                // else {
                //     this.setState({ jobfunction_val: this.state.jobfunction_val = val })
                // }
                if (val.length === 0) {
                    // this.setState({ common_array_duplicate: this.state.common_array_duplicate = this.state.common_array })
                    // this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = this.state.recent_array })
                    // this.setState({ closeicon_hidden: this.state.closeicon_hidden = true })
                    // this.setState({ show_hidden: this.state.show_hidden = true })
                    // this.setState({ search_array_hidden: this.state.search_array_hidden = true })
                }
                else if (val.length <= 2) {
                    // this.setState({
                    //     jobroleresult :this.state.jobroleresult == false

                    // })
                    this.setState({
                        FilterTitleitemOffset: (this.state.FilterTitleitemOffset = 0),
                        jobRoleArray: (this.state.jobRoleArray = [])
                    })
                    this.pageFilterBind(this.state.FilterTitleitemOffset, this.state.FilterTitleitemsPerPage)
                } else if (val.length >= 3) {
                    // this.setState({ closeicon_hidden: this.state.closeicon_hidden = false })
                    // this.setState({ show_hidden: this.state.show_hidden = false })
                    // this.setState({ search_array_hidden: this.state.search_array_hidden = false })
                    var filteredData = this.state.common_array.filter(x => x.name.toLowerCase().includes(val.toLowerCase()) && x.searchtype == 2);
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = filteredData })
                    // let recentfilteredData = this.state.recent_array.filter(x => String(x.name.toLowerCase()).includes(val.toLowerCase()));
                    // this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = recentfilteredData })
                    //         var filteredJobRole = this.state.allJobRoleArray
                    // .filter(e=>e.jobrolename.toLowerCase().includes(searchValue.toLowerCase()));
                    // this.setState({jobRoleArray :this.state.jobRoleArray =filteredJobRole })

                    this.setState({ jobRoleArray: this.state.jobRoleArray = filteredData })
                }

            } else {

                let languagecode = this.state.languagecode;
                // alert(languagecode)
                // this.setState({ search_val: this.state.search_val = val })

                if (val.length === 0) {
                    // this.setState({ common_array_duplicate: this.state.common_array_duplicate = this.state.common_array })
                    // this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = this.state.recent_array })
                    // this.setState({ closeicon_hidden: this.state.closeicon_hidden = true })
                    // this.setState({ show_hidden: this.state.show_hidden = true })
                    // this.setState({ search_array_hidden: this.state.search_array_hidden = true })
                }
                else if (val.length <= 2) {
                    // this.setState({
                    //     jobroleresult :this.state.jobroleresult == false
                    // })
                    this.setState({
                        FilterTitleitemOffset: (this.state.FilterTitleitemOffset = 0),
                        jobRoleArray: (this.state.jobRoleArray = [])
                    })
                    this.pageFilterBind(this.state.FilterTitleitemOffset, this.state.FilterTitleitemsPerPage)
                } else if (val.length >= 3) {
                    // this.setState({ closeicon_hidden: this.state.closeicon_hidden = false })
                    // this.setState({ show_hidden: this.state.show_hidden = false })
                    // this.setState({ search_array_hidden: this.state.search_array_hidden = false })
                    //  console.log("this.state.common_arrayKavitha", this.state.common_array)
                    let filteredData = this.state.common_array.filter(x => String(x.name.toLowerCase()).startsWith(val.toLowerCase()) && x.searchtype == flag);
                    let arr_duplicate = [];
                    //  console.log("filtereddataKavitha", filteredData)
                    for (let i = 0; i < filteredData.length; i++) {
                        let res = this.state.common_array.filter(item => (item.id == filteredData[i].id && filteredData[i].code == item.code && item.languagecode == languagecode))
                        if (res == '' || res == null || res == undefined) {
                            arr_duplicate.push(filteredData[i])
                        }
                        else {
                            arr_duplicate.push(res[0])
                        }
                    }
                    // console.log("arr_duplicateKavitha", arr_duplicate)
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = arr_duplicate })
                    // let recentfilteredData = this.state.recent_array.filter(x => String(x.name.toLowerCase()).includes(val.toLowerCase()) && x.searchtype == flag);
                    // let arr1_duplicate = [];
                    // for (let i = 0; i < recentfilteredData.length; i++) {
                    //     let res = this.state.recent_array.filter(item => (item.id == recentfilteredData[i].id && recentfilteredData[i].code == item.code && item.languagecode == languagecode))
                    //     if (res == '' || res == null || res == undefined) {
                    //         arr1_duplicate.push(recentfilteredData[i])
                    //     }
                    //     else {
                    //         arr1_duplicate.push(res[0])
                    //     }
                    // }
                    // this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = arr_duplicate })
                    //         var filteredJobRole = this.state.allJobRoleArray
                    // .filter(e=>e.jobrolename.toLowerCase().includes(searchValue.toLowerCase()));
                    // this.setState({jobRoleArray :this.state.jobRoleArray =filteredJobRole })

                    this.setState({ jobRoleArray: this.state.jobRoleArray = arr_duplicate })
                    console.log("arr_duplicateKavitha", this.state.jobRoleArray)
                    // this.setState({jobRoleArray :this.state.jobRoleArray =this.state.jobRoleArray })
                }

            }

        } catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "getSearchValues", 0, error.toString());
        }
    }
    job_loading = (index) => {
        if (this.state.activeTab != index) {
            this.setState({
                activeTab: (this.state.activeTab = index),
                currentItems: (this.state.currentItems = [])
            })
            if (index === "1") {
                localStorage.setItem('all_jobs', false)
                this.clearFilter();
                this.job_list()
            }
            if (index === "2") {
                this.applied_job_list()
            }
            if (index === "3") {
                localStorage.setItem('all_jobs', true)
                this.setState({ skip: this.state.skip = 0 })
                this.clearFilter();
                this.job_list('all_jobs')
            }
        }
    }
    search_view() {
        try {
            if (this.state.common_array_duplicate && this.state.common_array_duplicate.length > 0) {
                return this.state.common_array_duplicate.map((recent, index) => {
                    const { name, status_name, hover } = recent
                    return (
                        <div key={index} >
                            <div md="11" style={{ marginLeft: "2%" }}>
                                <div
                                    onClick={() => this.recentSearchClick(recent)}
                                    onMouseOver={() => this.someHandler_one(recent)}
                                    onMouseLeave={() => this.someOtherHandler_one(recent)}
                                    style={{
                                        backgroundColor: 'white',
                                        ...(hover && { backgroundColor: '#efe9e9', }),
                                    }}
                                    className="notify-margin" >
                                    {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                                    <span className="cursor-pointer dropdown" onClick={() => this.recentSearchClick(recent)} > {name}</span><span className="span_pointer"></span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "search_view", 0, error.toString());
        }

    }

    someHandler = (recent) => {
        try {
            recent.hover = true
            this.setState({ recent_array_duplicate: this.state.recent_array_duplicate })
            this.setState({ hover: this.state.hover = true })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }
    someOtherHandler = (recent) => {
        try {
            recent.hover = false
            this.setState({ recent_array_duplicate: this.state.recent_array_duplicate })
            this.setState({ hover: this.state.hover = false })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }
    someHandler_one = (recent) => {
        try {
            recent.hover = true
            this.setState({ common_array_duplicate: this.state.common_array_duplicate })
            this.setState({ hover: this.state.hover = true })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }

    someOtherHandler_one = (recent) => {
        try {
            recent.hover = false
            this.setState({ common_array_duplicate: this.state.common_array_duplicate })
            this.setState({ hover: this.state.hover = false })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }


    searchclear(flag) {
        if (flag == 1) {
            this.setState({
                location_val: this.state.location_val = '',
                locationcodeval: this.state.locationcodeval = []
            })
        }
        else if (flag == 2) {
            this.setState({
                jobrole_val: this.state.jobrole_val = '',
                jobRoleArray: this.state.jobRoleArray = this.state.allJobRoleArray,
                jobrolecodeval: this.state.jobrolecodeval = [],
                enableSearch: this.state.enableSearch = false,
                jobroleresult: this.state.jobroleresult = false,
                selectedJobRoleIndex: this.state.selectedJobRoleIndex = 0,
                FilterTitleitemOffset: (this.state.FilterTitleitemOffset = 0),
                jobRoleArray: (this.state.jobRoleArray = [])
            })

            this.pageFilterBind(this.state.FilterTitleitemOffset, this.state.FilterTitleitemsPerPage)
        }
        else {
            this.setState({
                jobfunction_val: this.state.jobfunction_val = '',
                jobfunctioncodeval: this.state.jobfunctioncodeval = []
            })
            this.setState({
                jobfunctioncode_api: this.state.jobfunctioncode_api = [],
                jobfunctioncode_api: this.state.jobfunctioncode_api = this.state.profile_jobfunction
            })
        }
    }
    handleInputChange = (e) => {
        this.setState({ fromSalary: e.target.value });
    };
    handleOtherInputChange = (e) => {
        this.setState({ toSalary: e.target.value });
    };
    handleMaxValueBlur = () => {
        const { toSalary, fromSalary } = this.state;

        if (parseInt(toSalary, 10) < parseInt(fromSalary, 10)) {
            toast("Maximum salary must be greater than or equal to the minimum salary.", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar'
            });

        }
    };
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const { fromSalary, toSalary, leadtype } = this.state;
        return (
        // {this.state.leadtype == 1 ? 
            <div className="site-main">
                {!this.state.is_auth && <Redirect push to="/login" />}
                <ToastContainer autoClose={10000} toastStyle={{ backgroundColor: "#ed9d3b", color: 'white' }} progressStyle={{ background: 'white' }} />
                <Header />

                {/* PageHeader */}
                {/* <PageHeader
                    title={stringsoflanguages.jobsummary_privatejobs}
                    breadcrumb={stringsoflanguages.jobsummary_privatejobs}
                    className="pb-65 pb-lg-0"
                /> */}
                {/* PageHeader end */}

                {/* form */}
                {this.state.leadtype == 0 ? 
                <div>
                <div >
                    <LoadingOverlay
                        active={this.state.isActive}
                        spinner={
                            <img src="images/svg/loading.gif" className="dz-img rounded-circle" alt="" />}
                    //  text='Loading...'
                    ></LoadingOverlay>
                    {this.state.openFilters && window.innerWidth <= 850 && <div className='opacity-diabg'></div>}

                    <div className="form-section clearfix">
                        <div className="container">
                            {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg border rounded p-40 p-lg-20 mt_70 mt-lg-50">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <div id="search_Form" className="search_Form wrap-form">
                                            <label>
                                                
                                                <input type="text"
                                                    id="txtjobfunction"
                                                    className="input-serch"
                                                    name="txtjobfunction"
                                                    value={this.state.jobfunction_val}
                                                    onChange={(e) => this.getSearchValues(e.target.value, 3)}
                                                    // onFocus={() => this.showRecentSearch()}
                                                    ref={this.inputRef}
                                                    onKeyDown={e => this.handleGlobalKeyDown(e)}
                                                    autoComplete="off"
                                                    placeholder={stringsoflanguages.home_jobfunction}  />
                     <i className="ti ti-close searchclear" onClick={e => { this.searchclear(3) }}></i>
                                                {this.state.jobfunctionresult == true ?
                                                    <div className="cls-searchprofile cls_scroll_search">
                                                        
                                                        <div hidden={this.state.search_array_hidden}>
                                                            <div style={{ marginTop: "10px" }}>
                                                             
                                                                {this.search_view()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                                    
                                                }
                                                
                                            </label>

                                            <label>
                                                <input type="text"
                                                    id="txtjobrole"
                                                    className="input-serch"
                                                    name="txtjobrole"
                                                    value={this.state.jobrole_val}
                                                    onChange={(e) => this.getSearchValues(e.target.value, 2)}
                                                   
                                                    autoComplete="off"
                                                    placeholder={stringsoflanguages. pre_jobrole}
                                                    ref={this.inputRoleRef}
                                                    onKeyDown={(e) => this.handleJobRoleKeyDown(e)}
                                                      />
                                                <i className="ti ti-close searchclear" onClick={e => { this.searchclear(2) }}></i>
                                                {this.state.jobroleresult == true ?
                                                    <div className="cls-searchprofile cls_scroll_search">
                                                       
                                                        <div hidden={this.state.search_array_hidden}>
                                                            <div style={{ marginTop: "10px" }}>
                                                               
                                                                {this.search_view()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                            </label>

                                            <label>
                                               
                                                <input type="text"
                                                    id="txtlocation"
                                                    className="input-serch"
                                                    name="txtlocation"
                                                    value={this.state.location_val}
                                                    onChange={(e) => this.getSearchValues(e.target.value, 1)}
                                               
                                                    ref={this.inputLocationRef}
                                                    onKeyDown={(e) => this.handleLocationKeyDown(e)}
                                                    autoComplete="off"
                                                    placeholder={stringsoflanguages.edu_aft_schl_location} />
                                                <i className="ti ti-close searchclear" onClick={e => { this.searchclear(1) }}></i>
                                                {this.state.locationresult == true ?
                                                    <div className="cls-searchprofile cls_scroll_search">
                                                     
                                                        <div hidden={this.state.search_array_hidden}>
                                                            <div style={{ marginTop: "10px" }}>
                                                            
                                                                {this.search_view()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                            </label>

                                         
                                            <button className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor" onClick={e => { this.findclick() }}>{stringsoflanguages.findjobs_text}</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    {/* form end */}



                    <div className="ttm-row sidebar job-sidebar clearfix" >
                        {window.innerWidth <= 850 &&
                            (
                                <div className=""><Job_role_filter /></div>
                            )}
                        <div className="container" style={window.innerWidth < 450 ? { paddingRight: '0px' } : {}}>
                            {/* row */}

                            <div className="ttm-tabs ttm-tab-style-01">
                                <div className="react-tabs" data-tabs="true" style={{ display: 'flex' }}>

                                    {window.innerWidth > 850 && this.state.activeTab != 2 && (this.state.currentItems.length > 0 || this.state.isFilterApplied) && <div style={window.innerWidth > 850 ? { width: "36%" } : { width: "100%" }}>
                                        <div className="row" >


                                            {window.innerWidth > 850 && (<div className="col-lg-12 widget-area sidebar-left job_list-widget-area">
                                                <div className="job_list-widget">

                                                    {/* <aside onClick={e => { this.filterlistchanges('jobfunction') }} className="widget job-widget">

                                        <h3 className="widget-title"><i className="flaticon flaticon-subfolder-1"></i>Job Function</h3>
                                        <form id="list2" onSubmit={this.formSubmit} className="list-filter">
                                            {this.state.showjobfunction == true ?
                                                <form id="list2" onSubmit={this.formSubmit} className="list-filter filtermax">
                                                    {this.bind_jobfunction(this.state.jobfunctionarray, 1)}
                                                </form>
                                                : ''}

                                        </form>
                                    </aside> */}
                                                    {/* <aside onClick={e => { this.filterlistchanges('skills') }} className="widget job-widget">
                                        <h3 className="widget-title"><i className="flaticon flaticon-subfolder-1"></i>Skills</h3>
                                        <form id="list3" onSubmit={this.formSubmit} className="list-filter">
                                            {this.state.showskills == true ?
                                                <form id="list2" onSubmit={this.formSubmit} className="list-filter filtermax">
                                                    {this.bind_skills(this.state.skilllist, 1)}
                                                </form>

                                                : ''}
                                        </form>
                                    </aside> */}

                                                    {(this.state.getroleValue == '0' || this.state.getroleValue == null || !this.state.getroleValue) && <Card className="widget job-widget">
                                                        <CardHeader id="item-1">
                                                            <h3 className="widget-title"><img src="images/svg/jobtitle.png" style={{ marginRight: '3%' }} alt="" />{stringsoflanguages.pre_jobrole}<i className='fa fa-chevron-down collapse-icon cursor-ptr' style={{ marginLeft: '96px', fontSize: '20px' }}></i></h3>
                                                        </CardHeader>
                                                        <UncontrolledCollapse id="me nuitem-1" toggler="#item-1" defaultOpen={true}>
                                                            <div className="ttm-col-bgcolor-yes ttm-bg rounded" style={{ padding: "28px" }}>
                                                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                                                    <div className="layer-content">
                                                                        <div className="search_Form wrap-form">
                                                                            <label>
                                                                                <i className="ti ti-search cursor search_align" style={{ fontSize: '14px', marginTop: '4px', left: '1px', paddingLeft: '9px' }}></i>
                                                                                <input type="text"
                                                                                    id="txtjobrole"
                                                                                    className="input-serch fs-12"
                                                                                    name="txtjobrole"
                                                                                    onChange={(e) => {
                                                                                        this.setState({ jobrole_val: this.state.jobrole_val = e.target.value });
                                                                                        this.getSearchValues(e.target.value, 2)
                                                                                    }}
                                                                                    style={{ padding: '12px 30px 12px 30px', borderRadius: '4px' }}
                                                                                    value={this.state.jobrole_val}
                                                                                    autoComplete="off"
                                                                                    placeholder={stringsoflanguages.accountant}
                                                                                    ref={this.inputRoleRef}
                                                                                // onKeyDown={(e) => this.handleJobRoleKeyDown(e)}
                                                                                />
                                                                                {this.state.jobrole_val.length > 0 ?
                                                                                    <i className="ti ti-close searchclear" style={{ cursor: 'pointer', right: '12px', left: 'unset' }} onClick={e => { this.searchclear(2) }}></i>
                                                                                    : ''
                                                                                }

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <form id="list1" onSubmit={this.formSubmit} className="list-filter webfiltermax" onScroll={this.handleScroll}>
                                                                {this.bind_job_role(this.state.jobRoleArray, 0)}
                                                                {this.state.jobRoleArray && this.state.jobRoleArray.length == 0 && this.state.allJobRoleArray.length > 0 && <div className='no-record no-rec-search'>
                                                                    {stringsoflanguages.schooling_no_record}
                                                                </div>}
                                                            </form>
                                                        </UncontrolledCollapse>
                                                    </Card>}

                                                    <Card className="widget job-widget">
                                                        <CardHeader id="item-2">
                                                            <h3 className="widget-title nor-warp"><img src="images/svg/job_location.png" style={{ marginRight: '3%' }} alt="" />{stringsoflanguages.home_joblocation} <i className='fa fa-chevron-down collapse-icon cursor-ptr' style={{ marginLeft: '70px', fontSize: '20px' }}></i></h3>

                                                        </CardHeader>
                                                        <UncontrolledCollapse id="menuitem-2" toggler="#item-2" defaultOpen={true}>
                                                            <form id="list2" onSubmit={this.formSubmit} className="list-filter webfiltermax">
                                                                {this.bind_location(this.state.districtarray, 0)}
                                                            </form>
                                                        </UncontrolledCollapse>
                                                    </Card>

                                                    {/* <Card className="widget job-widget">
                                        <CardHeader id="item-3" onClick={() => { this.filterlistchanges('jobfunction') }}>
                                            <h3 className="widget-title"><i className="flaticon flaticon-subfolder-1"></i> {stringsoflanguages.home_jobfunction} <i className='fa fa-chevron-down collapse-icon' style={{ marginLeft: '70px', fontSize: '20px' }}></i></h3>

                                        </CardHeader>
                                        <UncontrolledCollapse id="menuitem-3" toggler="#item-3">
                                            <form id="list2" onSubmit={this.formSubmit} className="list-filter filtermax">
                                                {this.bind_jobfunction(this.state.jobfunctionarray, 1)}
                                            </form>
                                        </UncontrolledCollapse>
                                    </Card> */}


                                                    {/* <Card className="widget job-widget">
                                        <CardHeader id="item-4" onClick={e => { this.call_job_skill() }}>
                                            <h3 className="widget-title"><i className="flaticon flaticon-subfolder-1"></i>Skills<i className='fa fa-chevron-down collapse-icon' style={{ marginLeft: '122px', fontSize: '20px' }}></i></h3>
                                        </CardHeader>
                                        <UncontrolledCollapse id="menuitem-4" toggler="#item-4">
                                            <form id="list3" className="list-filter filtermax">
                                                {this.bind_jobskill(this.state.skillarray, 3)}
                                            </form>
                                        </UncontrolledCollapse>
                                    </Card> */}

                                                    <Card className="widget job-widget">
                                                        <CardHeader id="item-5">
                                                            <h3 className="widget-title"><img src="images/svg/gender.png" style={{ marginRight: '3%' }} alt="" />{stringsoflanguages.personal_gender}<i className='fa fa-chevron-down collapse-icon genfilter cursor-ptr'></i></h3>
                                                        </CardHeader>
                                                        <UncontrolledCollapse id="me menuitem-5" toggler="#item-5" defaultOpen={true}>
                                                            <form id="list4" className="list-filter webfiltermax">
                                                                {this.bind_genderstatus(this.state.jobgenderarr, 8)}
                                                            </form>
                                                        </UncontrolledCollapse>
                                                    </Card>
                                                    <Card className="widget job-widget">
                                                        <CardHeader id="item-6" >
                                                            <h3 className="widget-title"><img src="images/svg/salary.png" style={{ marginRight: '3%' }} alt="" />{stringsoflanguages.pre_header_popup_sal} <i className='fa fa-chevron-down collapse-icon genfilter cursor-ptr'></i></h3>
                                                        </CardHeader>
                                                        <UncontrolledCollapse id="menuitem-6" toggler="#item-6" defaultOpen={true}>

                                                            <form className="form">
                                                                <Box className='salary-slider'>
                                                                    <Slider
                                                                        min={0}
                                                                        max={100000}
                                                                        step={5000}
                                                                        marks={this.state.getSalaryMarks}
                                                                        value={this.state.salaryRangeVal} onChange={this.handleChange} />
                                                                </Box>
                                                                <Box className='p-1 just-center disp-align text-dark'>{stringsoflanguages.salary_scale} : <span className='ft-wt500' style={{ paddingLeft: '4px' }}>
                                                                    ₹{this.state.salaryRangeVal[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} - ₹{this.state.salaryRangeVal[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                </span>
                                                                </Box>
                                                            </form>
                                                        </UncontrolledCollapse>
                                                    </Card>

                                                    <Card className="widget job-widget">
                                                        <CardHeader id="item-7" >
                                                            <h3 className="widget-title"><img src="images/svg/experience.png" style={{ marginRight: '3%' }} alt="" />{stringsoflanguages.rl_experience} <i className='fa fa-chevron-down collapse-icon genfilter cursor-ptr'></i></h3>
                                                        </CardHeader>
                                                        <UncontrolledCollapse id="menuitem-7" toggler="#item-7" defaultOpen={true}>

                                                            <form id="list5" onSubmit={this.formSubmit} className="list-filter filtermax">  {this.bind_Experience(this.state.dpExperience)} </form>
                                                        </UncontrolledCollapse>
                                                    </Card>

                                                    <div className="disp-align just-space">
                                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor fs-12" style={{ minWidth: "100px", width: "auto", backgroundColor: '#111922', borderColor: 'transparent' }} onClick={e => { this.applyfilter(true) }}>{stringsoflanguages.rl_applyfilter}</a>
                                                        {(this.state.isFilterApplied || (this.state.profile_jobrole && this.state.profile_jobrole.length > 0) || (this.state.profile_location && this.state.profile_location.length > 0) || (this.state.profile_gender && this.state.profile_gender.length > 0) || (this.state.job_experience_array && this.state.job_experience_array.length > 0) || (this.state.salaryRangeVal[0] != 0 && this.state.salaryRangeVal[1] != 100000)) && (<a className="fs-12 ttm-btn ttm-btn-size-md txt-upper ttm-btn-shape-rounded ttm-btn-style-fill clear_filter" style={{ minWidth: "100px", width: "auto", border: '1px solid #111922', color: "#111922", background: 'white' }} onClick={e => { this.clearFilter() }}>
                                                            {this.state.isFilterApplied ? <>{stringsoflanguages.clear_filter_inicaps}</> : <>{stringsoflanguages.reset_filter_inicaps}</>}</a>)}
                                                    </div>
                                                </div>
                                                {/* <aside className="widget widget-download">
                                    <ul className="download">
                                        <li><a href="#">Download.pdf</a><i className="ti ti-files"></i></li>
                                        <li><a href="#">Download.txt</a><i className="ti ti-files"></i></li>
                                    </ul>
                                </aside>
                                <aside className="widget widget-banner text-theme-WhiteColor">
                                    <div className="ttm-col-bgcolor-yes bg-theme-DarkColor text-theme-WhitColor col-bg-img-seven ttm-col-bgimage-yes ttm-bg p-40">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor" style={{ backgroundImage: 'url(https://via.placeholder.com/875x583?text=875x583+col-bgimage-7.jpg)' }}>
                                            <div className="ttm-col-wrapper-bg-layer-inner bg-theme-DarkColor"></div>
                                        </div>
                                        <div className="layer-content text-center">
                                            <div className="widget-banner-inner">
                                                <h3 className="mb-15">Make a Difference with Online Resume!</h3>
                                                <p className="mb-30">Your Resume in Minutes with Jobs Resume Assistant is Ready!</p>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                    href={'/'}>appoinments!</a>
                                            </div>
                                        </div>
                                    </div>
                                </aside> */}
                                            </div>)}
                                        </div>
                                    </div>}

                                    <div style={window.innerWidth <= 850 ? { width: "100%" } : window.innerWidth <= 1200 ? { width: "69%" } : { width: "83%" }}>
                                        {(this.state.getroleValue == '0' || this.state.getroleValue == null) ?
                                            <ul className="tabs" role="tablist">
                                                <li className={`tab mobile-40 ${this.state.activeTab == 1 ? `react-tabs__tab--selected` : ''}`} role="tab" id="react-tabs-0" aria-selected="true"
                                                    aria-disabled="false" aria-controls="react-tabs-1" onClick={() => {this.job_loading("1"); localStorage.setItem("istabclick", 1)}}><a className="tab-1 fs-12 mobile-per" tabIndex="0"><span className="jobTab">{this.state.employeecode > 0 ? stringsoflanguages.recommended_jobs_label : stringsoflanguages.findlist_job}</span></a></li>
                                                <li className={`tab mobile-30 ${this.state.activeTab == 3 ? `react-tabs__tab--selected` : ''}`} role="tab" id="react-tabs-4" aria-selected="false" aria-disabled="false" aria-controls="react-tabs-4" onClick={() => {this.job_loading("3"); localStorage.setItem("istabclick", 3)}}><a className="tab-2 fs-12 mobile-per" tabIndex="1"><span className="jobTab">{stringsoflanguages.all_jobs}</span></a></li>
                                                <li className={`tab mobile-30 ${this.state.activeTab == 2 ? `react-tabs__tab--selected` : ''}`} role="tab" id="react-tabs-2" aria-selected="false" aria-disabled="false" aria-controls="react-tabs-3" onClick={() => {this.job_loading("2"); localStorage.setItem("istabclick", 2)}}><a className="tab-2 fs-12 mobile-per" tabIndex="1"><span className="jobTab">{stringsoflanguages.applied_jobs}</span></a></li>

                                            </ul> : <><div className='search_font'><div>{stringsoflanguages.searchJobRole}</div>{window.innerWidth > 850 && this.state.getroleValue == '1' && <div style={{ float: 'right', marginTop: "-3%" }}><button className="btnnext" style={{ width: '100%' }} onClick={() => this.gotoRecommded()}>{stringsoflanguages.recommended_jobs_label}</button></div>}

                                                {window.innerWidth < 850 && this.state.getroleValue == '1' && <div style={{ float: 'right', marginTop: "-7%" }}><div style={{ width: '100%', fontSize: '11px', textDecoration: 'underline' }} onClick={() => this.gotoRecommded()}>{stringsoflanguages.recommended_jobs_label}</div></div>}
                                            </div></>}
                                        <div className="content-tab" style={{ marginTop: '5px' }}>
                                            <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel" id="react-tabs-1"
                                                aria-labelledby="react-tabs-0" style={{ display: this.state.activeTab == 1 ? "block" : "none" }}>

                                                {(this.state.currentItems.length > 0 || this.state.isFilterApplied) && window.innerWidth <= 850 ?
                                                    <div className="row" style={{ width: "100%" }}>
                                                        {window.innerWidth <= 850 && (
                                                            <div className="d-flex overflow-filter" style={window.innerWidth <= 850 ? { paddingTop: "5px", paddingLeft: "0px" } : { paddingTop: "5px" }}>

                                                                <div className="btn-white text-black nor-warp" style={{ color: 'rgb(130 120 120 / 80%)', marginLeft: '10px', fontSize: '12px' }}> {stringsoflanguages.filter_by} : </div>
                                                                {this.state.menuItems.map((Val, id) => {
                                                                    return (
                                                                        <div
                                                                            className={this.state.applyFilters.includes(Val.menuCodeId) ? 'text-black applied_jobs mar-lef-12 fs-12' : 'btn-white text-black mar-lef-12 fs-12'} style={{ cursor: "pointer" }}
                                                                            key={id} onClick={e => {
                                                                                this.setState({
                                                                                    openFilters: (this.state.openFilters = true)
                                                                                })

                                                                                this.setState({
                                                                                    selectedFilterTitle: (this.state.selectedFilterTitle = Val.menuName)
                                                                                })
                                                                                this.setState({
                                                                                    selectedFilterCode: (this.state.selectedFilterCode = Val.menuCodeId)
                                                                                })
                                                                            }}
                                                                        >
                                                                            <div className='disp-align nor-warp'> {Val.menuName} <i className='fa fa-chevron-down collapse-icon' style={{ fontSize: '11px', marginLeft: '10px' }}></i> </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                                {this.state.applyFilters && this.state.applyFilters.length > 0 && <div className="btn-white text-black" style={{ color: 'rgb(130 120 120 / 80%)', marginLeft: '10px' }} onClick={e => { this.clearFilter() }}>
                                                                    <div className='disp-align nor-warp fs-12 cursor-pointer'>| {stringsoflanguages.clear_filter_inicaps} </div>
                                                                </div>}
                                                            </div>
                                                        )}
                                                    </div> : null}
                                                {(this.state.getroleValue == '0' || this.state.getroleValue == null || !this.state.getroleValue) ? <>

                                                    {
                                                        this.state.isActive ? <></> : (<>

                                                            {this.state.currentItems.length > 0 && (<div className='fs-12 align-center'>({stringsoflanguages.showing} {this.state.startPage} – {this.state.endPage} {stringsoflanguages.job_of} {this.state.totalCount} {stringsoflanguages.noti_jobs})</div>)}
                                                        </>)}

                                                    <div className="row wt-100 col-lg-8">
                                                        <div style={{ paddingRight: '0px' }}>
                                                            {this.state.currentItems.length == 0 && this.state.isFilterApplied && !this.state.isActive && (<div className="section-title title-style-center_text">
                                                                <div className="title-header alignment-txt" style={{ flexDirection: 'column' }}>
                                                                    <span className="title no-record">{stringsoflanguages.no_records}</span>
                                                                    <span style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '11px' }} onClick={e => { this.clickHere() }}>{stringsoflanguages.ClickhereMoreSearch}</span>
                                                                </div>
                                                            </div>)}
                                                            {this.joblist(this.state.currentItems)}
                                                            {this.state.currentItems.length > 0 ?
                                                                <div className="col-lg-12">
                                                                    {/* <div className="header_btn">
                  <button className="btnprevious" style={{width: "auto"}} onClick={e => { this.loadMore() }}>{stringsoflanguages.load_deng}</button>
                  </div> */}
                                                                    <div>

                                                                        {/* <a className="load_btn_style" onClick={e => { this.loadMore() }}>
                      {'Load More'}</a> */}
                                                                    </div>

                                                                    {/* <a className="page-nav-link">prev</a> */}
                                                                    {/* {this.bind_pageslist()} */}
                                                                    {/* <a className="page-nav-link current">1</a>
                  <a className="page-nav-link" href="#">2</a>
                  <a className="page-nav-link" href="#">3</a>
                  <a className="page-nav-link" href="#">....</a>
                  <a className="page-nav-link">next</a> */}
                                                                    {/* <div className="job-pagination-blocknew">
                      <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={this.handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={this.state.pageCount}
                          previousLabel="< previous"
                          renderOnZeroPageCount={null}
                      />
                  </div> */}
                                                                </div>
                                                                : <>{this.state.currentItems.length == 0 && !this.state.isFilterApplied && !this.state.isActive && <div className="section-title title-style-center_text">

                                                                    <div className="section-title title-style-center_text">
                                                                        <div className="title-header alignment-txt" style={{ flexDirection: 'column' }}>
                                                                            <span className="title no-record">{stringsoflanguages.no_recommended_jobs}</span>
                                                                            <span style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '11px' }} onClick={e => { this.clickHere() }}>{stringsoflanguages.ClickhereMoreSearch}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>}</>
                                                            }

                                                        </div>
                                                    </div>
                                                </> : <div>
                                                    <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel" id="react-tabs-1"
                                                        aria-labelledby="react-tabs-0" style={{ display: this.state.activeTab == 1 ? "block" : "none" }}>
                                                        {/* {window.innerWidth <= 1000 &&
                                        (<Job_role_filter  />
                                 )} */}
                                                        {
                                                            this.state.isActive ? <></> : (<>
                                                                <div className="row">

                                                                    {this.state.currentItems.length > 0 && (<div className='fs-12 align-center'>({stringsoflanguages.showing} {this.state.startPage} – {this.state.endPage} {stringsoflanguages.job_of} {this.state.totalCount} {stringsoflanguages.noti_jobs})</div>)}
                                                                    {this.joblist(this.state.currentItems)}

                                                                    {this.state.currentItems.length > 0 ?
                                                                        <div className="col-lg-12">
                                                                            {/* <div className="header_btn">
                    
                  <button className="btnprevious" style={{width: "auto"}} onClick={e => { this.loadMore() }}>{stringsoflanguages.load_deng}</button>
                  </div> */}
                                                                            <div>

                                                                                {/* <a className="load_btn_style" onClick={e => { this.loadMore() }}>
                      {'Load More'}</a> */}
                                                                            </div>
                                                                            {/* { this.state.totalCount >= 10 ? <div className="job-pagination-blocknew stickyButton" >
                      <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={this.handlePageClick}
                          pageRangeDisplayed={10}
                          pageCount={this.state.pageCount}
                          previousLabel="< prev"
                          renderOnZeroPageCount={null}
                      />
                  </div> : <></>}     */}
                                                                            {/* <a className="page-nav-link">prev</a> */}
                                                                            {/* {this.bind_pageslist()} */}
                                                                            {/* <a className="page-nav-link current">1</a>
                  <a className="page-nav-link" href="#">2</a>
                  <a className="page-nav-link" href="#">3</a>
                  <a className="page-nav-link" href="#">....</a>
                  <a className="page-nav-link">next</a> */}



                                                                        </div>

                                                                        : <>{!this.state.isActive && <div className="section-title title-style-center_text">
                                                                            <div className="title-header alignment-txt">
                                                                                <span className="title no-record">{stringsoflanguages.no_search_jobs}</span>
                                                                            </div>
                                                                        </div>}</>
                                                                    }
                                                                </div>
                                                            </>)}
                                                    </div>
                                                </div>}
                                            </div>


                                            <div className="react-tabs__tab-panel" role="tabpanel" id="react-tabs-3" aria-labelledby="react-tabs-2" style={{ display: this.state.activeTab == 2 ? "block" : "none" }}>
                                                {
                                                    this.state.isActive ? <></> : (<> <div className="row">
                                                        {this.joblist(this.state.currentItems)}
                                                        {this.state.currentItems.length > 0 ?
                                                            <div className="col-lg-12">
                                                                <div>
                                                                </div>

                                                                {/* <a className="page-nav-link">prev</a> */}
                                                                {/* {this.bind_pageslist()} */}
                                                                {/* <a className="page-nav-link current">1</a>
         <a className="page-nav-link" href="#">2</a>
         <a className="page-nav-link" href="#">3</a>
         <a className="page-nav-link" href="#">....</a>
         <a className="page-nav-link">next</a> */}
                                                                {/* <div className="job-pagination-blocknew">
             <ReactPaginate
                 breakLabel="..."
                 nextLabel="next >"
                 onPageChange={this.handlePageClick}
                 pageRangeDisplayed={5}
                 pageCount={this.state.pageCount}
                 previousLabel="< previous"
                 renderOnZeroPageCount={null}
             />
         </div> */}


                                                            </div>

                                                            :
                                                            <div className="section-title title-style-center_text">
                                                                <div className="title-header alignment-txt">
                                                                    <span className="title no-record">{stringsoflanguages.no_applied_jobs}</span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    </>)}
                                            </div>

                                            <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel" id="react-tabs-4"
                                                aria-labelledby="react-tabs-0" style={{ display: this.state.activeTab == 3 ? "block" : "none" }}>

                                                {(this.state.currentItems.length > 0 || this.state.isFilterApplied) && window.innerWidth <= 850 ?
                                                    <div className="row" style={{ width: "100%" }}>
                                                        {window.innerWidth <= 850 && (
                                                            <div className="d-flex overflow-filter" style={window.innerWidth <= 850 ? { paddingTop: "5px", paddingLeft: "0px" } : { paddingTop: "5px" }}>

                                                                <div className="btn-white text-black nor-warp" style={{ color: 'rgb(130 120 120 / 80%)', marginLeft: '10px', fontSize: '12px' }}> {stringsoflanguages.filter_by} : </div>
                                                                {this.state.menuItems.map((Val, id) => {
                                                                    return (
                                                                        <div
                                                                            className={this.state.applyFilters.includes(Val.menuCodeId) ? 'text-black applied_jobs mar-lef-12 fs-12' : 'btn-white text-black mar-lef-12 fs-12'} style={{ cursor: "pointer" }}
                                                                            key={id} onClick={e => {
                                                                                this.setState({
                                                                                    openFilters: (this.state.openFilters = true)
                                                                                })

                                                                                this.setState({
                                                                                    selectedFilterTitle: (this.state.selectedFilterTitle = Val.menuName)
                                                                                })
                                                                                this.setState({
                                                                                    selectedFilterCode: (this.state.selectedFilterCode = Val.menuCodeId)
                                                                                })
                                                                            }}
                                                                        >
                                                                            <div className='disp-align nor-warp'> {Val.menuName} <i className='fa fa-chevron-down collapse-icon' style={{ fontSize: '11px', marginLeft: '10px' }}></i> </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                                {this.state.applyFilters && this.state.applyFilters.length > 0 && <div className="btn-white text-black" style={{ color: 'rgb(130 120 120 / 80%)', marginLeft: '10px' }} onClick={e => { this.clearFilter() }}>
                                                                    <div className='disp-align nor-warp fs-12 cursor-pointer'>| {stringsoflanguages.clear_filter_inicaps} </div>
                                                                </div>}
                                                            </div>
                                                        )}
                                                    </div> : null}
                                                {(this.state.getroleValue == '0' || this.state.getroleValue == null || !this.state.getroleValue) ? <>

                                                    {
                                                        this.state.isActive ? <></> : (<>

                                                            {this.state.currentItems.length > 0 && (<div className='fs-12 align-center'>({stringsoflanguages.showing} {this.state.startPage} – {this.state.endPage} {stringsoflanguages.job_of} {this.state.totalCount} {stringsoflanguages.noti_jobs})</div>)}
                                                        </>)}

                                                    <div className="row wt-100 col-lg-8">
                                                        <div style={{ paddingRight: '0px' }}>
                                                            {this.state.currentItems.length == 0 && this.state.isFilterApplied && !this.state.isActive && (<div className="section-title title-style-center_text">
                                                                <div className="title-header alignment-txt" style={{ flexDirection: 'column' }}>
                                                                    <span className="title no-record">{stringsoflanguages.no_records}</span>
                                                                </div>
                                                            </div>)}
                                                            {this.joblist(this.state.currentItems)}
                                                            {this.state.currentItems.length > 0 ?
                                                                <div className="col-lg-12">
                                                                    {/* <div className="header_btn">
                  <button className="btnprevious" style={{width: "auto"}} onClick={e => { this.loadMore() }}>{stringsoflanguages.load_deng}</button>
                  </div> */}
                                                                    <div>

                                                                        {/* <a className="load_btn_style" onClick={e => { this.loadMore() }}>
                      {'Load More'}</a> */}
                                                                    </div>

                                                                    {/* <a className="page-nav-link">prev</a> */}
                                                                    {/* {this.bind_pageslist()} */}
                                                                    {/* <a className="page-nav-link current">1</a>
                  <a className="page-nav-link" href="#">2</a>
                  <a className="page-nav-link" href="#">3</a>
                  <a className="page-nav-link" href="#">....</a>
                  <a className="page-nav-link">next</a> */}
                                                                    {/* <div className="job-pagination-blocknew">
                      <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={this.handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={this.state.pageCount}
                          previousLabel="< previous"
                          renderOnZeroPageCount={null}
                      />
                  </div> */}
                                                                </div>
                                                                : <>{this.state.currentItems.length == 0 && !this.state.isFilterApplied && !this.state.isActive && <div className="section-title title-style-center_text">

                                                                    <div className="section-title title-style-center_text">
                                                                        <div className="title-header alignment-txt" style={{ flexDirection: 'column' }}>
                                                                            <span className="title no-record">{stringsoflanguages.no_recommended_jobs}</span>
                                                                            <span style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '11px' }} onClick={e => { this.clickHere() }}>{stringsoflanguages.ClickhereMoreSearch}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>}</>
                                                            }

                                                        </div>
                                                    </div>
                                                </> : <div>
                                                    <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel" id="react-tabs-1"
                                                        aria-labelledby="react-tabs-0" style={{ display: this.state.activeTab == 1 ? "block" : "none" }}>
                                                        {/* {window.innerWidth <= 1000 &&
                                        (<Job_role_filter  />
                                 )} */}
                                                        {
                                                            this.state.isActive ? <></> : (<>
                                                                <div className="row">

                                                                    {this.state.currentItems.length > 0 && (<div className='fs-12 align-center'>({stringsoflanguages.showing} {this.state.startPage} – {this.state.endPage} {stringsoflanguages.job_of} {this.state.totalCount} {stringsoflanguages.noti_jobs})</div>)}
                                                                    {this.joblist(this.state.currentItems)}

                                                                    {this.state.currentItems.length > 0 ?
                                                                        <div className="col-lg-12">
                                                                            {/* <div className="header_btn">
                    
                  <button className="btnprevious" style={{width: "auto"}} onClick={e => { this.loadMore() }}>{stringsoflanguages.load_deng}</button>
                  </div> */}
                                                                            <div>

                                                                                {/* <a className="load_btn_style" onClick={e => { this.loadMore() }}>
                      {'Load More'}</a> */}
                                                                            </div>
                                                                            {/* { this.state.totalCount >= 10 ? <div className="job-pagination-blocknew stickyButton" >
                      <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={this.handlePageClick}
                          pageRangeDisplayed={10}
                          pageCount={this.state.pageCount}
                          previousLabel="< prev"
                          renderOnZeroPageCount={null}
                      />
                  </div> : <></>}     */}
                                                                            {/* <a className="page-nav-link">prev</a> */}
                                                                            {/* {this.bind_pageslist()} */}
                                                                            {/* <a className="page-nav-link current">1</a>
                  <a className="page-nav-link" href="#">2</a>
                  <a className="page-nav-link" href="#">3</a>
                  <a className="page-nav-link" href="#">....</a>
                  <a className="page-nav-link">next</a> */}



                                                                        </div>

                                                                        : <>{!this.state.isActive && <div className="section-title title-style-center_text">
                                                                            <div className="title-header alignment-txt">
                                                                                <span className="title no-record">{stringsoflanguages.no_search_jobs}</span>
                                                                            </div>
                                                                        </div>}</>
                                                                    }
                                                                </div>
                                                            </>)}
                                                    </div>
                                                </div>}
                                            </div>
                                            {this.state.totalCount >= 10 ? <div className="job-pagination-blocknew stickyButton" >
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel="next >"
                                                    onPageChange={this.handlePageClick}
                                                    pageRangeDisplayed={10}
                                                    pageCount={this.state.pageCount}
                                                    previousLabel="< prev"
                                                    renderOnZeroPageCount={null}
                                                />
                                            </div> : <></>}
                                        </div></div>
                                </div></div>


                            <div className="col-lg-12 content-area">

                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <div className="showing-result-block d-sm-flex align-items-center justify-content-between">
                  <span className="showing-result">{this.state.employeecode > 0 ? stringsoflanguages.recommended_jobs_label : stringsoflanguages.findlist_job}</span> */}
                                        {/* <form>
                      <div className="sort-by">Sort By:
                          <select defaultValue="">
                              <option value="" disabled>A to Z</option>
                              <option value="popularity">a</option>
                              <option value="rating">b</option>
                              <option value="date">c</option>
                              <option value="price">d</option>
                              <option value="price-desc">e</option>
                          </select>
                      </div>
                  </form> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                                {/* row end */}
                            </div>



                        </div>
                    </div>
                    {/* accordion */}
                    {/* <Footer /> */}

                </div>

                <div className={this.state.openFilters ? 'open popupContainer' : 'close popupContainer'}>
                    <DialogTitle >
                        <div className='disp-align' style={{ justifyContent: 'space-between' }}>
                            <span className='text-white'>{this.state.selectedFilterTitle}</span>
                            <span className='text-white'>
                                <IconButton onClick={() =>
                                    this.setState({
                                        openFilters: (this.state.openFilters = false)
                                    })
                                }>
                                    <i className="fa fa-times collapse-icon text-white"></i>
                                </IconButton>
                            </span>
                        </div>
                    </DialogTitle>
                    <DialogContent className='bg-white'>

                        {this.state.selectedFilterCode == 2 ? <form id="list2" onSubmit={this.formSubmit} className="list-filter filtermax">  {this.bind_location(this.state.districtarray, 0)} </form> : ""}
                        {this.state.selectedFilterCode == 3 ? <form id="list4" className="list-filter filtermax"> {this.bind_genderstatus(this.state.jobgenderarr, 8)} </form> : ""}
                        {this.state.selectedFilterCode == 4 ? <>
                            <Box className='salary-slider'>
                                <Slider
                                    min={0}
                                    max={100000}
                                    step={5000}
                                    marks={this.state.getSalaryMarks}
                                    value={this.state.salaryRangeVal} onChange={this.handleChange} />
                            </Box>
                            <Box className='p-1 just-center disp-align text-dark'>{stringsoflanguages.salary_scale} : <span className='ft-wt500' style={{ paddingLeft: '4px' }}>
                                ₹{this.state.salaryRangeVal[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} - ₹{this.state.salaryRangeVal[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            </Box>
                        </>
                            : ""}
                        {this.state.selectedFilterCode == 5 ? <form id="list5" onSubmit={this.formSubmit} className="list-filter filtermax">  {this.bind_Experience(this.state.dpExperience)} </form> : ""}

                        <div className='wt-100 disp-align just-center' style={{ paddingTop: '12px' }}>
                            <button className="apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded fs-12 ttm-btn-style-fill ttm-btn-color-skincolor" style={{ minWidth: "100px", width: "auto", backgroundColor: '#111922', borderColor: 'transparent', fontSize: '12px' }} type="submit" onClick={e => { this.applyfilter() }}>{stringsoflanguages.rl_filter}</button>
                        </div>

                    </DialogContent>
                </div>

                <div className="open popupContainer" style={{ display: this.state.openFilters && this.state.selectedFilterCode == 1 ? 'block' : 'none' }}>
                    <DialogTitle >
                        <div className='disp-align' style={{ justifyContent: 'space-between' }}>
                            <span className='text-white'>{this.state.selectedFilterTitle}</span>
                            <span className='text-white'>
                                <IconButton onClick={() => {
                                    this.setState({
                                        openFilters: (this.state.openFilters = false),
                                        jobrole_val: (this.state.jobrole_val = ""),
                                        FilterTitleitemOffset: (this.state.FilterTitleitemOffset = 0),
                                        jobRoleArray: (this.state.jobRoleArray = [])
                                    })
                                    this.pageFilterBind(this.state.FilterTitleitemOffset, this.state.FilterTitleitemsPerPage)
                                    // if(this.state.jobRoleArray.length != this.state.allJobRoleArray.length)
                                    //     this.setState({jobRoleArray :this.state.jobRoleArray =this.state.allJobRoleArray })
                                }
                                }>
                                    <i className="fa fa-times collapse-icon text-white"></i>
                                </IconButton>
                            </span>
                        </div>
                    </DialogTitle>
                    <DialogContent className='bg-white'>
                        <div className="ttm-col-bgcolor-yes ttm-bg rounded" style={{ padding: "28px" }}>
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                <div className="layer-content">
                                    <div className="search_Form wrap-form">
                                        <label>
                                            <i className="ti ti-search cursor search_align" style={{ fontSize: '14px', marginTop: '4px', left: '-5px', paddingLeft: '0px' }}></i>
                                            <input type="text"
                                                id="txtjobrole"
                                                className="input-serch fs-12"
                                                name="txtjobrole"
                                                onChange={(e) => {
                                                    this.setState({ jobrole_val: this.state.jobrole_val = e.target.value });
                                                    this.getSearchValues(e.target.value, 2)
                                                }}
                                                style={{ padding: '11px 36px', borderRadius: '4px' }}
                                                value={this.state.jobrole_val}
                                                autoComplete="off"
                                                placeholder={stringsoflanguages.accountant}
                                                ref={this.inputRoleRef}
                                            // onKeyDown={(e) => this.handleJobRoleKeyDown(e)}
                                            />
                                            {this.state.jobrole_val.length > 0 ?
                                                <i className="ti ti-close searchclear" style={{ cursor: 'pointer', right: '12px', left: 'unset' }} onClick={e => { this.searchclear(2) }}></i>
                                                : ''
                                            }

                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form id="list1" onSubmit={this.formSubmit} className="list-filter filtermax" onScroll={this.handleScroll}>
                            {this.bind_job_role(this.state.jobRoleArray, 0)}

                            {this.state.jobRoleArray && this.state.jobRoleArray.length == 0 && this.state.allJobRoleArray.length > 0 && <div className='no-record no-rec-search'>
                                {stringsoflanguages.schooling_no_record}
                            </div>}
                        </form>
                        <div className='wt-100 disp-align just-center' style={{ paddingTop: '12px' }}>
                            <button className="apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded fs-12 ttm-btn-style-fill ttm-btn-color-skincolor" style={{ minWidth: "100px", width: "auto", backgroundColor: '#111922', borderColor: 'transparent', fontSize: '12px' }} type="submit" onClick={e => { this.applyfilter() }}>{stringsoflanguages.rl_filter}</button>
                        </div>
                    </DialogContent>
                </div></div>
                 : null }
            </div>
            // : null }
            )
        
    }
}

export default Job_list;