import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
// import { Banner } from '../components/banner/Home1_banner';
// import Header from '../components/layout/Header2';
import { Banner } from '../components/banner/Home3_banner';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import ProgressBar from 'react-animated-progress-bar';
import Video from '../components/layout/Video';
import axios from "axios";
import { history } from "../history"
import { APIURL, COMMON } from '../config'
import { generateRefreshToken } from '../serviceworker';
let error_log = require('../error_log');

const sliderElements = [
    {
        id: 1, description: <div className="col-lg-12">
            {/* testimonials */}
            <div className="testimonials ttm-testimonial-box-view-style2" role="group">
                <div className="testimonial-top">
                    <div className="testimonial-avatar">
                        <div className="testimonial-img">
                            <img className="img-fluid" src="https://via.placeholder.com/150x150?text=150x150+01.jpg" alt="testimonial-img" />
                        </div>
                    </div>
                    <div className="testimonial-caption">
                        <label>Manager</label>
                        <h3>Alex Jhon Martin</h3>
                    </div>
                </div>
                <div className="testimonial-content">
                    <blockquote className="testimonial-text">It has always been so easy to do work with any team member at HireCo.
                        An effortless partnership. I was mentored by one of the consultants in a time when I was very young and vulnerable in
                        the HR world. Recommend HireCo!</blockquote>
                </div>
            </div>{/* testimonials end */}
        </div>
    },
    {
        id: 2, description: <div className="col-lg-12">
            {/* testimonials */}
            <div className="testimonials ttm-testimonial-box-view-style2" role="group">
                <div className="testimonial-top">
                    <div className="testimonial-avatar">
                        <div className="testimonial-img">
                            <img className="img-fluid" src="https://via.placeholder.com/150x150?text=150x150+02.jpg" alt="testimonial-img" />
                        </div>
                    </div>
                    <div className="testimonial-caption">
                        <label>Manager</label>
                        <h3>Alex Jhon Martin</h3>
                    </div>
                </div>
                <div className="testimonial-content">
                    <blockquote className="testimonial-text">A national award-winning staffing agency that was built with our
                        trademarked tagline, Expect More, We Do. was born out of the desire to be better. We developed our 4-dimensional
                        matching process with our clients and contractors in mind.</blockquote>
                </div>
            </div>{/* testimonials end */}
        </div>
    },
    {
        id: 3, description: <div className="col-lg-12">
            {/* testimonials */}
            <div className="testimonials ttm-testimonial-box-view-style2" role="group">
                <div className="testimonial-top">
                    <div className="testimonial-avatar">
                        <div className="testimonial-img">
                            <img className="img-fluid" src="https://via.placeholder.com/150x150?text=150x150+03.jpg" alt="testimonial-img" />
                        </div>
                    </div>
                    <div className="testimonial-caption">
                        <label>Manager</label>
                        <h3>Alex Jhon Martin</h3>
                    </div>
                </div>
                <div className="testimonial-content">
                    <blockquote className="testimonial-text">I have been working with HireCo, for the past month. This was my first experience working
                        with a recruiter and it has been amazing. I couldn’t have asked for more. She took into account my CV and experience and
                        recommended.</blockquote>
                </div>
            </div>{/* testimonials end */}
        </div>
    },
    {
        id: 4, description: <div className="col-lg-12">
            {/* testimonials */}
            <div className="testimonials ttm-testimonial-box-view-style2" role="group">
                <div className="testimonial-top">
                    <div className="testimonial-avatar">
                        <div className="testimonial-img">
                            <img className="img-fluid" src="https://via.placeholder.com/150x150?text=150x150+01.jpg" alt="testimonial-img" />
                        </div>
                    </div>
                    <div className="testimonial-caption">
                        <label>Manager</label>
                        <h3>Alex Jhon Martin</h3>
                    </div>
                </div>
                <div className="testimonial-content">
                    <blockquote className="testimonial-text">HireCo has been the ultimate recruitment professional throughout the entire
                        process. It has kept to his word regarding communicating with me and with all other commitments made. I have found
                        them be extremely helpful.</blockquote>
                </div>
            </div>{/* testimonials end */}
        </div>
    },
    {
        id: 5, description: <div className="col-lg-12">
            {/* testimonials */}
            <div className="testimonials ttm-testimonial-box-view-style2" role="group">
                <div className="testimonial-top">
                    <div className="testimonial-avatar">
                        <div className="testimonial-img">
                            <img className="img-fluid" src="https://via.placeholder.com/150x150?text=150x150+02.jpg" alt="testimonial-img" />
                        </div>
                    </div>
                    <div className="testimonial-caption">
                        <label>Manager</label>
                        <h3>Alex Jhon Martin</h3>
                    </div>
                </div>
                <div className="testimonial-content">
                    <blockquote className="testimonial-text">I can't thank HireCo Recruitment enough for helping me secure my current role.
                        Very professional & is very good at determining where a person's skill set is best served. Whenever I had the pleasure
                        of speaking them always!</blockquote>
                </div>
            </div>{/* testimonials end */}
        </div>
    }
];


export class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ipurl: APIURL,
            photoIndex: 0,
            isOpen: false,
            currentSlide: 0,
            jobfunction: [],
            joblocation_array: [],
            common_array_duplicate: [],
            common_array: '',
            recent_array_duplicate: [],
            closeicon_hidden: true,
            show_hidden: true,
            search_array_hidden: true,
            profile_location: [],
            profile_taluk: [],
            profile_jobfunction: [],
            profile_jobrole: [],
            isSearchclick: false,
            filterprofile: [],
            jobalertdays: '',
            location_val: '',
            jobrole_val: '',
            jobfunction_val: '',
            jobfunctioncodeval: [],
            locationcodeval: [],
            jobrolecodeval: [],
            jobfunctionresult: false,
            jobroleresult: false,
            locationresult: false,
            skip: 0,
            limit: 10,
            rowData: [],
            jobalertdays: 0,
        };
    }

    componentDidMount() {
        try {
            this.jobfunctionlist()
            this.joblocationlist()
            this.searchprofile_load()
            this.job_list()
            let empcode = localStorage.getItem('employeecode');
            this.setState({employeecode : this.state.employeecode = empcode});
            // alert("44444444")
            localStorage.getItem('ipaddress')
            this.setState({ipaddress : this.state. ipaddress = localStorage.getItem('ipaddress')});
                stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
                this.setState({languagecode : this.state.languagecode = localStorage.getItem('languagecode')})
        }
        catch (error) {

        }
    }
    
    indian_standard_amount(amt) {
        try {

            var x = parseInt(amt);
            x = x.toString();
            var lastThree = x.substring(x.length - 3);
            var otherNumbers = x.substring(0, x.length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

            return res;
        }
        catch (error) {
        }
    }

    handleAfterChange = (index) => {
        // alert("handleAfterChange "+ index)
        console.log("after change", index);
        this.setState({
            currentSlide: index
        });
    };

    GetSortOrder(prop) {
        try {
            return function (a, b) {
                if (a[prop] < b[prop]) {
                    return 1;
                } else if (a[prop] < b[prop]) {
                    return -1;
                }
                return 0;
            }
        } catch (error) {
            error_log.ERRORLOG(this.state.employeecode, this.state.version, "Home", "GetSortOrder", 0, error.toString());
        }
    }
    //job function list
    jobfunctionlist() {
        var accessToken =  localStorage.getItem('accessToken');

        window.fbq('track', 'Website', { fb_ad_function: "jobfunctionlist", fb_ad_type: "Home", fb_ad_loginuser: COMMON.employee_name });
        axios({
            method: 'Post',
            url: this.state.ipurl + 'jobs/jobscount',
            crossdomain: true,
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
              },
            params: {
                employeecode: this.state.employeecode,
                deviceip: this.state.deviceip,
                languagecode: this.state.languagecode,
                type: 4
            },
        })
            .then(async response => {
                if(response.data.status == 401)
                {
                 await generateRefreshToken().then((result)=>{
                    if(result == 1)
                    {
                      this.jobfunctionlist();
                    }
                    else{
                      // alert('Unauthorized')
                    }
                  })
             
                }
                else{
                // alert(JSON.stringify(response))
                if (response.data.job_list_count_json_result.varstatuscode === 4) {
                    let jobarray = response.data.job_list_count_json_result.job_list_count;
                    // mjfun_rowdata == this.state.dpjobfunction
                    // JSON.stringify(this.state.jobfunction);
                    jobarray.sort((a, b) => b.jobcount - a.jobcount)
                    this.setState({ jobfunction: jobarray });
                }
            }
            })
            .catch(error => {
                //   this.setState({ isSmallLoaderJobFunctionActive: this.state.isSmallLoaderJobFunctionActive = false })
                //   error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
            });
    }
    //job function 
    bind_jobfunction() {
        if (this.state.jobfunction && this.state.jobfunction.length > 0) {
            return this.state.jobfunction.map((jfun, index) => {
                const { jobfunctioncode, status, jobfunctionname, jobcount, imageurl, flashjobcount } = jfun

                if (jobcount > 0) {
                    return (
                        <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* finance-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundColor: '#E8E8E8' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* finance-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src={imageurl} />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3 style={{ minHeight: '40px' }}>{jobfunctionname}</h3>
                                        <h3 className="jobcount">{jobcount+flashjobcount}</h3>

                                    </div>
                                    {/* <div className="featured-desc">
                                        <p style={{fontSize:'16px'}}>{jobcount}</p>
                                    </div> */}
                                    <a className="btnapply" href={'/job_list?jobfunctioncodeval=' + jobfunctioncode + '&jobnav=' + 1}>Apply Now!</a>
                                    {/* <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" href="">Apply Now</a> */}
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                    )
                }

            })
        }
    }
    //job loction
    joblocationlist() {
        var accessToken =  localStorage.getItem('accessToken');
        window.fbq('track', 'Website', { fb_ad_function: "joblocationlist", fb_ad_type: "Home", fb_ad_loginuser: COMMON.employee_name });
        axios({
            method: 'Post',
            url: this.state.ipurl + 'jobs/jobscount',
            crossdomain: true,
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
              },

            params: {
                employeecode:this.state.employeecode,
                deviceip: this.state.deviceip,
                languagecode: this.state.languagecode,
                type: 2
            },
        })
            .then(async response => {
                if(response.data.status == 401)
                {
                 await generateRefreshToken().then((result)=>{
                    if(result == 1)
                    {
                      this.joblocationlist();
                    }
                    else{
                      // alert('Unauthorized')
                    }
                  })
             
                }
                else{
                if (response.data.job_list_count_json_result.varstatuscode === 4) {
                    this.state.joblocation_array = response.data.job_list_count_json_result.job_list_count.sort(function (a, b) { return b.jobcount - a.jobcount });// WITH SORT BY jobcount DESCENDING
                    // this.state.joblocation_array = response.data.job_list_count_json_result.job_list_count;// WITHOUT SORT
                    // mjfun_rowdata == this.state.dpjobfunction
                    JSON.stringify(this.state.joblocation_array);
                    this.setState({ joblocation_array: this.state.joblocation_array });
                    // for (let i=0;i<4;i++){
                    //     this.state.joblocation_array.push(this.state.joblocation_array[i])
                    // }

                }
            }
            })
            .catch(error => {
                //   this.setState({ isSmallLoaderJobFunctionActive: this.state.isSmallLoaderJobFunctionActive = false })
                //   error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
            });
    }
    bind_joblocation() {
        if (this.state.joblocation_array && this.state.joblocation_array.length > 0) {
            return this.state.joblocation_array.map((jloction, index) => {
                const { districtcode, statecode, districtname, jobcount, flashjobcount, imageurl } = jloction
                console.log(imageurl)
                if (jobcount > 0) {
                    return (
                        // <a href={'/job_list?locationcodeval=' + districtcode + '&jobnav=' + 1} className="locationcls col-lg-3 col-md-6 col-sm-6" key={index}>
                        //     <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">
                        //         <div className="featured-icon">
                        //             <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                        //                 <i className="ti ti-location-pin"></i>
                        //             </div>
                        //         </div>
                        //         <div className="featured-content" style={{ width: '70%' }} >
                        //             <div className="featured-title">
                        //                 <h3>{districtname}</h3>
                        //                 <h3 style={{ color: '#fb5e71' }}>{jobcount}</h3>
                        //             </div>
                        //         </div>
                        //     </div>
                        // </a>
                        <a href={'/job_list?locationcodeval=' + districtcode + '&jobnav=' + 1} className="col-lg-4 col-md-6 col-sm-6" key={index}>
                            {/* featured-imagebox-portfolio */}
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* ttm-box-view-overlay */}
                                <div className="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">

                                        {imageurl != '' && imageurl != undefined && imageurl != null ?
                                            <img className="img-fluid" src={imageurl} alt="image" />
                                            :
                                            <img className="img-fluid" src="https://via.placeholder.com/680x440?text=680x440+portfolio-01.jpg" alt="image" />
                                        }

                                    </div>
                                    {/* featured-thumbnail end */}
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={'/'}>{districtname}</a></h3>
                                    </div>
                                    <div className="featured-desc locjob_count">
                                        <p><b style={{ fontSize: '23px' }}>{jobcount+flashjobcount}</b> Jobs</p>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox-portfolio end */}
                        </a>
                    )
                }

            })
        }
    }
    jobfun_navigation(jobfunctioncode) {
        localStorage.setItem('jobfunctioncode', jobfunctioncode);
        localStorage.setItem('jobnavication', 'jobfunction');
    }
    navigatejoblocation(districtcode) {
        localStorage.setItem('districtcode', districtcode);
        localStorage.setItem('jobnavication', 'joblocation');
        history.push('/job_list')
        window.location.reload();
    }

    //search load
    searchprofile_load() {
        var accessToken =  localStorage.getItem('accessToken');
        window.fbq('track', 'Website', { fb_ad_function: "searchprofile_load", fb_ad_type: "Home", fb_ad_loginuser: COMMON.employee_name });
        this.setState({ isActive: this.state.isActive = true })
        axios({
            method: 'Post',
            url: this.state.ipurl + 'search/load/',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
              },
            params: {
                languagecode: this.state.languagecode,
                employeecode: this.state.employeecode,
                ipaddress: this.state.ipaddress,
            }
        })
            .then(async response => {
                if(response.data.status == 401)
                {
                 await generateRefreshToken().then((result)=>{
                    if(result == 1)
                    {
                      this.searchprofile_load();
                    }
                    else{
                      // alert('Unauthorized')
                    }
                  })
             
                }
                else{
                this.setState({ isActive: this.state.isActive = false })
                if (response.data.search_json_result.varstatuscode === 4) {

                    //load location
                    var load_location_response = response.data.search_json_result.locationlist;
                    var dpbind_loadlocation = load_location_response.map(load_location_response => {
                        return { value: load_location_response.districtcode, label: load_location_response.districtname }
                    })
                    JSON.stringify(dpbind_loadlocation)
                    this.setState({ dpbind_loadlocation });
                    //load jobfunction
                    var load_jobfunction_response = response.data.search_json_result.jobfunctionlist;
                    var dpbind_loadjobfunction = load_jobfunction_response.map(load_jobfunction_response => {
                        return { value: load_jobfunction_response.jobfunctioncode, label: load_jobfunction_response.jobfunctionname }
                    })
                    JSON.stringify(dpbind_loadjobfunction)
                    this.setState({ dpbind_loadjobfunction });
                    //load jobfunction
                    var load_jobrole_response = response.data.search_json_result.jobrolelist;
                    var dpbind_loadjobrole = load_jobrole_response.map(load_jobrole_response => {
                        return { value: load_jobrole_response.jobrolecode, label: load_jobrole_response.jobrolename }
                    })
                    JSON.stringify(dpbind_loadjobrole)
                    this.setState({ dpbind_loadjobrole });
                    this.setState({ locationlist: this.state.locationlist = response.data.search_json_result.hasOwnProperty('locationlist') ? response.data.search_json_result.locationlist : [] })
                    this.setState({ jobrolelist: this.state.jobrolelist = response.data.search_json_result.hasOwnProperty('jobrolelist') ? response.data.search_json_result.jobrolelist : [] })
                    this.setState({ jobfunctionlist: this.state.jobfunctionlist = response.data.search_json_result.hasOwnProperty('jobfunctionlist') ? response.data.search_json_result.jobfunctionlist : [] })
                    this.setState({ recentsearch: this.state.recentsearch = response.data.search_json_result.hasOwnProperty('recentsearch') ? response.data.search_json_result.recentsearch : [] })
                    let common_list = [], final_list = [];
                    for (let i = 0; i < this.state.recentsearch.recentlocationlist.length; i++) {
                        let filter = common_list.some(e => e.label === this.state.recentsearch.recentlocationlist[i].locationname && e.value === this.state.recentsearch.recentlocationlist[i].locationcode);
                        if (!filter) {
                            final_list.push({ 'name': this.state.recentsearch.recentlocationlist[i].locationname, "languagecode": this.state.recentsearch.recentlocationlist[i].languagecode, 'id': this.state.recentsearch.recentlocationlist[i].locationcode, 'status': 1, 'status_name': 'Location', 'searchtype': 1, hover: false });
                        }
                    }
                    for (let i = 0; i < this.state.recentsearch.recentjobfunctionlist.length; i++) {
                        let filter = common_list.some(e => e.label === this.state.recentsearch.recentjobfunctionlist[i].jobfunctionname && e.value === this.state.recentsearch.recentjobfunctionlist[i].jobfunctioncode);
                        if (!filter) {
                            final_list.push({ 'name': this.state.recentsearch.recentjobfunctionlist[i].jobfunctionname, 'languagecode': this.state.recentsearch.recentjobfunctionlist[i].languagecode, 'id': this.state.recentsearch.recentjobfunctionlist[i].jobfunctioncode, 'status': 2, 'status_name': 'Job Function', 'searchtype': 3, hover: false });
                        }
                    }
                    for (let i = 0; i < this.state.recentsearch.recentjobrolelist.length; i++) {
                        let filter = common_list.some(e => e.label === this.state.recentsearch.recentjobrolelist[i].jobrolename && e.value === this.state.recentsearch.recentjobrolelist[i].jobrolecode);
                        if (!filter) {
                            final_list.push({ 'name': this.state.recentsearch.recentjobrolelist[i].jobrolename, 'languagecode': this.state.recentsearch.recentjobrolelist[i].languagecode, 'id': this.state.recentsearch.recentjobrolelist[i].jobrolecode, 'status': 3, 'status_name': 'Job Role', 'searchtype': 2, hover: false });
                        }
                    }
                    for (let i = 0; i < this.state.locationlist.length; i++) {
                        let filter = common_list.some(e => e.label === this.state.locationlist[i].districtname && e.value === this.state.locationlist[i].districtcode);
                        if (!filter) {
                            common_list.push({ 'name': this.state.locationlist[i].districtname, 'languagecode': this.state.locationlist[i].languagecode, 'id': this.state.locationlist[i].districtcode, 'status': 2, 'status_name': 'Location', 'searchtype': 1, hover: false });
                        }
                    }
                    for (let i = 0; i < this.state.jobrolelist.length; i++) {
                        let filter = common_list.some(e => e.label === this.state.jobrolelist[i].jobrolename && e.value === this.state.jobrolelist[i].jobrolecode);
                        if (!filter) {
                            common_list.push({ 'name': this.state.jobrolelist[i].jobrolename, 'languagecode': this.state.jobrolelist[i].languagecode, 'id': this.state.jobrolelist[i].jobrolecode, 'status': 2, 'status_name': 'Job Role', 'searchtype': 2, hover: false });
                        }
                    }

                    for (let i = 0; i < this.state.jobfunctionlist.length; i++) {
                        let filter = common_list.some(e => e.label === this.state.jobfunctionlist[i].jobfunctionname && e.value === this.state.jobfunctionlist[i].jobfunctioncode);
                        if (!filter) {
                            common_list.push({ 'name': this.state.jobfunctionlist[i].jobfunctionname, 'languagecode': this.state.jobfunctionlist[i].languagecode, 'id': this.state.jobfunctionlist[i].jobfunctioncode, 'status': 2, 'status_name': 'Job Function', 'searchtype': 3, hover: false });
                        }
                    }
                    this.setState({ common_array: this.state.common_array = common_list });
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = common_list });
                    this.setState({ recent_array: this.state.recent_array = final_list });
                    this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = final_list });
                }
                else if (response.data.search_json_result.varstatuscode === 7) {
                    //   toast.warn(this.state.blocked_msg, {
                    //     autoClose: this.state.timeset
                    //   })
                    localStorage.clear()
                    setTimeout(() => {
                        history.push('/');
                    }, 2000);
                }
                else {
                    this.setState({ isActive: this.state.isActive = false })
                }
            }
            })
            .catch(error => {
                this.setState({ isActive: this.state.isActive = false })
                error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "searchprofile_load", 0, error.toString());
            });
    }

    recentSearchClick(item) {
        try {
            this.setState({ show_hidden: this.state.show_hidden = true })
            this.setState({ search_array_hidden: this.state.search_array_hidden = true })
            // this.setState({ search_val: this.state.search_val = item.name });
            // this.onclicksearch_btn();
            this.setState({ jobnav: this.state.jobnav = 1 })

            if (Object.keys(item).length > 0) {
                if (item.searchtype === 1) {
                    this.setState({ locationcodeval: this.state.locationcodeval = [] })
                    this.state.locationcodeval.push(item.id)
                    this.setState({
                        searchtype: this.state.searchtype = 1,
                        location_val: this.state.location_val = item.name
                    })
                }
                else if (item.searchtype === 2) {
                    this.setState({ jobrolecodeval: this.state.jobrolecodeval = [] })
                    this.state.jobrolecodeval.push(item.id)
                    this.setState({
                        searchtype: this.state.searchtype = 2,
                        jobrole_val: this.state.jobrole_val = item.name
                    })
                }
                else {
                    this.setState({ jobfunctioncodeval: this.state.jobfunctioncodeval = [] })
                    this.state.jobfunctioncodeval.push(item.id)
                    this.setState({
                        searchtype: this.state.searchtype = 3,
                        jobfunction_val: this.state.jobfunction_val = item.name
                    })
                }
            }
            // this.job_list()
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "recentSearchClick", 0, error.toString());
        }
    }

    getSearchValues(val, flag) {
        try {
            if (flag == 1) {
                this.setState({
                    location_val: this.state.location_val = val,
                    locationresult: this.state.locationresult = true,
                    jobroleresult: this.state.jobroleresult = false,
                    jobfunctionresult: this.state.jobfunctionresult = false
                })

            }
            else if (flag == 2) {
                this.setState({
                    jobrole_val: this.state.jobrole_val = val,
                    jobroleresult: this.state.jobroleresult = true,
                    locationresult: this.state.locationresult = false,
                    jobfunctionresult: this.state.jobfunctionresult = false
                })
            }
            else {
                this.setState({
                    jobfunction_val: this.state.jobfunction_val = val,
                    jobfunctionresult: this.state.jobfunctionresult = true,
                    jobroleresult: this.state.jobroleresult = false,
                    locationresult: this.state.locationresult = false
                })
            }
            if (this.state.languagecode == 2) {
                // this.setState({ search_val: this.state.search_val = val })

                if (val.length === 0) {
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = this.state.common_array })
                    this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = this.state.recent_array })
                    this.setState({ closeicon_hidden: this.state.closeicon_hidden = true })
                    this.setState({ show_hidden: this.state.show_hidden = true })
                    this.setState({ search_array_hidden: this.state.search_array_hidden = true })
                } else {
                    this.setState({ closeicon_hidden: this.state.closeicon_hidden = false })
                    this.setState({ show_hidden: this.state.show_hidden = false })
                    this.setState({ search_array_hidden: this.state.search_array_hidden = false })
                    let filteredData = this.state.common_array_duplicate.filter(x => String(x.name.toLowerCase()).includes(val.toLowerCase()) && x.searchtype == flag);
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = filteredData })
                    let recentfilteredData = this.state.recent_array_duplicate.filter(x => String(x.name.toLowerCase()).includes(val.toLowerCase()));
                    this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = recentfilteredData })
                }
            } else {
                let languagecode = Number(localStorage.getItem('languagecode'))

                // this.setState({ search_val: this.state.search_val = val })
                if (val.length === 0) {
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = this.state.common_array })
                    this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = this.state.recent_array })
                    this.setState({ closeicon_hidden: this.state.closeicon_hidden = true })
                    this.setState({ show_hidden: this.state.show_hidden = true })
                    this.setState({ search_array_hidden: this.state.search_array_hidden = true })
                } else {
                    this.setState({ closeicon_hidden: this.state.closeicon_hidden = false })
                    this.setState({ show_hidden: this.state.show_hidden = false })
                    this.setState({ search_array_hidden: this.state.search_array_hidden = false })
                    let filteredData = this.state.common_array.filter(x => String(x.name.toLowerCase()).includes(val.toLowerCase()) && x.searchtype == flag);
                    let arr_duplicate = [];
                    for (let i = 0; i < filteredData.length; i++) {
                        let res = this.state.common_array.filter(item => (item.id == filteredData[i].id && filteredData[i].code == item.code && item.languagecode == languagecode))
                        if (res == '' || res == null || res == undefined) {
                            arr_duplicate.push(filteredData[i])
                        }
                        else {
                            arr_duplicate.push(res[0])
                        }
                    }
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = arr_duplicate })
                    let recentfilteredData = this.state.recent_array.filter(x => String(x.name.toLowerCase()).includes(val.toLowerCase()) && x.searchtype == flag);
                    let arr1_duplicate = [];
                    for (let i = 0; i < recentfilteredData.length; i++) {
                        let res = this.state.recent_array.filter(item => (item.id == recentfilteredData[i].id && recentfilteredData[i].code == item.code && item.languagecode == languagecode))
                        if (res == '' || res == null || res == undefined) {
                            arr1_duplicate.push(recentfilteredData[i])
                        }
                        else {
                            arr1_duplicate.push(res[0])
                        }
                    }
                    this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = arr1_duplicate })
                }
            }

        } catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "getSearchValues", 0, error.toString());
        }
    }

    search_view() {
        try {
            if (this.state.common_array_duplicate && this.state.common_array_duplicate.length > 0) {
                return this.state.common_array_duplicate.map((recent, index) => {
                    const { name, status_name, hover } = recent
                    return (
                        <div key={index} >
                            <div md="11" style={{ marginLeft: "2%" }}>
                                <div
                                    onClick={() => this.recentSearchClick(recent)}
                                    onMouseOver={() => this.someHandler_one(recent)}
                                    onMouseLeave={() => this.someOtherHandler_one(recent)}
                                    style={{
                                        backgroundColor: 'white',
                                        ...(hover && { backgroundColor: '#efe9e9', }),
                                    }}
                                    className="notify-margin" >
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                    <span className="cursor-pointer" onClick={() => this.recentSearchClick(recent)} > {name}</span><span className="span_pointer"></span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "search_view", 0, error.toString());
        }

    }

    someHandler = (recent) => {
        try {
            recent.hover = true
            this.setState({ recent_array_duplicate: this.state.recent_array_duplicate })
            this.setState({ hover: this.state.hover = true })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }
    someOtherHandler = (recent) => {
        try {
            recent.hover = false
            this.setState({ recent_array_duplicate: this.state.recent_array_duplicate })
            this.setState({ hover: this.state.hover = false })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }
    someHandler_one = (recent) => {
        try {
            recent.hover = true
            this.setState({ common_array_duplicate: this.state.common_array_duplicate })
            this.setState({ hover: this.state.hover = true })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }

    someOtherHandler_one = (recent) => {
        try {
            recent.hover = false
            this.setState({ common_array_duplicate: this.state.common_array_duplicate })
            this.setState({ hover: this.state.hover = false })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }
    searchclear(flag) {
        if (flag == 1) {
            this.setState({
                location_val: this.state.location_val = '',
                locationcodeval: this.state.locationcodeval = []
            })
        }
        else if (flag == 2) {
            this.setState({
                jobrole_val: this.state.jobrole_val = '',
                jobrolecodeval: this.state.jobrolecodeval = []
            })
        }
        else {
            this.setState({
                jobfunction_val: this.state.jobfunction_val = '',
                jobfunctioncodeval: this.state.jobfunctioncodeval = []
            })
        }
    }
    //  job list
    job_list() {
        var accessToken =  localStorage.getItem('accessToken');

        window.fbq('track', 'Website', { fb_ad_function: "job_list", fb_ad_type: "Home", fb_ad_loginuser: COMMON.employee_name });
        try {
            axios({
                method: 'Post',
                url: this.state.ipurl + 'jobs/jobslist',
                crossdomain: true,
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                  },
                params: {
                    employeecode: this.state.employeecode,
                    ipaddress: this.state.ipaddress,
                    languagecode: this.state.languagecode,
                    type: 6,
                    skip: this.state.skip,
                    limit: this.state.limit
                },
                data: {
                    skillcode: [],
                    locationcode: [], jobfunctioncode: [], jobrolecode: [], jobtypecode: [], schoolqualcode: [], afterschoolcatecode: [], anydegree: "true", anyqualification: "true", anyspec: "true", afterschoolqualcode: [], afterschoolspeccode: [], experiencecode: 0, industrycode: [], employertypecode: [], companytypecode: [], maritalcode: [], gendercode: [], differentlyabled: -1, salaryfrom: 0, salaryto: 0, agefrom: 0, ageto: 0, anyage: "true"
                }
            })
                .then(async response => {
                    if(response.data.status == 401)
                    {
                     await generateRefreshToken().then((result)=>{
                        if(result == 1)
                        {
                          this.job_list();
                        }
                        else{
                          // alert('Unauthorized')
                        }
                      })
                 
                    }
                    else{
                    if (response.data.job_list_json_result.varstatuscode === 4) {
                        this.setState({ jobalertdays: this.state.jobalertdays = response.data.job_list_json_result.jobalertdays })
                        // let flash_job_list = []
                        let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                        let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list') ? response.data.job_list_json_result.job_list : []
                        this.setState({ flash_job_list: this.state.flash_job_list = flash_job_list });
                        for (let i = 0; i < recommended_list.length; i++) {
                            recommended_list[i].flash = 0;
                            if (recommended_list[i].hasOwnProperty('locationname'))
                                recommended_list[i].locationname.sort();
                        }
                        for (let i = 0; i < flash_job_list.length; i++) {
                            flash_job_list[i].flash = 1;
                            if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                flash_job_list[i].joblocationname.sort();
                        }
                        let arr = [];
                        let flashlist = flash_job_list;
                        if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                            for (let i = 0; i < recommended_list.length; i++) {
                                if (i != 0 && i % 4 == 0) {
                                    if (flashlist && flashlist.length > 0) {
                                        arr.push(flashlist[0]);
                                        flashlist.shift();
                                    }
                                    arr.push(recommended_list[i]);
                                } else {
                                    arr.push(recommended_list[i]);
                                }
                            }
                            if (flashlist && flashlist.length > 0) {
                                flashlist.forEach(element => {
                                    arr.push(element);
                                });
                            }
                        } else {
                            if ((recommended_list && recommended_list.length > 0)) {
                                arr = recommended_list;
                            }
                            if ((flash_job_list && flash_job_list.length > 0)) {
                                arr = flash_job_list;
                            }
                        }
                        this.setState({ recommended_list: this.state.recommended_list = arr });
                        let rowData = arr
                        JSON.stringify(rowData);
                        this.setState({ rowData: this.state.rowData = rowData });
                    }
                    else if (response.data.job_list_json_result.varstatuscode === 6) {
                        this.setState({
                            rowData: this.state.rowData = [],
                        });
                    }
                }
                })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "onclicksearch_btn", 0, error.toString());
        }

    }

    joblist = () => {
        try {
            return this.state.rowData.map((jobpost, index) => {
                const { employername, flash, companyname, statuscode, registeredname, contactdetai1ls, jobcode, jobfunctionname, jobid, viewedcount, dialedcount, pushedcount, experience, salaryrange, isbestsalary, joblocationname, jobrolename, imageurl, createddate, updateddate, profileurl, daysleft } = jobpost
                return (

                    <div className="col-lg-6 col-md-12" key={index}>
                        {/* featured-imagebox */}
                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                            <div className="featured-thumbnail">
                                {profileurl ?
                                    <img src={profileurl} />
                                    :
                                    <img src="images/joblist_logo.jpg" />
                                }
                                {/* <img src="images/joblist_logo.png" /> */}
                                {/* <img src={profileurl}/> */}
                                {/* {flash == 1 ?
                                    <div className="required-tag">Flash</div>
                                    :
                                    ''
                                } */}
                            </div>
                            <div className="featured-content">
                                <div className="job-title" style={{ height: '70px' }}>
                                    <div className="job-heading">
                                        {flash == 1 ?
                                            <h3><a href={'/Flashjobs_details?jobcode=' + jobcode}>{jobrolename}</a></h3>
                                            :
                                            <h3><a href={'/Job_details?jobcode=' + jobcode}>{jobrolename}</a></h3>
                                        }
                                    </div>
                                    <div className="job-time">
                                        {daysleft <= this.state.jobalertdays && daysleft != 0 ?
                                           <span style={{ backgroundColor:'transparent',color:'red',fontSize:'11px' }}>{daysleft} days left</span>
                                            : daysleft == 0 ?
                                                <span style={{ backgroundColor:'transparent',color:'red',fontSize:'11px' }}>Expiring Today</span>
                                                :
                                                <span style={{ backgroundColor:'transparent',color:'red',fontSize:'11px' }}>{daysleft} days left</span>
                                        }
                                    </div>
                                </div>
                                {/* <div className="featured-desc">
                                    <p>Published {daysleft} Days Ago.</p>
                                </div> */}
                                <div className="featured-details">
                                    <div className="job-meta">
                                        {flash == 1 ?
                                            <div>
                                                <span><i className="fa fa-building"></i>{companyname}</span><br />
                                            </div>
                                            :
                                            <div>
                                                <span><i className="fa fa-building"></i>{employername}</span><br />
                                            </div>
                                        }

                                        <span><i className="fa fa-map-marker-alt"></i>{joblocationname}</span><br />
                                        <span><span><i className="fa fa-briefcase"></i>{experience[0].isfresher == true || experience[0].isfresher == 'true' ?
                                            <span>Fresher</span>
                                            : ''}
                                            {experience[0].from >= 0 && experience[0].to > 0 ?
                                                <span>{experience[0].from} to {experience[0].to} Years</span>
                                                : ''
                                            }
                                            {experience[0].isfresher == "false" && experience[0].from == 0 && experience[0].to == 0 ?
                                                <span>-</span>
                                                : ''
                                            }</span></span><br />
                                        {isbestsalary == 1 ?
                                            <div>
                                                <span><i className="fas fa-rupee-sign"></i>Best Salary in the Industry</span>
                                            </div>
                                            :
                                            salaryrange.min != '' & salaryrange.max != '' ?
                                                <div>
                                                    <span><i className="fas fa-rupee-sign"></i>{this.indian_standard_amount(salaryrange.min)} to {this.indian_standard_amount(salaryrange.max)}</span>
                                                </div>
                                                :
                                                <div>
                                                    <span><i className="fas fa-rupee-sign"></i>-</span>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>{/* featured-imagebox end */}
                    </div >
                )

            })
        }
        catch (error) {
            // error_log.ERRORLOG(this.state.usercode, this.state.version, "FlashJobsList", "jobpost_list_view", 0, error.toString());
        }
    }
    render() {
        const { photoIndex, isOpen } = this.state;
        const settings = {
            beforeChange: function (currentSlide, nextSlide) {
                // alert("before change "+currentSlide + nextSlide)
                console.log("before change", currentSlide, nextSlide);
            },
            afterChange: this.handleAfterChange
        };
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{

                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };

        return (
            <div className="site-main">

                <Header />

                {/* Banner */}
                <Banner />
                {/* Banner end */}

                {/* Search  old*/}
                {/* <div className="serach_bar bg-theme-SkinColor pt-20">
                    <div className="container">
                        <div id="b_search_Form" className="b_search_Form wrap-form d-block">
                            <div className="row row-equal-height ttm-boxes-spacing-20px">
                                <div className="col-md">
                                    <label>
                                        <input type="text"
                                            name="txtjobfunction"
                                            value={this.state.jobfunction_val}
                                            onChange={(e) => this.getSearchValues(e.target.value, 3)}
                                            autoComplete="off"
                                            placeholder="Job Functions"
                                        />
                                        <i className="ti ti-close" style={{ marginLeft: '267px', fontSize: '13px' }} onClick={e => { this.searchclear(3) }}></i>
                                    </label>
                                    {this.state.jobfunctionresult == true ?
                                        <div className="cls-searchprofile cls_scroll_search">
                                            <div hidden={this.state.search_array_hidden}>
                                                <div style={{ marginTop: "10px" }}>
                                                    {this.state.common_array_duplicate && this.state.common_array_duplicate.length > 0 ?
                                                        <span className="cls_divresult">{'Suggestion Result'}</span> : ""}
                                                    {this.search_view()}
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                    }


                                </div>
                                <div className="col-md">
                                    <label>
                                        <input type="text"
                                            id="txtjobrole"
                                            name="txtjobrole"
                                            value={this.state.jobrole_val}
                                            onChange={(e) => this.getSearchValues(e.target.value, 2)}
                                            autoComplete="off"
                                            placeholder="Job Role" />
                                        <i className="ti ti-close" style={{ marginLeft: '267px', fontSize: '13px' }} onClick={e => { this.searchclear(2) }}></i>
                                    </label>
                                    {this.state.jobroleresult == true ?
                                        <div className="cls-searchprofile cls_scroll_search">
                                            <div hidden={this.state.search_array_hidden}>
                                                <div style={{ marginTop: "10px" }}>
                                                    {this.state.common_array_duplicate && this.state.common_array_duplicate.length > 0 ?
                                                        <span className="cls_divresult">{'Suggestion Result'}</span> : ""}
                                                    {this.search_view()}
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                </div>
                                <div className="col-md">
                                    <label>
                                        <input type="text"
                                            id="txtlocation"
                                            name="txtlocation"
                                            value={this.state.location_val}
                                            onChange={(e) => this.getSearchValues(e.target.value, 1)}
                                            autoComplete="off"
                                            placeholder="Location" />
                                        <i className="ti ti-close" style={{ marginLeft: '267px', fontSize: '13px' }} onClick={e => { this.searchclear(1) }}></i>
                                    </label>
                                    {this.state.locationresult == true ?
                                        <div className="cls-searchprofile cls_scroll_search">
                                            <div hidden={this.state.search_array_hidden}>
                                                <div style={{ marginTop: "10px" }}>
                                                    {this.state.common_array_duplicate && this.state.common_array_duplicate.length > 0 ?
                                                        <span className="cls_divresult">{'Suggestion Result'}</span> : ""}
                                                    {this.search_view()}
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                </div>
                                <div className="col-lg-2">
                                    <label style={{ display: 'inline-block' }}>
                                        <a href={'/job_list?jobfunctioncodeval=' + this.state.jobfunctioncodeval + '&jobfunctionname=' + this.state.jobfunction_val + '&jobrolecodeval=' + this.state.jobrolecodeval + '&jobrolename=' + this.state.jobrole_val + '&locationcodeval=' + this.state.locationcodeval + '&locationname=' + this.state.location_val + '&jobnav=' + 1 + '&searchflag=' + 2}>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                    ttm-btn-color-grey" >Find Job</button>
                                        </a>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}
                {/* search old end */}

                {/* form */}
                {/* <div className="form-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12"> */}
                                {/* <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg border rounded p-40 p-lg-20 mt_70 mt-lg-50">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <div id="search_Form" className="search_Form wrap-form">
                                            <label> */}
                                                {/* <i className="ti ti-search"></i> */}
                                                {/* <input type="text"
                                                    id="txtjobfunction"
                                                    className="input-serch"
                                                    name="txtjobfunction"
                                                    value={this.state.jobfunction_val}
                                                    onChange={(e) => this.getSearchValues(e.target.value, 3)} */}
                                                    {/* // onFocus={() => this.showRecentSearch()}
                                                    autoComplete="off"
                                                    placeholder="Job Function" />
                                                <i className="ti ti-close searchclear" onClick={e => { this.searchclear(3) }}></i>
                                                {this.state.jobfunctionresult == true ?
                                                    <div className="cls-searchprofile cls_scroll_search"> */}
                                                        {/* <div hidden={this.state.show_hidden}>
                                             <div style={{ marginTop: "10px" }}>
                                                 {this.state.recent_array_duplicate && this.state.recent_array_duplicate.length > 0 ?
                                                     <span className="cls_divresult">{'Recent Search'}</span> : ""}
                                                 {this.recent_view()}
                                             </div>
                                         </div> */}
                                                        {/* <div hidden={this.state.search_array_hidden}>
                                                            <div style={{ marginTop: "10px" }}>
                                                                {this.state.common_array_duplicate && this.state.common_array_duplicate.length > 0 ?
                                                                    <span className="cls_divresult">{'Suggestion Result'}</span> : ""}
                                                                {this.search_view()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                            </label> */}

                                            {/* <label>
                                                <input type="text"
                                                    id="txtjobrole"
                                                    className="input-serch"
                                                    name="txtjobrole"
                                                    value={this.state.jobrole_val}
                                                    onChange={(e) => this.getSearchValues(e.target.value, 2)}
                                                    // onFocus={() => this.showRecentSearch()}
                                                    autoComplete="off"
                                                    placeholder="Job Role" />
                                                <i className="ti ti-close searchclear" onClick={e => { this.searchclear(2) }}></i>
                                                {this.state.jobroleresult == true ?
                                                    <div className="cls-searchprofile cls_scroll_search">
                                                        {/* <div hidden={this.state.show_hidden}>
                                                <div style={{ marginTop: "10px" }}>
                                                    {this.state.recent_array_duplicate && this.state.recent_array_duplicate.length > 0 ?
                                                        <span className="cls_divresult">{'Recent Search'}</span> : ""}
                                                    {this.recent_view()}
                                                </div>
                                            </div> */}
                                                        {/* <div hidden={this.state.search_array_hidden}>
                                                            <div style={{ marginTop: "10px" }}>
                                                                {this.state.common_array_duplicate && this.state.common_array_duplicate.length > 0 ?
                                                                    <span className="cls_divresult">{'Suggestion Result'}</span> : ""}
                                                                {this.search_view()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                            </label> */}

                                            {/* <label> */}
                                                {/* <i className="ti ti-location-pin"></i> */}
                                                {/* <input type="text"
                                                    id="txtlocation"
                                                    className="input-serch"
                                                    name="txtlocation"
                                                    value={this.state.location_val}
                                                    onChange={(e) => this.getSearchValues(e.target.value, 1)} */}
                                                    {/* onFocus={() => this.showRecentSearch()} */}
                                                    {/* autoComplete="off"
                                                    placeholder="Location" /> */}
                                                {/* <i className="ti ti-close searchclear" onClick={e => { this.searchclear(1) }}></i>
                                                {this.state.locationresult == true ?
                                                    <div className="cls-searchprofile cls_scroll_search"> */}
                                                        {/* <div hidden={this.state.show_hidden}>
                                                <div style={{ marginTop: "10px" }}>
                                                    {this.state.recent_array_duplicate && this.state.recent_array_duplicate.length > 0 ?
                                                        <span className="cls_divresult">{'Recent Search'}</span> : ""}
                                                    {this.recent_view()}
                                                </div>
                                            </div> */}
                                                        {/* <div hidden={this.state.search_array_hidden}>
                                                            <div style={{ marginTop: "10px" }}>
                                                                {this.state.common_array_duplicate && this.state.common_array_duplicate.length > 0 ?
                                                                    <span className="cls_divresult">{'Suggestion Result'}</span> : ""}
                                                                {this.search_view()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                            </label> */}

                                            {/* <div className="header_btn">
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                onClick={e => { this.loadMore() }}>Find Job</a>
                                        </div> */}
                                            {/* <a href={'/job_list?jobfunctioncodeval=' + this.state.jobfunctioncodeval + '&jobfunctionname=' + this.state.jobfunction_val + '&jobrolecodeval=' + this.state.jobrolecodeval + '&jobrolename=' + this.state.jobrole_val + '&locationcodeval=' + this.state.locationcodeval + '&locationname=' + this.state.location_val + '&jobnav=' + 1 + '&searchflag=' + 2} style={{ display: 'inline-flex' }}>
                                                <button className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor" onClick={e => { this.findclick() }}>Find Jobs</button>
                                            </a>
                                        </div> */}

                                    {/* </div>
                                </div> */}
                            {/* </div>
                        </div>
                    </div> */}
                {/* </div> */} 
                {/* form end */}

                {/* job-list-section */}
                {/* <section className="ttm-row job-list-section z-index-1 clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-11">
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        <h2 className="title">Jobs By Location</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-equal-height mb_10">
                            {this.bind_joblocation()}
                        </div>
                    </div>
                </section> */}

                <section className="ttm-row portfolio-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        <h2 className="title">Jobs By Location</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* row */}
                        <div className="row">
                            <Slider className="row slick_slider mb_10 pt-20 slick-arrows-style2" {...slick_slider} slidesToShow={3}
                                rows={2} arrows={true} responsive={[{ breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
                                {this.bind_joblocation()}
                                {/* <div className="col-lg-12">
                            <div className="pt-30 m-auto text-center">
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                href={'/job_list'}>More Jobs By Region</a>
                            </div>
                        </div> */}
                            </Slider>
                        </div>

                    </div>
                </section>
                {/* job-list end */}


                {/* Get job on */}
                <section className="ttm-row job-list-section z-index-1  bg-theme-GreyColor clearfix" style={{ padding: '50px' }}>
                    <div className="container">

                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        <h2 className="title">How to Get a Job @ JOBANYA</h2>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>{/* row end */}
                        {/* row */}

                        <div className="row row-equal-height mb_12">
                            <div className="col-lg-1 col-md-6 col-sm-6"></div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">

                                    <div className="featured-content-gj" >
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i class="fa fa-download"></i> */}
                                            <img className="img-fluid" src="images/icons/Download-img.png" />
                                        </div>
                                        <div className="featured-desc">
                                            <p>Download Jobanya App</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            <div className="col-lg-1 col-md-6 col-sm-6" style={{ justifyContent: 'center' }}><div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                <i className="ti-arrow-right desk-arrow" style={{ marginTop: '50px', fontSize: '30px' }}></i>
                                <i className="ti-arrow-down mob-arrow" style={{ marginTop: '50px', fontSize: '30px' }}></i>
                            </div></div>

                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">

                                    <div className="featured-content-gj">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="fa fa-check-square"></i> */}
                                            <img className="img-fluid" src="images/icons/percentage-img.png" />
                                        </div>
                                        <div className="featured-desc">
                                            <p>Complete the Profile 100%</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>

                            <div className="col-lg-1 col-md-6 col-sm-6" style={{ justifyContent: 'center' }}><div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                <i className="ti-arrow-right desk-arrow" style={{ marginTop: '50px', fontSize: '30px' }}></i>
                                <i className="ti-arrow-down mob-arrow" style={{ marginTop: '50px', fontSize: '30px' }}></i>
                            </div></div>

                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">

                                    <div className="featured-content-getjob" >
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="fas fa-phone-square-alt"></i> */}
                                            <img className="img-fluid" src="images/icons/submit-img.png" />
                                        </div>
                                        <div className="featured-desc">
                                            <p>Apply your Jobs</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>

                        </div>



                        {/* row end */}
                    </div>
                </section>

                {/* features-section */}
                <section className="ttm-row features-section clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        {/* <h3>Get <span className="text-theme-SkinColor">Appropriate</span></h3> */}
                                        <h2 className="title">Jobs By Category</h2>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>{/* row end */}
                        {/* row */}

                        <div className="row row-equal-height mb_10">
                            <Slider className="row slick_slider mb_10 pt-20 slick-arrows-style2" {...slick_slider} slidesToShow={4}
                                rows={2} arrows={true} responsive={[{ breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
                                {this.bind_jobfunction()}
                            </Slider>
                        </div>{/* row end */}

                    </div>
                </section>
                {/* features-section end */}

                {/* card start */}

                {/* <div class="col-lg-12 topcard_div">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title title-style-center_text" style={{ marginTop: '12px' }}>
                                <div className="title-header">
                                    <h4 className="title">INDIA'S NO.1 VERIFIED JOB PORTAL</h4>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6  col-12">
                            <div class="card mb-3" style={{ backgroundColor: '#4b3750', color: 'white' }}>
                                <div class="row g-0">
                                    <div class="col-5">
                                        <img src="images/Want_to_Hire.png" class="img-fluid rounded-start img-res" alt="..." />
                                    </div>
                                    <div class="col-7">
                                        <a href="https://play.google.com/store/apps/details?id=nithra.jobs.career.placement&hl=en_IN&referrer=utm_source%3DJOBS_WEBSITE_v2%26utm_campaign%3DJOBS_WEBSITE_v2"
                                            rel="noopener" target="_blank">
                                            <div class="card-body" style={{ marginTop: '23px', textAlign: 'center' }}>
                                                <h5 class="card-title fontsize-30" style={{ fontWeight: 'bold', color: 'white' }}>Want a Job?</h5>
                                                <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor topcard_btn" target="_blank" style={{ backgroundColor: '#2bb691', borderColor: '#2bb691' }} href="https://play.google.com/store/apps/details?id=com.best_jobs">
                                                    Download the App
                                                </a>
                                            </div>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6  col-12">
                            <div class="card mb-3" style={{ backgroundColor: '#4b3750', color: 'white' }}>
                                <div class="row g-0">
                                    <div class="col-5">
                                        <img src="images/Want_to_Hire.png" class="img-fluid rounded-start img-res" alt="..." />
                                    </div>
                                    <div class="col-7">
                                        <a href="https://play.google.com/store/apps/details?id=nithra.jobs.career.placement&hl=en_IN&referrer=utm_source%3DJOBS_WEBSITE_v2%26utm_campaign%3DJOBS_WEBSITE_v2"
                                            rel="noopener" target="_blank">
                                            <div class="card-body" style={{ marginTop: '23px', textAlign: 'center' }}>
                                                <h5 class="card-title fontsize-30" style={{ fontWeight: 'bold', color: 'white' }}>Want to Hire?</h5>
                                                <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" target="_blank" style={{ backgroundColor: '#2bb691', borderColor: '#2bb691' }} href="https://employer.jobanya.com/">
                                                    Post Jobs for Free
                                                </a>
                                            </div>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* </div> */}

                {/* card end */}


                {/* features-section */}
                {/* <section className="ttm-row features-section clearfix" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                               
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        <h4 className="title">INDIA'S VERIFIED JOB PORTAL</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row row-equal-height mb_12" style={{ justifyContent: 'center' }}>


                            <div className=" col-lg-5 col-md-6 col-sm-6" style={{ backgroundColor: '#4b3750', borderRadius: '6px' }}>

                                <div className="col-lg-6 col-md-3 col-sm-6">
                                    <div>
                                        <img className="img-fluid" src="images/Want_a_job.png" />
                                    </div>
                                </div>
                                <div className="topcard col-lg-6col-md-3 col-sm-6">
                                    <h3>Want a Job?</h3>
                                    <div className="header_btn">
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" target="_blank" style={{ backgroundColor: '#2bb691', borderColor: '#2bb691' }}
                                            href="https://play.google.com/store/apps/details?id=com.best_jobs">Download the App</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-1 col-md-6 col-sm-6" ></div>


                            <div className=" col-lg-5 col-md-6 col-sm-6" style={{ backgroundColor: '#4b3750', borderRadius: '6px' }}>

                                <div className="col-lg-6 col-md-3 col-sm-6">
                                    <div>
                                        <img className="img-fluid" src="images/Want_to_Hire.png" />
                                    </div>
                                </div>
                                <div className="topcard col-lg-6col-md-3 col-sm-6">
                                    <h3>Want to Hire?</h3>
                                    <div className="header_btn">
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" target="_blank" style={{ backgroundColor: '#2bb691', borderColor: '#2bb691' }}
                                            href="https://employer.jobanya.com/">Post Jobs for Free</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </section> */}
                {/* features-section end */}

                {/* job category list */}

                {/* <section className="features-section bg-theme-GreyColor clearfix">
                    <div className="container">

                        <div className="row row-equal-height mb_10">
                            <a className="col-lg-2 col-md-6 col-sm-6" href={'/job_list?jobtype=' + 1}>
                               
                                <div className="featured-icon-box icon-align-top-content style6">
                                    <div className="ttm-box-view-overlay">
                                      
                                        <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundImage: 'url(https://via.placeholder.com/600x600?text=600x600+finance-hover.png)' }}></div>
                                            <div className="layer-content"></div>
                                        </div>
                                    </div>
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                            <img className="img-fluid" src="images/jobs-logo/private-jobs.png" />
                                        </div>
                                    </div>
                                    <div className="featured-content-jobcategory">
                                        <div className="featured-title">
                                            <h3>Private Jobs</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a className="col-lg-2 col-md-6 col-sm-6" href={'/gnjoblist?jobtype=' + 2}>
                               
                                <div className="featured-icon-box icon-align-top-content style6">
                                    <div className="ttm-box-view-overlay">

                                    </div>
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                            <img className="img-fluid" src="images/jobs-logo/stategovt.png" />
                                        </div>
                                    </div>
                                    <div className="featured-content-jobcategory">
                                        <div className="featured-title">
                                            <h3>State Government Jobs</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a className="col-lg-2 col-md-6 col-sm-6" href={'/gnjoblist?jobtype=' + 3}>
                              
                                <div className="featured-icon-box icon-align-top-content style6">
                                    <div className="ttm-box-view-overlay">
                                    </div>
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                            <img className="img-fluid" src="images/jobs-logo/centralgovt.png" />
                                        </div>
                                    </div>
                                    <div className="featured-content-jobcategory">
                                        <div className="featured-title">
                                            <h3>Central Government Jobs</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a className="col-lg-2 col-md-6 col-sm-6" href={'/job_list?jobtype=' + 4}>
                               
                                <div className="featured-icon-box icon-align-top-content style6">
                                    <div className="ttm-box-view-overlay">
                                    </div>
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                            <img className="img-fluid" src="images/jobs-logo/freshers-jobs.png" />
                                        </div>
                                    </div>
                                    <div className="featured-content-jobcategory">
                                        <div className="featured-title">
                                            <h3>Fresher Jobs</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a className="col-lg-2 col-md-6 col-sm-6" href={'/job_list?jobtype=' + 5}>
                              
                                <div className="featured-icon-box icon-align-top-content style6">
                                    <div className="ttm-box-view-overlay">
                                        
                                        <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundImage: 'url(https://via.placeholder.com/600x600?text=600x600+manufacturing-hover.png)' }}></div>
                                            <div className="layer-content"></div>
                                        </div>
                                    </div>
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                            <img className="img-fluid" src="images/jobs-logo/all-jobs.png" />
                                        </div>
                                    </div>
                                    <div className="featured-content-jobcategory">
                                        <div className="featured-title">
                                            <h3>Flash Jobs</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                               
                                <div className="featured-icon-box icon-align-top-content style6">
                                    <div className="ttm-box-view-overlay">
                                     
                                        <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundImage: 'url(https://via.placeholder.com/600x600?text=600x600+production-hover.png)' }}></div>
                                            <div className="layer-content"></div>
                                        </div>
                                    </div>
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                            <img className="img-fluid" src="images/jobs-logo/jobanya-logo.png" />
                                        </div>
                                    </div>
                                    <div className="featured-content-jobcategory">
                                        <div className="featured-title">
                                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.best_jobs"><h3>Download Now</h3></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* job category list end*/}


                {/* client slider  start */}
                <section className="ttm-row services-section bg-img3 clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        {/* <h3>We Serve <span className="text-theme-SkinColor">Overseas</span></h3> */}
                                        <h2 className="title">Loved by 5,000+ MSME's, SMB's & Startups</h2>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>{/* row end */}
                        {/* Slider */}
                        <Slider className="row slick_slider mb_10 slick-arrows-style1" {...slick_slider} slidesToShow={4} arrows={true}>
                            <div className="col-md-12">
                                {/* featured-imagebox */}
                                <div className="">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail" style={{ overflow: 'visible', marginLeft: '110px' }}>
                                        <img src="images/client_logo/annamalai.png" />
                                    </div>{/* featured-thumbnail end */}

                                </div>{/* featured-imagebox end */}
                            </div>
                            <div className="col-md-12">
                                {/* featured-imagebox */}
                                <div className="">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail" style={{ overflow: 'visible', marginLeft: '110px' }}>
                                        <img src="images/client_logo/ayyan.png" />
                                    </div>{/* featured-thumbnail end */}
                                </div>{/* featured-imagebox end */}
                            </div>
                            <div className="col-md-12">
                                {/* featured-imagebox */}
                                <div className="">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail" style={{ overflow: 'visible', marginLeft: '110px' }}>
                                        <img src="images/client_logo/oror.png" />
                                    </div>{/* featured-thumbnail end */}

                                </div>{/* featured-imagebox end */}
                            </div>
                            <div className="col-md-12">
                                {/* featured-imagebox */}
                                <div className="">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail" style={{ overflow: 'visible', marginLeft: '110px' }}>
                                        <img src="images/client_logo/vivek.png" />
                                    </div>{/* featured-thumbnail end */}

                                </div>{/* featured-imagebox end */}
                            </div>

                        </Slider>
                        {/* Slider end */}
                    </div>
                </section>

                {/* client slider end */}

                {/* about-section */}
                <section className="ttm-row about-section bg-img5 ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix" style={{ backgroundImage: '#d1d1d1'}}>
                    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer appdownload-bg"></div>
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-6">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h2 className="title appdownload-font">To Get A Job From Jobanya </h2>
                                    </div>
                                    <div className="title-desc appdownload-font">
                                        <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                            <li>
                                                <div className="ttm-list-li-content">Download The App</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Register Your Information 100%</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Apply For A Job</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Turn On Your Notification</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>{/* section title end */}
                                <div className="d-sm-flex social-btns">


                                    <a className="" href="https://play.google.com/store/apps/details?id=com.best_jobs" target="_blank" style={{ backgroundColor: '#f6f6f6' }}>
                                        <img className="img-fluid" src="images/playstore-logo.png" alt="single_04" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-10 mx-auto">
                                <div className="ttm_single_image-wrapper mt_20 p-15 position-absolute mb_lg-200 d-lg-block d-none">
                                    <img className="img-fluid" src="images/Mobile.png" alt="single_04" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* about-section end */}

                {/* testimonial-section */}
                <section className="ttm-row testimonial-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        {/* <h3>Our <span className="text-theme-SkinColor">Clients!</span></h3> */}
                                        <h2 className="title">Voice of <span className="text-theme-SkinColor title">Customer</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} slidesToShow={3} arrows={true}>
                            <div className="col-lg-12">
                                {/* testimonials */}
                                <div className="testimonials ttm-testimonial-box-view-style1">
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-text">The right App to recruit personnel for our industry. Has opened up good possibilities for recruitment from far and near.</blockquote>
                                        <div className="star-ratings">
                                            <ul className="rating">
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="testimonial-bottom">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-fluid" src="images/ayyan_abiruben.jpg" alt="testimonial-img" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Managing Director - Ayyan Fireworks</label>
                                            <h3>G.Abiruben</h3>
                                        </div>
                                    </div>
                                </div> {/* testimonials end */}
                            </div>
                            <div className="col-lg-12">
                                {/* testimonials */}
                                <div className="testimonials ttm-testimonial-box-view-style1">
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-text">This job platform helps both employers and employees to find the right recruitment. Easy and user friendly interface even a novice person can get benefited.</blockquote>
                                        <div className="star-ratings">
                                            <ul className="rating">
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="testimonial-bottom">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-fluid" src="images/vivek_veivekhonda.jpg" alt="testimonial-img" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Managing Director - Viveks Honda</label>
                                            <h3>E.J.Vivekram</h3>
                                        </div>
                                    </div>
                                </div> {/* testimonials end */}
                            </div>
                            <div className="col-lg-12">
                                {/* testimonials */}
                                <div className="testimonials ttm-testimonial-box-view-style1">
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-text">A very good internet job App and portal that could screen for the prospective candidates and fill up their vacancies.</blockquote>
                                        <div className="star-ratings">
                                            <ul className="rating">
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="testimonial-bottom">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-fluid" src="images/vishnuram.jpg" alt="testimonial-img" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Partner - Annamalai Agencies</label>
                                            <h3>A.C.Vishnu Ram</h3>
                                        </div>
                                    </div>
                                </div> {/* testimonials end */}
                            </div>
                            <div className="col-lg-12">
                                {/* testimonials */}
                                <div className="testimonials ttm-testimonial-box-view-style1">
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-text">It is a good platform to recruit people for SMEs and Industries. Keep up the good work and keep adding more and more job profiles so that many more users benefit from the same. All the best!</blockquote>
                                        <div className="star-ratings">
                                            <ul className="rating">
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                                <li className="active"><i className="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="testimonial-bottom">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-fluid" src="images/sridhar.jpg" alt="testimonial-img" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Managing Director - Oror Flavours & Chemicals Pvt. Ltd</label>
                                            <h3>S.R.Sridhar</h3>
                                        </div>
                                    </div>
                                </div> {/* testimonials end */}
                            </div>
                        </Slider>
                    </div>
                </section>
                {/* testimonial-section end */}

                {/* job-list-section */}
                <section className="ttm-row job-list-section bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-11">
                                {/* section title */}
                                <div className="section-title style2">
                                    <div className="title-header">
                                        <h3>Find The <span className="text-theme-SkinColor">Best One!</span></h3>
                                        <h2 className="title">Recent Job Listings</h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>We have several jobs in active right now. Check out <span className="text-theme-SkinColor">recent jobs </span>
                                            according to your excellence. Our experts will guide you accordingly. </p>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>{/* row end */}
                        {/* Slider */}
                        <Slider className="row slick_slider mb_10 pt-20 slick-arrows-style2" {...slick_slider} slidesToShow={2}
                            rows={2} arrows={true} responsive={[{ breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
                            {this.joblist()}
                        </Slider>{/* row end */}
                    </div>
                </section>
                {/* job-list end */}

                {/* blog-section */}

                {/* blog-section end */}

                <Footer />

            </div>
        )
    }
}


export default Home;
