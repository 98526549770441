import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import axios from "axios";
import Pagination from "react-js-pagination";
import ReactPaginate from 'react-paginate';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import * as Moment from 'moment'
import { APIURL, COMMON } from '../config'
import { history } from "../history"
let error_log = require('../error_log');
export class Gn_Job_list extends Component {

    constructor() {
        super();
        this.state = {
            logourl: '',
            employeecode: -1,
            gnorganisationcode: 0,
            gnorganisationname: '',
            name: "React",
            ipurl: APIURL,
            rowData: [],
            dpjobfunction: [],
            jobrole_full_list: [],
            currentItems: [],
            pageCount: 0,
            itemOffset: 0,
            itemsPerPage: 10,
            activePage: 15,
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }

    componentDidMount() {
        try {
            let query = new URLSearchParams(history.location.search)
            let gnorganisationcode = (query.get('gnorganisationcode'))
            let gnorganisationname = (query.get('gnorganisationname'))
            let empcode = localStorage.getItem('employeecode');
            this.setState({employeecode : this.state.employeecode = empcode});
            // alert("44444444")
            localStorage.getItem('ipaddress')
            this.setState({ipaddress : this.state. ipaddress = localStorage.getItem('ipaddress')});
                stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
                this.setState({languagecode : this.state.languagecode = localStorage.getItem('languagecode')})
            if (gnorganisationcode != '') {
                this.setState({ gnorganisationcode: this.state.gnorganisationcode = gnorganisationcode })
            }
            if (gnorganisationname != '') {
                this.setState({ gnorganisationname: this.state.gnorganisationname = gnorganisationname })
            }
            this.job_list();
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "Gn_Job_list", "componentDidMount", 0, error.toString());
        }
    }
    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
    }


    job_list() {
        window.fbq('track', 'Website', { fb_ad_function: "job_list", fb_ad_type: "Gn Job List", fb_ad_loginuser: COMMON.employee_name });
        try {
            axios({
                method: 'Post',
                // url: this.state.ipurl + 'gnorganisation/govtjobslist',
                url: 'https://api.jobanya.com/api/controller/' + 'gnorganisation/govtjobslist',
                crossdomain: true,

                params: {
                    // employeecode: this.state.employeecode,
                    employeecode: this.state.employeecode,
                    ipaddress: this.state.ipaddress,
                    languagecode: this.state.languagecode,
                    gnorganisationcode: this.state.gnorganisationcode,
                },
                data: {
                    skillcode: [],
                    locationcode: [], jobfunctioncode: [], jobrolecode: [], jobtypecode: [], schoolqualcode: [], afterschoolcatecode: [], anydegree: "true", anyqualification: "true", anyspec: "true", afterschoolqualcode: [], afterschoolspeccode: [], experiencecode: 0, industrycode: [], employertypecode: [], companytypecode: [], maritalcode: [], gendercode: [], differentlyabled: -1, salaryfrom: 0, salaryto: 0, agefrom: 0, ageto: 0, anyage: "true"
                }
            })
                .then(response => {
                    if (response.data.gnOrganisation_list_json_result.varstatuscode === 4) {
                        let rowData = response.data.gnOrganisation_list_json_result.govtjobs;
                        JSON.stringify(rowData);
                        this.setState({ rowData: this.state.rowData = rowData });
                        // alert(JSON.stringify(this.state.rowData))
                        this.page(this.state.itemOffset, this.state.itemsPerPage)
                    }

                })
                .catch(error => {
                    error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "Gn_Job_list", "job_list", 0, error.toString());
                });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "Gn_Job_list", "job_list", 0, error.toString());
        }


    }


    loadMore() {
        this.setState({
            // itemOffset: this.state.itemOffset = this.state.itemsPerPage,
            limit: this.state.limit = this.state.limit + 5
        })
        this.job_list()
        // this.page(this.state.itemOffset, this.state.itemsPerPage)
    }
    //Bind job role 

    GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }



    onChangeValue(event) {
        console.log(event.target.value);
    }



    bind_pageslist() {
        try {
            // this.state.jobfunctionarray =[...mjfun_rowdata];
            let pages = [1, 2, 3, 4, 5, 6, 7]
            if (pages && pages.length > 0) {
                return pages.map((page, index) => {
                    return (
                        index > 2 ?
                            <a className="page-nav-link">...</a>

                            :
                            <a className="page-nav-link ">{page}</a>


                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_jobfunction", 0, error.toString());
        }

    }
    page(offset, itemsPerPage) {
        this.setState({ itemOffset: this.state.itemOffset = offset })
        var endOffset = this.state.itemOffset + itemsPerPage;
        var count = this.state.rowData.slice(this.state.itemOffset, endOffset)
        this.setState({ currentItems: this.state.currentItems = count })
        // alert(JSON.stringify(this.state.currentItems))
        this.setState({ pageCount: this.state.pageCount = Math.ceil(this.state.rowData.length / itemsPerPage) })
    }

    handlePageClick = (event) => {
        var newOffset = (event.selected * this.state.itemsPerPage) % this.state.rowData.length;
        this.setState({ itemOffset: this.state.itemOffset = newOffset })
        this.page(newOffset, this.state.itemsPerPage)
    }

    navigategnjob(item) {
        try {
            // alert(JSON.stringify(item));
            localStorage.setItem('gnorganisationcode', item.gnorganisationcode);
            localStorage.setItem('gnjobcode', item.gnjobcode);
            // alert(Number(localStorage.getItem('gnorganisationcode')))
            history.push('/GNJob_details')
            window.location.reload();
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "Gn_Job_list", "navigategnjob", 0, error.toString());
        }
    }

    joblist = (currentItems) => {
        try {
            return this.state.currentItems.map((item, index) => {
                let { statecode, title, gnorganisationname, gnorganisationurl, statename, expirationdate, noofvacancies, } = item
                if (gnorganisationurl == '') {
                    gnorganisationurl = 'images/joblist_logo.jpg'
                }
                return (
                    // ajith 
                    <a href={'/GNJob_details?gnorganisationcode=' + item.gnorganisationcode + '&gnjobcode=' + item.gnjobcode} className="col-lg-12 col-md-12" key={index}>
                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                            <div className="featured-thumbnail">
                                <img src={gnorganisationurl} />
                            </div>
                            <div className="featured-content">
                                <div className="job-title">
                                    <div className="job-heading">
                                        <h3><a href={'/GNJob_details?gnorganisationcode=' + item.gnorganisationcode + '&gnjobcode=' + item.gnjobcode}>{title}</a></h3>
                                    </div>
                                    <div className="job-time">
                                        <span className="green">Last date - {Moment(new Date(expirationdate)).format('DD-MM-YYYY')}</span>
                                    </div>
                                </div>

                                <div className="featured-details">
                                    <div className="job-meta">
                                        <div>
                                            <span><i className="fa fa-building"></i>{gnorganisationname}</span><br />
                                        </div>
                                        {statecode != 0 ?
                                            <div>
                                                <span><i className="fa fa-map-marker-alt"></i>{statename}</span><br />
                                            </div>
                                            : ""}
                                        <div>
                                            <span><i className="fas fa-users"></i>{noofvacancies} Vacancies</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a >
                )

            })
        }
        catch (error) {
        }
    }

    render() {
        return (

            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="Government job list"
                    breadcrumb="Government Jobs List"
                    className="pb-65 pb-lg-0"
                />
                {/* PageHeader end */}


                {/* form */}
                {/* <div className="form-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg border rounded p-40 p-lg-20 mt_70 mt-lg-50">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <form id="search_Form" className="search_Form wrap-form" method="post" action="#" data-mailchimp="true">
                                            <label>
                                                <i className="ti ti-search"></i>
                                                <input type="text" id="filter" placeholder="Job Functions or Job Role" />
                                            </label>
                                            <label>
                                                <i className="ti ti-location-pin"></i>
                                                <input type="email" name="EMAIL" id="txtemail" placeholder="location" />
                                            </label>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor" type="submit">Find Jobs</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* form end */}


                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            {/* <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                <div className="job_list-widget">
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="flaticon flaticon-calendar-1"></i>Date Posted</h3>
                                        <form id="list1" className="list-filter">
                                            <div onChange={this.onChangeValue} >
                                                <label className="radio">
                                                    <input type="radio" value="Today" name="post_date" />Today
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Last 7 days" defaultChecked name="post_date" />Last 7 days
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Last 14 days" name="post_date" />Last 14 days
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Last 21 days" name="post_date" />Last 21 days
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Last 30 days" name="post_date" />Last 30 days
                                                </label>
                                            </div>
                                        </form>
                                    </aside>
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="flaticon flaticon-subfolder-1"></i>Job Function</h3>
                                        <form id="list2" onSubmit={this.formSubmit} className="list-filter">
                                            <div onChange={this.onChangeValue} >
                                                <label className="radio">
                                                    <input type="radio" value="Digital Marketing" defaultChecked name="categories" />Digital Marketing
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Charity & Voluntary" name="categories" />Charity & Voluntary
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="IT Contractor" name="categories" />IT Contractor
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Estate Agency" name="categories" />Estate Agency
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Graduate" name="categories" />Graduate
                                                </label>
                                            </div>
                                        </form>
                                    </aside>
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="flaticon flaticon-subfolder-1"></i>Job Role</h3>
                                        <form id="list2" onSubmit={this.formSubmit} className="list-filter">
                                            <div onChange={this.onChangeValue} >
                                                <label className="radio">
                                                    <input type="radio" value="Digital Marketing" defaultChecked name="categories" />Digital Marketing
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Charity & Voluntary" name="categories" />Charity & Voluntary
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="IT Contractor" name="categories" />IT Contractor
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Estate Agency" name="categories" />Estate Agency
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="Graduate" name="categories" />Graduate
                                                </label>
                                            </div>
                                        </form>
                                    </aside>
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="flaticon flaticon-expert"></i>Experince</h3>
                                        <form id="list3" onSubmit={this.formSubmit} className="list-filter">
                                            <div onChange={this.onChangeValue} >
                                                <label className="radio">
                                                    <input type="radio" value="0Year to 1Year" name="ex_year" />0Year to 1Year
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="1Year to 2Year" name="ex_year" />1Year to 2Year
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="2Year to 3Year" name="ex_year" />2Year to 3Year
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="3Year or more" defaultChecked name="ex_year" />3Year or more
                                                </label>
                                            </div>
                                        </form>
                                    </aside>
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="flaticon flaticon-gender"></i>Gender</h3>
                                        <form id="list4" onSubmit={this.formSubmit} className="list-filter">
                                            <div onChange={this.onChangeValue} >
                                                <label className="radio">
                                                    <input type="radio" value="male" defaultChecked name="gender" />male
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" value="female" name="gender" />female
                                                </label>
                                            </div>
                                        </form>
                                    </aside>
                                </div>
                                <aside className="widget widget-download">
                                    <ul className="download">
                                        <li><a href="#">Download.pdf</a><i className="ti ti-files"></i></li>
                                        <li><a href="#">Download.txt</a><i className="ti ti-files"></i></li>
                                    </ul>
                                </aside>
                                <aside className="widget widget-banner text-theme-WhiteColor">
                                    <div className="ttm-col-bgcolor-yes bg-theme-DarkColor text-theme-WhitColor col-bg-img-seven ttm-col-bgimage-yes ttm-bg p-40">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor" style={{ backgroundImage: 'url(https://via.placeholder.com/875x583?text=875x583+col-bgimage-7.jpg)' }}>
                                            <div className="ttm-col-wrapper-bg-layer-inner bg-theme-DarkColor"></div>
                                        </div>
                                        <div className="layer-content text-center">
                                            <div className="widget-banner-inner">
                                                <h3 className="mb-15">Make a Difference with Online Resume!</h3>
                                                <p className="mb-30">Your Resume in Minutes with Jobs Resume Assistant is Ready!</p>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                    href={'/'}>appoinments!</a>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div> */}
                            <div className="col-lg-12 content-area">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="showing-result-block d-sm-flex align-items-center justify-content-between">
                                            <span className="showing-result">{this.state.gnorganisationname}</span>
                                            {/* <form>
                                                <div className="sort-by">Sort By:
                                                    <select defaultValue="">
                                                        <option value="" disabled>A to Z</option>
                                                        <option value="popularity">a</option>
                                                        <option value="rating">b</option>
                                                        <option value="date">c</option>
                                                        <option value="price">d</option>
                                                        <option value="price-desc">e</option>
                                                    </select>
                                                </div>
                                            </form> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {this.joblist(this.state.currentItems)}
                                    <div className="col-lg-12">
                                        {/* <div className="header_btn">
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                onClick={e => { this.loadMore() }}>Load More</a>
                                        </div> */}
                                        <div>

                                            {/* <a className="load_btn_style" onClick={e => { this.loadMore() }}>
                                                {'Load More'}</a> */}
                                        </div>

                                        {/* <a className="page-nav-link">prev</a> */}
                                        {/* {this.bind_pageslist()} */}
                                        {/* <a className="page-nav-link current">1</a>
                                            <a className="page-nav-link" href="#">2</a>
                                            <a className="page-nav-link" href="#">3</a>
                                            <a className="page-nav-link" href="#">....</a>
                                            <a className="page-nav-link">next</a> */}
                                        <div className="job-pagination-blocknew">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="next >"
                                                onPageChange={this.handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={this.state.pageCount}
                                                previousLabel="< previous"
                                                renderOnZeroPageCount={null}
                                            />
                                        </div>


                                    </div>




                                </div>{/* row end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
                {/* accordion */}


                <Footer />

            </div>
        )
    }
}

export default Gn_Job_list;