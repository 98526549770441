import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from "axios";
import Select from 'react-select';
// import { tCToasontainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useSnackbar } from 'notistack';
import { APIURL, COMMON } from '../config'
import Checkbox from '../components/@vuexy/checkbox/CheckboxesVuexy';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormFeedback,
    Label,
    CustomInput,
    FormGroup
} from "reactstrap"
import { generateRefreshToken } from '../serviceworker';
let error_log = require('../error_log');


export class Employee_registration extends Component {
    constructor() {
        super();
        this.state = {
            ipurl: APIURL,
            empname: '',
            username: '',
            password: '',
            mobnum: '',
            selected_knowntype: '0',
            knowntypelist: [],
            knowntypeOptions: [],
            otp: '',
            setOTP: '',
            value: '', otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "", disable: true,
            txtempname_invalid: false,
            errorempname: 'Please enter name of the user',
            txtusername_invalid: false,
            errorusername: 'Please enter user name',
            txtmobnum_invalid: false,
            errormobnum: 'Please enter mobile no.',
            txtpassword_invalid: false,
            errorpassword: 'Please enter password',
            txtknowntype_invalid: false,
            errorknowntype: 'Please how do you know about JOBANYA',
            varstatuscode: '',
            txtotp_invalid: false,
            errorotp: 'Please enter otp',
            iagree:''
        };
    }

    componentDidMount() {
        try {
            this.employee_load();
            let empcode = localStorage.getItem('employeecode');
            this.setState({employeecode : this.state.employeecode = empcode});
            // alert("44444444")
            localStorage.getItem('ipaddress')
            this.setState({ipaddress : this.state. ipaddress = localStorage.getItem('ipaddress')});
                stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
                this.setState({languagecode : this.state.languagecode = localStorage.getItem('languagecode')})
        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Register", "componentDidMount", 0, error.toString());
        }
    }
    Checkusername() {
        var accessToken =  localStorage.getItem('accessToken');
        window.fbq('track', 'Website', { fb_ad_function: "Checkusername", fb_ad_type: "Employee Registration", fb_ad_loginuser: COMMON.employee_name });
        if (this.state.username.length === 0) {
            this.setState({ txtusername_invalid: this.state.txtusername_invalid = true })
        }
        else {
            var i = this.state.username.length;
            var user = [];
            var users = [];
            for (var j = 0; j < i; j++) {
                var k = this.state.username.charCodeAt(j);
                users.push(k);
                if ((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || (k >= 48 && k <= 57) || (k == 64) || (k == 35) || (k == 36) || (k == 38) || (k == 95) || (k == 45)) {
                    user.push(k)
                }
            }
            if (user.length == users.length) {
                if (users.length != 0 && user.length < 3) {
                    this.setState({
                        errorusername: this.state.errorusername = "User name should be atleast 3 characters",
                        txtusername_invalid: this.state.txtusername_invalid = true
                    })
                }
                else if (!(COMMON.username.test(this.state.username))) {
                    this.setState({
                        errorusername: this.state.errorusername = "Please only use Alpha & Numeric & Do not use Space & Special Characters",
                        txtusername_invalid: this.state.txtusername_invalid = true
                    })
                }
                else if (i >= 50) {

                    this.setState({
                        errorusername: this.state.errorusername = "User name cannot exceed 50 characters",
                        txtusername_invalid: this.state.txtusername_invalid = true
                    })
                }
                else {

                    this.setState({
                        errorusername: this.state.errorusername = "",
                        txtusername_invalid: this.state.txtusername_invalid = false
                    })
                }
            }
            else {
                this.setState({
                    errorusername: this.state.errorusername = "Please only use Alpha & Numeric & Do not use Space & Special Characters",
                    txtusername_invalid: this.state.txtusername_invalid = true
                })
            }
        }
        if (this.state.errorusername.length == 0) {
            try {
                axios({
                    method: 'Post',
                    url: this.state.ipurl + '/employee/registerusername/',
                    params: {
                        username: this.state.username
                    },
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                      },
                })
                    .then(async response => {
                        if(response.data.status == 401)
                        {
                         await generateRefreshToken().then((result)=>{
                            if(result == 1)
                            {
                              this.Checkusername();
                            }
                            else{
                              // alert('Unauthorized')
                            }
                          })
                     
                        }
                        else{
                        if (response.data.hasOwnProperty('employee_json_result')) {
                            this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 0;
                            if (this.state.varstatuscode == 11) {
                                this.setState({
                                    errorusername: this.state.errorusername = "User Name already exist",
                                    txtusername_invalid: this.state.txtusername_invalid = true
                                })
                            }
                        }
                    }
                    })
                    .catch((error) => {

                    });
            } catch (error) { }
        }
    }
    //validate for mobile number
    Checkmobnum() {
        var accessToken =  localStorage.getItem('accessToken');

        if (this.state.mobnum.length === 0) {
            this.setState({
                errormobnum: this.state.errormobnum = 'Please enter mobile number',
                txtmobnum_invalid: this.state.txtmobnum_invalid = true
            })
        }
        else if (this.state.mobnum != 0) {
            var num = this.state.mobnum;
            if (!isNaN(num)) {
                var i = this.state.mobnum.length;
                if (i != 10) {
                    this.setState({
                        errormobnum: this.state.errormobnum = 'Please enter valid mobile number',
                        txtmobnum_invalid: this.state.txtmobnum_invalid = true
                    })
                }
                else if (i == 10) {
                    this.setState({
                        errormobnum: this.state.errormobnum = '',
                        txtmobnum_invalid: this.state.txtmobnum_invalid = false
                    })
                }
            }
            else {
                this.setState({
                    errormobnum: this.state.errormobnum = 'Please enter valid mobile number',
                    txtmobnum_invalid: this.state.txtmobnum_invalid = true
                })
            }
        }
        if (this.state.errormobnum.length == 0) {
            try {
                axios({
                    method: 'Post',
                    url: this.state.ipurl + '/employee/registermobileno/?',
                    params: {
                        mobileno: this.state.mobnum,
                    },
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                      },
        
                })
                    .then(async response => {
                        if(response.data.status == 401)
                        {
                         await generateRefreshToken().then((result)=>{
                            if(result == 1)
                            {
                              this.Checkmobnum();
                            }
                            else{
                              // alert('Unauthorized')
                            }
                          })
                     
                        }
                        else{
                        if (response.data.hasOwnProperty('employee_json_result')) {
                            this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 0;
                            if (this.state.varstatuscode == 12) {
                                this.setState({
                                    errormobnum: this.state.errormobnum = 'Mobile number already exits',
                                    txtmobnum_invalid: this.state.txtmobnum_invalid = true
                                })
                            }
                            else if (this.state.varstatuscode == 16) {
                                this.setState({
                                    errormobnum: this.state.errormobnum = '',
                                    txtmobnum_invalid: this.state.txtmobnum_invalid = false
                                })
                            }
                        }
                    }
                    })
                    .catch((error) => {

                    });
            } catch (error) { }
        }
    }
    Checkpassword() {
        try {
            if (this.state.password.length === 0) {
                this.setState({
                    errorpassword: this.state.errorpassword = "Please enter password",
                    txtpassword_invalid: this.state.txtpassword_invalid = true
                })
            }
            else {
                var i = this.state.password.length;
                if (((this.state.password.length) != 0) && ((this.state.password.length) < 6)) {
                    this.setState({
                        errorpassword: this.state.errorpassword = "Password should be atleast 6 characters",
                        txtpassword_invalid: this.state.txtpassword_invalid = true
                    })
                }
                else {
                    this.setState({
                        errorpassword: this.state.errorpassword = "",
                        txtpassword_invalid: this.state.txtpassword_invalid = false
                    })
                }
            }
        } catch (error) {

        }
    }

    check_inputfield_empty() {
        this.setState({
            txtempname_invalid: this.state.txtempname_invalid = false,
            txtknowntype_invalid: this.state.txtknowntype_invalid = false,
        })

        if (this.state.empname == '' || this.state.empname == null || this.state.empname == undefined) {
            this.setState({ txtempname_invalid: this.state.txtempname_invalid = true })
            return
        }
        if (this.state.selected_knowntype == '0') {
            this.setState({ txtknowntype_invalid: this.state.txtknowntype_invalid = true })
            return
        }

        this.user_register()
    }

    employee_load() {
        var accessToken =  localStorage.getItem('accessToken');
        window.fbq('track', 'Website', { fb_ad_function: "employee_load", fb_ad_type: "Employee Registration", fb_ad_loginuser: COMMON.employee_name });
        axios({
            method: 'Post',
            url: this.state.ipurl + 'employee/load',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
              },
            params: {
                languagecode: this.state.languagecode,
                ipaddress: this.state.ipaddress,
                appcode: 1,
                employercode: this.state.employercode
            }
        }).then(async response => {
            if(response.data.status == 401)
            {
             await generateRefreshToken().then((result)=>{
                if(result == 1)
                {
                  this.employee_load();
                }
                else{
                  // alert('Unauthorized')
                }
              })
         
            }
            else{
            if (response.data.employer_json_result.varstatuscode == 4) {
                let result = response.data.employer_json_result;
                if (result.knowntypelist && result.knowntypelist.length > 0) {
                    this.state.knowntypelist = result.knowntypelist;
                    let knowntypeOptions = result.knowntypelist.map(knowtype => {
                        return { value: knowtype.knownfromcode, label: knowtype.knownfromname };
                    });
                    JSON.stringify(knowntypeOptions);
                    this.setState({ knowntypeOptions });
                }
            }
        }
        })
    }
    knowntype_change = ({ target: { value } }) => {
        this.setState({ selected_knowntype: this.selected_knowntype = value })
    }

    user_register() {
        var accessToken =  localStorage.getItem('accessToken');
        window.fbq('track', 'Website', { fb_ad_function: "user_register", fb_ad_type: "Employee Registration", fb_ad_loginuser: COMMON.employee_name });
        this.setState({ txtotp_invalid: this.state.txtotp_invalid = false })
        if (this.state.otp1 == '' || this.state.otp2 == '' || this.state.otp3 == '' || this.state.otp4 == '' || this.state.otp5 == '' || this.state.otp6 == '') {
            this.setState({ txtotp_invalid: this.state.txtotp_invalid = true })
            return
        }
        if (this.state.iagree == false || this.state.iagree == 'false') {
            return
        }
        axios({
            method: 'Post',
            url: this.state.ipurl + 'employee/register/',
            crossdomain: true,
            params: {
                deviceip: '103.154.203.22'
            },
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
              },
            data: {
                employeename: this.state.empname,
                registervia: 2,
                username: this.state.username,
                mobileno: this.state.mobnum,
                password: this.state.password,
                knowabouttypecode: this.state.txt_know_code,
                others: this.state.selected_knowntype,
                usercode: 0,
            }
        }).then(async response => {
         if(response.data.status == 401)
                {
                 await generateRefreshToken().then((result)=>{
                    if(result == 1)
                    {
                      this.user_register();
                    }
                    else{
                      // alert('Unauthorized')
                    }
                  })
             
                }
                else{

            let result = response.data.employee_json_result.varstatuscode;
            if (result == 13) {
                alert(response.data.employee_json_result.responsestring)
                window.location.href = "http://localhost:3000/login";
            }
            else if (result == 11) {
                alert(response.data.employee_json_result.responsestring)
            }
            else if (result == 12) {
                alert(response.data.employee_json_result.responsestring)
            }
        }
        })
    }
    handleSubmit(event) {

        const data = new FormData(event.target);
        console.log(this.state);
        event.preventDefault();
    }
    handleChange(value1, event) {

        this.setState({ [value1]: event.target.value });
    }
    inputfocus = (elmnt) => {

        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            let next = elmnt.target.tabIndex - 2;
            if (next > 5) {

                elmnt.target.form.elements[next - 1].focus()
            }
        }
        else {

            let next = elmnt.target.tabIndex;
            // alert(next)
            if (next < 12) {
                console.log(elmnt.target.form)
                elmnt.target.form.elements[next - 1].focus()
            }
        }

    }

    getOtp() {
        window.fbq('track', 'Website', { fb_ad_function: "getOtp", fb_ad_type: "Employee Registration", fb_ad_loginuser: COMMON.employee_name });
        try {
            axios({
                method: 'Post',
                url: this.state.ipurl + '/employee/registermobileno/?',
                params: {
                    mobileno: this.state.mobnum,
                },
            })
                .then(response => {
                    if (response.data.employee_json_result.varstatuscode == 12) {
                        alert("Mobile no already exits")

                    }
                    else if (response.data.employee_json_result.varstatuscode == 16) {

                        try {
                            axios({
                                method: 'Post',
                                url: this.state.ipurl + 'sendsms/getOTP/',
                                params: {
                                    employeecode: this.state.employeecode,
                                    ipaddress: this.state.ipaddress,
                                    mobileno: this.state.mobnum,
                                    typecode: 5,
                                    employeename: this.state.empname
                                }
                            })
                                .then(response => {
                                    // alert(JSON.stringify(response))
                                })
                        }
                        catch (error) {

                        }

                    }

                })

        }
        catch (error) { }
    }


    render() {
        return (

            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="Employee registration"
                    breadcrumb="Employee_registration"
                />
                {/* PageHeader end */}


                {/* register */}
                <div className="ttm-row register-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <div className="text-center mb-20">
                                            <h3>Employee Registration</h3>
                                        </div>
                                        <div className="ttm-tabs ttm-tab-style-02">
                                            <Tabs>
                                                <div className='row'>

                                                    <div className='col-lg-3'>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <TabList className="tabs">
                                                            <Tab className="tab">
                                                                <a>
                                                                    <i className="flaticon flaticon-research"></i>
                                                                    <span>Candidate</span><h5>Login as a Candidate</h5>
                                                                </a>
                                                            </Tab>
                                                        </TabList>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                    </div>

                                                </div>

                                                {/* <TabList className="tabs">
                                                    <Tab className="tab">

                                                    </Tab>

                                                    <Tab className="tab">

                                                    </Tab>
                                                </TabList> */}
                                                <div className="content-tab">
                                                    {/* <TabPanel> */}
                                                    <form className="login_form wrap-form">
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="col-lg-12">
                                                                    <FormGroup>
                                                                        <label>Name of the user</label>
                                                                        <label>
                                                                            <i className="ti ti-user"></i>
                                                                            <input type="text" id="txtempname" placeholder="Name of the user"
                                                                                autocomplete="off"
                                                                                tabIndex="1"
                                                                                value={this.state.empname}
                                                                                onChange={(e) => {
                                                                                    this.setState({ empname: this.state.empname = e.target.value });
                                                                                }}
                                                                                onBlur={() => this.check_inputfield_empty()} />
                                                                            {this.state.txtempname_invalid == true ?
                                                                                <span style={{ color: 'red' }}>{this.state.errorempname}</span>
                                                                                :
                                                                                ''
                                                                            }

                                                                        </label>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>User Name</label>
                                                                    <label>
                                                                        <i className="ti ti-user"></i>
                                                                        <input type="text" id="txtusername" placeholder="User Name"
                                                                            autocomplete="off"
                                                                            tabIndex="2"
                                                                            value={this.state.username}
                                                                            onChange={(e) => {
                                                                                this.setState({ username: this.state.username = e.target.value });
                                                                            }}
                                                                            onBlur={() => this.Checkusername()}
                                                                        />
                                                                        {this.state.txtusername_invalid == true ?
                                                                            <span style={{ color: 'red' }}>{this.state.errorusername}</span>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>Phone Number</label>
                                                                    <label>
                                                                        <i className="ti ti-mobile"></i>
                                                                        <input type="text" id="txtmobnum" placeholder="Phone Number"
                                                                            autocomplete="off"
                                                                            tabIndex="3"
                                                                            value={this.state.mobnum}
                                                                            onChange={(e) => {
                                                                                this.setState({ mobnum: this.state.mobnum = e.target.value });
                                                                            }}
                                                                            onBlur={() => this.Checkmobnum()} />
                                                                        {this.state.txtmobnum_invalid == true ?
                                                                            <span style={{ color: 'red' }}>{this.state.errormobnum}</span>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>Password</label>
                                                                    <label>
                                                                        <i className="ti ti-lock"></i>
                                                                        <input type="assword" id="txtpassword" placeholder="Password"
                                                                            autocomplete="off"
                                                                            tabIndex="4"
                                                                            value={this.state.password}
                                                                            onChange={(e) => {
                                                                                this.setState({ password: this.state.password = e.target.value });
                                                                            }}
                                                                            onBlur={() => this.Checkpassword()} />
                                                                        {this.state.txtpassword_invalid == true ?
                                                                            <span style={{ color: 'red' }}>{this.state.errorpassword}</span>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>How do you know about JOBANYA?</label>
                                                                    <label>
                                                                    <i className="ti ti-world"></i>
                                                                        <select tabIndex="5" onChange={this.knowntype_change} onBlur={() => this.check_inputfield_empty()}>
                                                                            <option value="0">Please Select</option>
                                                                            {this.state.knowntypeOptions.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}

                                                                        </select>
                                                                        {this.state.txtknowntype_invalid == true ?
                                                                            <span style={{ color: 'red' }}>{this.state.errorknowntype}</span>
                                                                            :
                                                                            ''
                                                                        }
                                                                        {/* <Select
                                                                            ref="dp_relationship"
                                                                            id="dp_relationship"
                                                                            className="React education_dropdown"
                                                                            classNamePrefix="select"
                                                                            name={this.state.dp_relationship}
                                                                            options={this.state.knowntypeOptions}
                                                                            value={this.state.selected_knowntype}
                                                                            onChange={(e)=>{
                                                                                this.setState({selected_knowntype:this.state.selected_knowntype = e})
                                                                            }}
                                                                            isClearable={false}
                                                                        // onBlur={() => this.check_inputfield_empty("jobfunction")}
                                                                        /> */}
                                                                    </label>
                                                                </div>
                                                                {/* <OtpInput value={this.state.otp} onChange={this.handleChange} numInputs={6} separator={<span>-</span>} /> */}

                                                            </div>
                                                            <div className="col-lg-3">
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                </div>
                                                                <div className="col-lg-2">
                                                                    <a className="btnotp" onClick={() => this.getOtp()}>Get OTP</a>
                                                                </div>

                                                                <div className="col-lg-4">
                                                                    <div className="otpContainer">

                                                                        <input
                                                                            name="otp1"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            className="otpInput"
                                                                            value={this.state.otp1}
                                                                            onKeyPress={this.keyPressed}
                                                                            onChange={e => this.handleChange("otp1", e)}
                                                                            tabIndex="7" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                                                                        />
                                                                        <input
                                                                            name="otp2"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            className="otpInput"
                                                                            value={this.state.otp2}
                                                                            onChange={e => this.handleChange("otp2", e)}
                                                                            tabIndex="8" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                                                                        />
                                                                        <input
                                                                            name="otp3"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            className="otpInput"
                                                                            value={this.state.otp3}
                                                                            onChange={e => this.handleChange("otp3", e)}
                                                                            tabIndex="9" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                                                                        />
                                                                        <input
                                                                            name="otp4"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            className="otpInput"
                                                                            value={this.state.otp4}
                                                                            onChange={e => this.handleChange("otp4", e)}
                                                                            tabIndex="10" maxLength="1" onKeyUp={e => this.inputfocus(e)}
                                                                        />

                                                                        <input
                                                                            name="otp5"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            className="otpInput"
                                                                            value={this.state.otp5}
                                                                            onChange={e => this.handleChange("otp5", e)}
                                                                            tabIndex="11" maxLength="1" onKeyUp={e => this.inputfocus(e)}
                                                                        />
                                                                        <input
                                                                            name="otp6"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            className="otpInput"
                                                                            value={this.state.otp6}
                                                                            onChange={e => this.handleChange("otp6", e)}
                                                                            tabIndex="12" maxLength="1" onKeyUp={e => this.inputfocus(e)}
                                                                        />
                                                                    </div>
                                                                    {this.state.txtotp_invalid == true ?
                                                                        <span style={{ color: 'red' }}>{this.state.errorotp}</span>
                                                                        :
                                                                        ''
                                                                    }

                                                                </div>
                                                                <div className="col-lg-4">
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    {/* <div className="col-lg-4">
                                                                </div> */}
                                                                    {/* <div className="col-lg-4"> */}
                                                                    <label className="mt-0">
                                                                        <div className="d-md-flex align-items-center justify-content-between">
                                                                            <div className="cookies-checkbox mt-15">
                                                                                <div className="d-flex flex-row justify-content-start">
                                                                                    <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" 
                                                                                    onChange={(e) => {        
                                                                                        this.setState({iagree:this.state.iagree = e.target.checked})
                                                                                    }}/>
                                                                                    <span>I agree to the JOBANYA Terms and Conditions</span>
                                                                                    {/* <Checkbox className="d-flex flex-row justify-content-start"
                                                                                        id="jobfunction"
                                                                                        icon=""
                                                                                        label={'I agree to the JOBANYA.'}
                                                                                        checked={this.state.iagree}
                                                                                        onChange={(e) => {
                                                                                            this.setState({iagree:this.iagree = e})
                                                                                        }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                    {/* </div> */}
                                                                    {/* <div className="col-lg-4">
                                                                </div> */}
                                                                </div>
                                                                <div className="col-lg-3">
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label className="mb-0">
                                                                        <a className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" style={{ textAlign: 'center' }}
                                                                            onClick={() => this.check_inputfield_empty()}>Sign up</a>
                                                                    </label>
                                                                    {/* <div className="mt-15">
                                                                        <p>Already registered?<a href={'/login'} className="text-theme-SkinColor"> Sign in here</a></p>
                                                                    </div> */}
                                                                </div>
                                                                <div className="col-lg-3">
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                </div>
                                                                <div className="col-lg-4" style={{ justifyContent: 'center', display: 'flex' }}>
                                                                    {/* <label className="mb-0">
                                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                            type="submit" onClick={() => this.user_register()}>Sign up</button>
                                                                    </label>
                                                                    <div className="mt-15"> */}
                                                                    <p>Already registered?<a href={'/login'} className="text-theme-SkinColor"> Sign in here</a></p>
                                                                    {/* </div> */}
                                                                </div>
                                                                <div className="col-lg-4">
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                    {/* </TabPanel> */}
                                                    {/* <TabPanel>
                                                        <form id="login_form" className="login_form wrap-form">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-user"></i>
                                                                        <input type="text" id="txtname" placeholder="Username" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-email"></i>
                                                                        <input type="email" id="txtemail" placeholder="Email Address" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-lock"></i>
                                                                        <input type="password" id="password" placeholder="Password" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-lock"></i>
                                                                        <input type="password" id="cpassword" placeholder="Confirm Password *" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>
                                                                        <i className="ti ti-mobile"></i>
                                                                        <input type="tel" id="txtphone" placeholder="Phone Number" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label className="mt-0">
                                                                        <div className="d-md-flex align-items-center justify-content-between">
                                                                            <div className="cookies-checkbox mt-15">
                                                                                <div className="d-flex flex-row justify-content-start">
                                                                                    <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" />
                                                                                    <span>I agree to the JOBANYA.</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-15">
                                                                                <p>Already have an account?</p>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label className="mb-0">
                                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                            type="submit">Sign up</button>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </TabPanel> */}
                                                </div>
                                            </Tabs>
                                        </div>
                                        {/* <div className="login-social-buttons">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button id="login-with-facebook" className="social-account-button w-100 facebook-button">
                                                        <i className="ti ti-facebook"></i><span>Login With facebook</span>
                                                    </button>
                                                </div>
                                                <div className="col-md-6">
                                                    <button id="login-with-google" className="social-account-button w-100 google-button">
                                                        <i className="ti ti-google"></i><span>Login With Google</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* login end */}


                <Footer />

            </div>
        )
    }
}

export default Employee_registration;