import React, { Component } from 'react'

export class EmployeePortalFooter extends Component {
    render() {
        return (
            <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
           
                <div className="bottom-footer-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-6">
                                <span className="copyright">Copyright © 2023 <a href={'/'}>Jobanya </a>All rights reserved.
                                </span>
                                {/* <ul className="footer-nav-menu">
                                    <li><a href={'/'}>Privacy & Policy</a></li>
                                    <li><a href={'/'}>Terms & Conditions</a></li>
                                </ul> */}
                            </div>
                            <div className="col-lg-4 col-md-6 d-lg-flex align-items-center justify-content-between">
                                <ul className="footer-nav-menu">
                                    {/* <li><a href={'/'}>Privacy Policy</a></li>
                                    <li><a href={'/'}>Terms & Conditions</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


