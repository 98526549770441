import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import Video from '../components/layout/Video';


export class About_us extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }
    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{

                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }, {

                breakpoint: 778,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };

        return (
            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="About Us"
                    breadcrumb="About Us"
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    {/* <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/Services.jpg" alt="services-img" />
                                    </div> */}
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">About Us</h3>
                                        <p>Jobanya is the most suitable platform to find your dream job. Job seekers and employers are connected through us. No matter where you are, you can use our App to easily find jobs and employees in Tamil Nadu. If you follow our guidelines 100%, you will get 100% results from our App. We will keep your information secure.</p>
                                        <div className="row">
                                            <h4 className="mb-20">For Employees</h4>
                                            <h5 className="mb-20">Get 100% results by following our guidelines.</h5>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg" style={{width:'50px',height:'50px'}}>
                                                            {/* <i className="flaticon-Registered"></i> */}
                                                            <img src="images/icons/download_img.png" />
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Download the App</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Our App is available on the Google Play Store so you can download the App from there.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg" style={{width:'50px',height:'50px'}}>
                                                            {/* <i className="flaticon-Registered"></i> */}
                                                            <img src="images/icons/register.png" />
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Register your information</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We prefer candidates who fill out 100% of the details in their profiles since unfilled profiles will not be displayed on employers' screens. Ensure that all your details are filled out 100%.
                                                                Note: Your preferred jobs will only appear if your profile is 100% complete.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg" style={{width:'50px',height:'50px'}}>
                                                            {/* <i className="flaticon-conveyor"></i> */}
                                                            <img src="images/icons/apply.png" />
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Apply for a job</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>You will be able to view your suitable jobs on your App once you have completed the profile setup process. You will need to apply for a minimum of 10 jobs.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg" style={{width:'50px',height:'50px'}}>
                                                            {/* <i className="flaticon-human-resources-3"></i> */}
                                                            <img src="images/icons/notification.png" />
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Turn Your Notifications ON</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>If employers see your profile, they will contact you for an interview, so you will get a notification whenever an interview request is made. Our App keeps you updated with new jobs every day.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                        </div>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        <div className="row">
                                            <h4 className="mb-20">For Employers</h4>
                                            <div className="col-md-6">
                                                <h5>Follow this setup to get your candidates</h5>
                                                <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                                    <li>
                                                        <div className="ttm-list-li-content">Please fill out your company details completely</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Please enter the skills or details of your preferred candidate</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Give the candidates a call request for an interview</div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="ttm_single_image-wrapper mb-30">
                                                    <img className="img-fluid" src="images/Services.jpg" alt="services-img" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <p>Follow these steps to get employees right away. You can then use a web application or mobile App available on both platforms to get the candidates.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                            <div className="col-lg-4 widget-area">
                                <div className="sidebar-right">
                                    {/* <aside className="widget widget-nav-menu">
                                        <ul>
                                            <li><a className="active" href={'/services_details'}>Manpower Supply</a></li>
                                            <li><a href={'/services_details'}>Technical Services</a></li>
                                            <li><a href={'/services_details'}>Executive Search</a></li>
                                            <li><a href={'/services_details'}>Forte Acquisition</a></li>
                                            <li><a href={'/services_details'}>Human Resource</a></li>
                                            <li><a href={'/services_details'}>Retained search</a></li>
                                        </ul>
                                    </aside> */}
                                    {/* <aside className="widget widget-download">
                                    <ul className="download">
                                        <li><span className="download-img"><img src="images/pdf-img.png" alt="pdf-img" /></span>
                                            <span className="download-caption">
                                                <span>Case Studies</span>
                                                <h6>Audit & Assuarance</h6>
                                                <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-left ttm-btn-color-grey" href="/">
                                                    <i className="ti ti-arrow-right"></i>Download (3.5 MB)</a>
                                            </span>
                                        </li>
                                    </ul>
                                </aside> */}
                                    <aside className="widget widget-banner">
                                        <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                                                <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                            </div>
                                            <div className="layer-content text-center text-theme-WhiteColor">
                                                <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                                    <i className="far fa-comments"></i></div>
                                                <h3>Do You Need Any Help?</h3>
                                                <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                                                <ul>
                                                    <li>+91 93843 78052</li>
                                                    <li>support@jobanya.com</li>
                                                </ul>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                            ttm-btn-color-skincolor" href={'/contact_us'}>Contact</a>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}


export default About_us;