// module.exports = global.config = {
//     geturl: {
//         ipaddress: {
//             url: "https://testapi.thebestjobs.in/api/controller/"// Testing
//             //url: "https://apiuat.thebestjobs.in/api/controller/"// UAT
//             // url: "http://172.16.1.250:8800/api/controller/" //250
//             // url:"https://api.thebestjobs.in/api/controller/" // Live
//             //url:"https://api.jobanya.com/api/controller/" // NEW jobanya Live
//         }  
//         // rest of your translation object`
//     }
//     // other global config variables you wish
// };
//  const APIURL = "https://devapi.jobanya.com/api/controller/"// Dev
//  const APIURL = "https://apilive.jobanya.com/api/controller/"// Live  
// const APIURL = "https://api.jobanya.com//api/controller/"// website
// const APIURL = "https://testapi.thebestjobs.in/api/controller/"// Testing
const APIURL = "https://uatapi.jobanya.com/api/controller/"// UAT
// const APIURL = "http://Jobanya-env.eba-vtr7amfv.ap-south-1.elasticbeanstalk.com/api/controller/" // NEW jobanya Live
const COMMON = {
    disclaimer_clause_link: 'https://jobanya.com/disclaimer-clause/',
    flashjobbackgroundcolor: '#e4f7dc',
    employee_name: '',
    FACEBOOK_APP_ID: '698899494718434',
    isschoolingpursuing: 0,//0 FOR NOT PURSUING 1 FOR PURSUING
    isafterschoolingpursuing: 0,//0 FOR NOT PURSUING 1 FOR PURSUING
    //Employee Common variables
    animationInTiming_modal: 10,
    SVG_IMG_PERSONAL_INFO: 'https://bestjobs-app-images.s3.us-east-2.amazonaws.com/Personal_info.svg',
    SVG_IMG_CONTACT_INFO: 'https://bestjobs-app-images.s3.us-east-2.amazonaws.com/Contact_Info.svg',
    SVG_IMG_EDUCATION: 'https://bestjobs-app-images.s3.us-east-2.amazonaws.com/Education.svg',
    SVG_IMG_SKILLS: 'https://bestjobs-app-images.s3.us-east-2.amazonaws.com/Skills.svg',
    SVG_IMG_EXPERIENCE: 'https://bestjobs-app-images.s3.us-east-2.amazonaws.com/Experience.svg',
    SVG_IMG_REFERENCE: 'https://bestjobs-app-images.s3.us-east-2.amazonaws.com/Reference.svg',
    SVG_IMG_PREFERENCE: 'https://bestjobs-app-images.s3.us-east-2.amazonaws.com/Preferences.svg',
    BUILD_NO: '1.0.1',
    GOOGLE_PACKAGE_NAME: "com.best_jobs",
    APPLE_STORE_ID: "id284882215",
    APP_TYPE_CODE: 1,
    statuscode: 2,
    save_as_draft: 23,
    post_job: 4,
    er_statuscode: 4,
    experiencejobarray: 4,
    fresherjobarray: 1,
    minsalary: 1000,
    default_language_code: 2,
    default_lang_shortcode: "ta",
    default_lang_code: 1,
    activestatuscode: 1,
    current_work_status: 0,
    current_jobfunction: 0,
    usercode: 1,
    experiencejobarray: 4,
    fresherjobarray: 1,
    type_fresher: "fresher",
    experience_year: 0,
    type_experience: "experience",
    current_work_flag: 0,
    current_jobfunction_flag: 0,
    current_from_month: 0,
    current_from_year: 0,
    notificationtypestatus_one: 1,
    notificationtypestatus_zero: 0,
    download_gif_image_timeout: 5000,//DOWNLOAD GIF IMAGE TIMEOUT SECONDS
    toast_flag: 0,
    logtype: "employee",
    sortbycode: 1,
    filterflag: 0,
    default_state_code: 1,
    back_code: 0,
    bind_filter_array: {},
    wished: 10,
    unwished: 13,
    ads: false,
    ads_resume: false,
    ads_employer: false,
    pc_eng: '',
    pc_tam: '',
    //status bar visible
    statusbar_visible: false,
    //Employer Potal Link
    //Terms & Conditions Link
    terms_condition_employee: 'https://jobanya.com/terms-conditions/',
    terms_condition_employer: 'https://jobanya.com/terms-conditions/',
    //Employer Common variables
    Salary: /^[0-9]*$/,
    username: /^[a-zA-Z0-9]+$/,
    urlRegex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    clear_flag: 0,
    Lisense_number: /^([A-Z0-9])+$/,
   
   
}

const checkIsLoggedIn = () => {
  const user = localStorage.getItem("isloggedIn") == "true" && localStorage.getItem("mobileNumber") ? true : false
  if (localStorage.getItem("IdleTimeOut")) {
    const idleTime = Number(localStorage.getItem("IdleTimeOut"))
    if (new Date().getTime() > idleTime) {
      sessionStorage.clear()
      localStorage.clear()
      return false
    }
  }
  if (user) {
    return true
  } else {
    return false
  }
}

export {
  APIURL, COMMON, checkIsLoggedIn
}