import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import { Banner } from '../components/banner/Home1_banner';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import ProgressBar from 'react-animated-progress-bar';
import Video from '../components/layout/Video';
import axios from "axios";
import { APIURL, COMMON } from '../config'
import { history } from "../history"
import PageHeader from "../components/layout/PageHeader";
let error_log = require('../error_log');


export class GnJobList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employeecode: -1,
            ipurl: APIURL,
            photoIndex: 0,
            isOpen: false,
            currentSlide: 0,
            gnjob: [],
            joblocation_array: [],
            jobtype: -1
        };
    }

    componentDidMount() {
        try {
            let empcode = localStorage.getItem('employeecode');
            this.setState({employeecode : this.state.employeecode = empcode});
            // alert("44444444")
            localStorage.getItem('ipaddress')
            this.setState({ipaddress : this.state. ipaddress = localStorage.getItem('ipaddress')});
                stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
                this.setState({languagecode : this.state.languagecode = localStorage.getItem('languagecode')})
            let query = new URLSearchParams(history.location.search)
            this.setState({ jobtype: this.state.jobtype = (query.get('jobtype')) })
            this.Governmentjoblist()
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "GnJobList", "componentDidMount", 0, error.toString());
        }
    }

    handleAfterChange = (index) => {
        try {
            this.setState({ currentSlide: index });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "GnJobList", "handleAfterChange", 0, error.toString());
        }
    };
    //job function list
    Governmentjoblist() {
        window.fbq('track', 'Website', { fb_ad_function: "Governmentjoblist", fb_ad_type: "Gn Job List", fb_ad_loginuser: COMMON.employee_name });
        try {
            axios({
                method: 'Post',
                // url: this.state.ipurl + 'jobs/jobscount',
                url: 'https://api.jobanya.com/api/controller/' + 'jobs/jobscount',
                crossdomain: true,
                params: {
                    // employeecode: this.state.employeecode,
                    employeecode: this.state.employeecode,
                    deviceip: this.state.deviceip,
                    languagecode: this.state.languagecode,
                    type: 5
                },
            })
                .then(response => {
                    if (response.data.job_list_count_json_result.varstatuscode === 4) {
                        let fullarray = []
                        let stategovjobs = []
                        let centralgovtjobs = []
                        if (this.state.jobtype == 2) {
                            stategovjobs = response.data.job_list_count_json_result.job_list_count.filter(e => e.statecode == 1)
                            this.state.gnjob = stategovjobs
                            JSON.stringify(this.state.gnjob);
                            this.setState({ gnjob: this.state.gnjob });
                        }
                        else if (this.state.jobtype == 3) {
                            centralgovtjobs = response.data.job_list_count_json_result.job_list_count.filter(e => e.statecode != 1)
                            this.state.gnjob = centralgovtjobs
                            JSON.stringify(this.state.gnjob);
                            this.setState({ gnjob: this.state.gnjob });
                        }
                        else {
                            fullarray = response.data.job_list_count_json_result.job_list_count
                            this.state.gnjob = fullarray
                            JSON.stringify(this.state.gnjob);
                            this.setState({ gnjob: this.state.gnjob });
                            
                        }


                    }
                })
                .catch(error => {
                    error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "GnJobList", "Governmentjoblist", 0, error.toString());
                });
        } catch (error) {
            error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "GnJobList", "Governmentjoblist", 0, error.toString());
        }
    }

    //job function 
    GnJobCountList() {
        try {
            if (this.state.gnjob && this.state.gnjob.length > 0) {
                return this.state.gnjob.map((item, index) => {
                    const { gnorganisationname, jobcount, logourl } = item
                    return (
                        <a href={'/gn_joblist?gnorganisationcode=' + item.gnorganisationcode + '&gnorganisationname=' + item.gnorganisationname} className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* finance-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundColor: '#E8E8E8' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* finance-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src={logourl} />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>{gnorganisationname}</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>{jobcount} Vacancies<br></br></p>
                                    </div>
                                    <span className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                                    >View Jobs!</span>
                                </div>
                            </div>{/* featured-icon-box end */}
                        </a>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, COMMON.BUILD_NO, "GnJobList", "GnJobCountList", 0, error.toString());
        }

    }

    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{

                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };
        return (
            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="Goverment Jobs"
                    breadcrumb="Goverment Jobs"
                    className="pb-65 pb-lg-0"
                />
                {/* PageHeader end */}
                <div className="container">
                    <div className="section-title title-style-center_text">
                        <div className="title-header">
                            <h3><span className="text-theme-SkinColor"></span></h3>
                            <h2 className="title">Jobs By Government Organisation</h2>
                        </div>
                    </div>
                    <div className="row row-equal-height mb_10">
                        {/* <Slider className="row slick_slider slick-arrows-style2 pt-20 mb_10" {...slick_slider} rows={2} slidesToShow={4} arrows={true}> */}
                            {this.GnJobCountList()}
                        {/* </Slider> */}
                    </div>

                </div>
                <Footer />
            </div>
        )
    }
}


export default GnJobList;
